import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Dashborad/dashboard.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Network } from "../../../config/axios";
import { toast } from "react-toastify";

const Comment = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [commentvalue, setCommentvalue] = useState();
  const UpdateDetails = async () => {
    const data = {
      comments: commentvalue,
    };
    try {
      const response = await Network().patch(
        "/admin/updateAppointmentById/" + props.comentmeetingid,
        data,
        {
          headers: {
            authorization: token,
          },
        }
      );

      if ([200, 201, 202].includes(response.status)) {
        toast.success(t(response.data.msg));
        props.handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        open={props.commentModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1> {t("Admin comments")}</h1>
            <div>
              <textarea
                type="text"
                className="form-control"
                rows={4}
                onChange={(e) => setCommentvalue(e.target.value)}
                defaultValue={props.comment}
              />
            </div>
            <Button
              size="lg"
              style={{ marginTop: "30px", width: "186px" }}
              onClick={UpdateDetails}
            >
              {t("Submit")}
            </Button>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default Comment;
