import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { collectiveData } from "../../../config/service";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { RotatingLines } from "react-loader-spinner";
import { serverPath } from "../../../config/key";
import fs from "fs";
import moment from "moment";

const Report = ({ handleReportModalClose, reportModal }) => {
  const { t } = useTranslation();
  const [collectivedata, setCollectiveData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedCollective, setSelectedCollective] = useState([]);
  const [isloader, setIsLoader] = useState(false);
  const collectiveAllArray = [{ collective: "all" }];
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [partnerToDate, setPartnerToDate] = useState("");
  const [partnerFromDate, setPartnerFromDate] = useState("");
  const [fileType, setFileType] = useState("");
  useEffect(() => {
    getCollective();
  }, [reportModal]);
  useEffect(() => {
    setSelectedCollective([]);
    setSelected([]);
  }, [handleReportModalClose]);
  const getCollective = async () => {
    const response = await collectiveData();
    setCollectiveData(collectiveAllArray.concat(response.data));
  };
  const onSelect = (selectedList, selectedItem) => {
    setSelected([...selected, selectedItem]);
    setSelectedCollective([...selectedCollective, selectedItem.collective]);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelected(
      selected.filter((d) => {
        return d != removedItem;
      })
    );
    setSelectedCollective(
      selectedCollective.filter((d) => {
        return d != removedItem.collective;
      })
    );
  };
  const handleFileType = (e) => {
    setFileType(e.target.value);
  };
  const getReport = async (reportdata) => {
    let newdata = reportdata.map((data) => {
      const d = data.replace(/['"]+/g, "");
      return d;
    });
    setIsLoader(true);
    const reporturl =
      reportdata[0] === "all"
        ? `${serverPath}/admin/reports/consultations`
        : `${serverPath}/admin/reports/consultations/${JSON.stringify(
            newdata
          )}`;

    const headers = {
      "Content-Type": "blob",
      authorization: localStorage.getItem("token"),
    };
    const config = {
      method: "GET",
      url: reporturl,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);

      const outputFilename = `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      setIsLoader(false);
      handleReportModalClose();
      setSelectedCollective([]);
      setSelected([]);

      // OR you can save/write file locally.
      fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setIsLoader(false);
      throw Error(error);
    }
  };
  const genereateReport = () => {
    getReport(selectedCollective);
  };
  // console.log("collective data", collectivedata);
  const handleChange = async (name, value) => {
    if (name === "fromDate") {
      if (!value) {
        setFromDate(null);
      }
      setFromDate(value);
    }
    if (name === "toDate") {
      if (!value) {
        setToDate(null);
      }
      setToDate(value);
    }
  };
  const handlePartnerChange = (name, value) => {
    if (name === "partnerFromDate") {
      if (!value) {
        setPartnerFromDate(null);
      }

      setPartnerFromDate(value);
    }
    if (name === "partnerToDate") {
      if (!value) {
        setPartnerToDate(null);
      }
      setPartnerToDate(value);
    }
  };
  const validation = () => {
    let isValid = true;
    if (!fromDate) {
      toast.error(t("From Date is mandatory field"));
      return (isValid = false);
    }
    if (!toDate) {
      toast.error(t("To Date is mandatory field"));
      return (isValid = false);
    }
    return isValid;
  };
  const partnerValidation = () => {
    let isValid = true;
    if (!partnerFromDate) {
      toast.error(t("From Date is mandatory field"));
      return (isValid = false);
    }
    if (!partnerToDate) {
      toast.error(t("To Date is mandatory field"));
      return (isValid = false);
    }
    return isValid;
  };
  const genereateIDFReport = async () => {
    if (validation()) {
      setIsLoader(true);
      const headers = {
        "Content-Type": "blob",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      var myDate = toDate;
      var updateEndDate = new Date(myDate.setDate(myDate.getDate() + 1));
      myDate.setDate(myDate.getDate() - 1);
      const config = {
        method: "GET",
        url: `${serverPath}/admin/reports?startDate=${new Date(
          fromDate
        )}&endDate=${updateEndDate}&fileType=${fileType}`,
        responseType: "arraybuffer",
        headers,
      };
      try {
        const response = await axios(config);
        const outputFilename = `idf-report-${Date.now()}.${
          fileType ? fileType : "xlsx"
        }`;
        if (response?.data?.message) {
          setIsLoader(false);
          return toast.info(response?.data?.message);
        }
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.setAttribute("download", outputFilename);
        fileLink.setAttribute("target", "_blank");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
        setIsLoader(false);
        setFromDate("");
        setToDate("");
        setFileType("");
      } catch (error) {
        setIsLoader(false);
        toast.error("Some error occurd.Please try again");
        console.log("error==>", error);
        throw Error(error);
      }
    }
  };
  const genereateIchilovReport = async () => {
    if (partnerValidation()) {
      setIsLoader(true);
      const headers = {
        "Content-Type": "blob",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const fromFormattedDate = moment(partnerFromDate)
        .startOf("day")
        .format("YYYY-MM-DD");
      const toFormattedDate = moment(partnerToDate)
        .startOf("day")
        .format("YYYY-MM-DD");
      const config = {
        method: "GET",
        url: `${serverPath}/reports/generatePartnersMeetingReport?fromDate=${new Date(
          fromFormattedDate
        )}&toDate=${new Date(toFormattedDate)}`,
        responseType: "arraybuffer",
        headers,
      };
      try {
        const response = await axios(config);
        var time = new Date();
        const outputFilename = `ichilov-report-${time.toLocaleDateString()}.csv`;
        if (response?.data?.message) {
          setIsLoader(false);
          return toast.info(response?.data?.message);
        }

        const url = URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.setAttribute("download", outputFilename);
        fileLink.setAttribute("target", "_blank");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
        setIsLoader(false);
        setPartnerToDate("");
        setPartnerFromDate("");
      } catch (error) {
        setIsLoader(false);
        toast.error("Some error occurd.Please try again");
        throw Error(error);
      }
    }
  };

  return (
    <div>
      <Modal
        open={reportModal}
        onClose={handleReportModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card collective_content">
          <MDBCardBody className={isloader ? "fade_content" : ""}>
            <h1 style={{ marginBottom: "40px" }}>{t("Reports")}</h1>
            <h3> {t("Generate Report")}</h3>
            {isloader && (
              <div className="loader">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={isloader}
                />
              </div>
            )}
            <div className="collecttive_Container">
              <Button
                onClick={genereateReport}
                className="download_report"
                disabled={selected.length != 0 ? false : true}
              >
                Download Report
              </Button>
              <Multiselect
                className="form-control speciality selectData"
                options={collectivedata}
                selectedValues={selected}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue={"collective"}
                placeholder="Please Select"
                style={{ width: "15%" }}
              />
            </div>
            <h3> {t("Generate IDF Report")}</h3>
            <Form className="idf-report">
              <div className="collecttive_Container">
                <Button
                  onClick={genereateIDFReport}
                  className="download_report"
                >
                  {t("Download Report")}
                </Button>
                <Form.Group className="form-group">
                  <Form.Label>{t("Select File Type")}</Form.Label>
                  <select
                    name="fileType"
                    value={fileType}
                    onChange={(e) => handleFileType(e)}
                    className="form-type"
                  >
                    <option value="">Select</option>
                    <option value="csv">CSV</option>
                    <option value="xlsx">XLSX</option>
                  </select>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>{t("From Date")}</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={fromDate}
                    name="fromDate"
                    onChange={(event) => handleChange("fromDate", event)}
                    placeholderText="DD/MM/YYYY"
                    className="datePicker"
                    popperPlacement="top-start"
                    endDate={new Date()}
                    maxDate={new Date()}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>{t("To Date")}</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={toDate}
                    name="toDate"
                    onChange={(event) => handleChange("toDate", event)}
                    placeholderText="DD/MM/YYYY"
                    endDate={new Date()}
                    maxDate={new Date()}
                    className="datePicker"
                    popperPlacement="top-start"
                  />
                </Form.Group>
              </div>
            </Form>
            <h3> {t("Generate Ichilov Report")}</h3>
            <Form className="idf-report">
              <div className="collecttive_Container">
                <Button
                  onClick={genereateIchilovReport}
                  className="download_report"
                >
                  {t("Download Report")}
                </Button>
                {/* <Form.Group className="form-group">
                  <Form.Label>{t("Select Partner")}</Form.Label>
                  <select
                    name="fileType"
                    value={fileType}
                    onChange={(e) => handleFileType(e)}
                    className="form-type"
                  >
                    <option value="">Select</option>
                    <option value="csv">CSV</option>
                    <option value="xlsx">XLSX</option>
                  </select>
                </Form.Group> */}
                <Form.Group className="form-group">
                  <Form.Label>{t("From Date")}</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={partnerFromDate}
                    name="partnerFromDate"
                    onChange={(event) =>
                      handlePartnerChange("partnerFromDate", event)
                    }
                    placeholderText="DD/MM/YYYY"
                    className="datePicker"
                    popperPlacement="top-start"
                    endDate={new Date()}
                    maxDate={new Date()}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>{t("To Date")}</Form.Label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={partnerToDate}
                    name="partnerToDate"
                    onChange={(event) =>
                      handlePartnerChange("partnerToDate", event)
                    }
                    placeholderText="DD/MM/YYYY"
                    endDate={new Date()}
                    maxDate={new Date()}
                    className="datePicker"
                    popperPlacement="top-start"
                  />
                </Form.Group>
              </div>
            </Form>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default Report;
