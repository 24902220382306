import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { Network } from "../../config/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import { RotatingLines } from "react-loader-spinner";
import { Price } from "../../config/service";

const Pay = (props) => {
  const { pacseeClose, setShowIframe, showIframe, loader } = props;
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [iframeUrl, setIframeUrl] = useState();
  const [service, setService] = useState("");
  const [referralCode, setReferralCode] = useState(
    localStorage.getItem("refferalId") || ""
  );
  useEffect(() => {
    setService("");
  }, [props.handleClose]);
  const pay = async () => {
    const data = props?.insurance?.find(
      (el) => el.price === 0 && el.name === "Coupon"
    );
    const value = data?.name ? data?.name : props.insurance[0].name;
    if (validation()) {
      try {
        const response = await Network().get(
          `/users/payForMeeting?sId=63352fdff9ddf1cdc0aadfdd&pOpt=${
            service ? service : value
          }&mId=${props.payData._id}&dId=${props.dId}&sDt=${props.dates}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        if (response.data.url) {
          updateTranzilaIframeUrl(response.data.url);
          localStorage.setItem("mId", props.payData._id);
        } else {
          toast.error(t(response.data.msg));
        }
        /*if ([200, 201, 202].includes(response.status)) {
                    toast.success(t(response.data.msg))
                    props.handleClose()
                }*/
        localStorage.removeItem("refferalId");
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error(t("Please Select date and Price"));
    }
  };

  const applyCoupon = async () => {
    try {
      const response = await Network().post(
        `users/validateCouponCode`,
        { referralCode },
        {
          headers: {
            authorization: token,
          },
        }
      );

      const {
        data: { statusCode, message },
      } = response;
      if (statusCode === 200) {
        insuranceDetails(props?.payData?._id);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const insuranceDetails = async (id) => {
    let data = {
      meetingId: id,
      doctorId: props.dId,
    };
    const response = await Price(data);
    props?.setInsurance(response.data.paymentOptions);
  };

  const validation = () => {
    let flag = false;
    if (props.dates) {
      flag = true;
    }
    return flag;
  };

  const updateTranzilaIframeUrl = (url) => {
    setShowIframe(true);
    setIframeUrl(url);
  };
  const handleReffralValue = (e) => {
    const refferalId = e.target.value;
    setReferralCode(refferalId);
  };
  useEffect(() => {
    function handleMessage(event) {
      if (event.source) {
        const iframeURL = event.data;
        if (iframeURL) {
          const url = new URL(iframeURL);
          const conversionAmount = url.searchParams.get("conversionAmount");
          if (conversionAmount) {
            window.gtag("event", "conversion", {
              conversionAmount: conversionAmount,
            });
          }
        }
      }
    }
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <div>
      <Modal
        open={showIframe}
        onClose={() => (setShowIframe(false), props.handleClose())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <div className="Iframe_content">
          <Iframe url={iframeUrl} id="myId" className="tranzila_iframe" />
          <Button className="iframeclose payment" onClick={() => pacseeClose()}>
            {t("Close")}
          </Button>
        </div>
      </Modal>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1>{t("Payment")}</h1>
            {loader && props.insurance.length == 0 && (
              <div className="payloadercontent">
                <RotatingLines
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
                <span className="loadertext">
                  {t(
                    "Please hold while we are preparing your meeting, this may take up to 30 seconds"
                  )}
                </span>
              </div>
            )}

            {props?.insurance?.find(
              (el) => el.price === 0 && el.name === "Coupon"
            ) ? (
              <select
                className="pay_service_select"
                onChange={(e) => setService(e.target.value)}
              >
                <option
                  value={
                    props?.insurance?.find(
                      (el) => el.price === 0 && el.name === "Coupon"
                    )?.name
                  }
                >
                  {t(
                    props?.insurance?.find(
                      (el) => el.price === 0 && el.name === "Coupon"
                    )?.name
                  ) +
                    " - " +
                    "₪" +
                    props?.insurance?.find(
                      (el) => el.price === 0 && el.name === "Coupon"
                    )?.price}{" "}
                </option>
              </select>
            ) : (
              <select
                className="pay_service_select"
                onChange={(e) => setService(e.target.value)}
              >
                {/* <option>{t("Select Payment option")}</option> */}
                {props.insurance.length != 0 &&
                  props.insurance.map((d, i) => {
                    return (
                      <option value={d.name} key={i}>
                        {t(d.name) + " - " + "₪" + d.price}{" "}
                      </option>
                    );
                  })}
              </select>
            )}
            <div className="reffral-input">
              <Form.Group className="form-group ">
                <Form.Label>{t("Coupon Code")}</Form.Label>
                <div className="reffral-input-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("Coupon Code")}
                    value={referralCode}
                    id="reffral"
                    onChange={handleReffralValue}
                  />
                  <Button onClick={applyCoupon} disabled={!referralCode}>
                    {t("Apply")}
                  </Button>
                </div>
              </Form.Group>
            </div>
            <MDBCardText>
              <Button
                variant="primary"
                size="lg"
                className="buttton_content"
                onClick={pay}
              >
                {props?.insurance?.find(
                  (el) => el.price === 0 && el.name === "Coupon"
                )
                  ? t("Relly")
                  : t("Pay")}
              </Button>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default Pay;
