import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Importing translation files

import translationEN from "./locals/english/translation.json";
import translationHE from "./locals/hebrew/translation.json";
const fallbackLng = ["HE"];
const availableLanguages = ["EN", "HE"];

//Creating object with the variables of imported translation files
const resources = {
  EN: {
    translation: translationEN,
  },
  HE: {
    translation: translationHE,
  },
};

//i18N Initialization

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,
    // lng:"hn", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
