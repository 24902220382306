import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { medicalQuestionnaire } from "../../config/service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const MadicalQuestionnaire = (props) => {
  const { readOnly, scheduledate } = props;
  const { t } = useTranslation();
  const [formValue, setformValue] = React.useState({
    mainComplaint: "",
    medicalHistory: "",
    medications: "",
  });

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const uploadMedicalQuestionnaire = async () => {
    const data = {
      meetingId: props.qid,
      questionairre: {
        mainComplaint: formValue?.mainComplaint || "",
        medicalHistory: formValue?.medicalHistory || "",
        medications: formValue?.medications || "",
      },
    };
    const response = await medicalQuestionnaire(data);

    toast.success(t(response.data.msg));
    props.handleClose();
  };
  var date = new Date(scheduledate);
  date.setMinutes(date.getMinutes() + 3);
  React.useEffect(() => {
    if (props?.medicalq) {
      const { medicalHistory, medications, mainComplaint } = props?.qData || {};
      if (medicalHistory || medications || mainComplaint) {
        setformValue({
          mainComplaint: mainComplaint || "",
          medicalHistory: medicalHistory || "",
          medications: medications || "",
        });
      }
    }
  }, [props?.medicalq]);

  return (
    <div>
      <Modal
        open={props.medicalq}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody className="textchange">
            <h1> {t("Medical questionnaire")}</h1>

            <h3>
              {" "}
              {t(
                "Filling out the questionnaire will allow the expert to prepare for the conversation and ensure quality and purposeful advice"
              )}
            </h3>
            <h2> {t("Description of the main complaint")}</h2>
            <textarea
              readOnly={date > new Date() ? readOnly : true}
              rows={2}
              className="form-control textDiv"
              name="mainComplaint"
              defaultValue={props.qData ? props.qData.mainComplaint : ""}
              onChange={handleChange}
            />
            <h2> {t("Description of the disease and medical history")}</h2>
            <textarea
              readOnly={date > new Date() ? readOnly : true}
              rows={2}
              className="form-control textDiv"
              name="medicalHistory"
              defaultValue={props.qData ? props.qData.medicalHistory : ""}
              onChange={handleChange}
            />
            <h2>{t("Regular medications")}</h2>
            <textarea
              readOnly={date > new Date() ? readOnly : true}
              rows={2}
              className="form-control textDiv"
              name="medications"
              defaultValue={props.qData ? props.qData.medications : ""}
              onChange={handleChange}
            />
            {!readOnly ? (
              date > new Date() ? (
                <Button
                  size="lg"
                  className="buttton_content"
                  onClick={uploadMedicalQuestionnaire}
                >
                  {t("Save")}
                </Button>
              ) : (
                <h2 className="update_smg">
                  {t(
                    "Questionnaire cannot be updated after the meeting has started"
                  )}
                </h2>
              )
            ) : (
              <Button
                size="lg"
                className="buttton_content"
                onClick={props.handleClose}
              >
                {t("Close")}
              </Button>
            )}
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default MadicalQuestionnaire;
