import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import ichilovLogo from "../../assets/images/new/ichilov-logo.svg";

const ConsultingField = ({
  setSelectStep,
  formData,
  setformData,
  categoriesData,
  filteredData,
  setFilteredData,
  doctorObj,
  storagePartnerId,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const [checkIchilov, setcheckIchilov] = useState(false);
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  let lang = localStorage.getItem("lang");
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleSearch = (e) => {
    setSearchResult(true);
    const searchWord = e.target.value;
    setSearchText(searchWord);
    const newFilter = categoriesData.filter((value) => {
      return lang === "EN"
        ? value.eng.toLowerCase().includes(searchWord.toLowerCase())
        : value.heb.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (
      categoriesData.find((el) =>
        lang === "EN"
          ? el.eng.toLowerCase() === searchWord?.toLowerCase()
          : el.heb.toLowerCase() === searchWord?.toLowerCase()
      )
    ) {
      setLoader(false);
    } else {
      setLoader(true);
    }
    if (searchWord === "") {
      setFilteredData(categoriesData);
    } else {
      setFilteredData(newFilter);
    }
  };
  const handleSelectResult = (data) => {
    setSearchText(lang === "EN" ? data?.eng : data?.heb);
    setSelectedData(data);
    setSearchResult(false);
    setLoader(false);
    handleCheckIchilov(data);
  };
  const handleCheckIchilov = (data) => {
    if (data && data.partners) {
      const ichilovExists = data.partners.some((obj) => {
        return (
          obj.partnerName && obj.partnerName.toLowerCase().includes("ichilov")
        );
      });

      if (ichilovExists) {
        setcheckIchilov(true);
      } else {
        setcheckIchilov(false);
      }
    } else {
      setcheckIchilov(false);
    }
  };

  const handleCategory = (item) => {
    setformData((prev) => ({ ...prev, ["consult"]: item }));
    setSelectStep("reference");
    window.gtag("event", "new-request-consultation", {});
  };
  const boldQuery = (item) => {
    const text = lang === "EN" ? item?.eng : item?.heb;
    const termSp = searchText.split("");
    const textSp = text.split("");
    const result = textSp.map((el) => {
      if (
        termSp.includes(el.toLowerCase()) ||
        termSp.includes(el.toUpperCase())
      ) {
        return `<b>${el}</b>`;
      }
      return el;
    });
    return result.join("");
  };

  const handlePartnerConsent = (event) => {
    const { name, checked } = event.target;
    const updatedFormValue = {};
    if (name === "partnerId" && checked) {
      updatedFormValue[name] = "64d326cd869f4f8664538775";
    } else {
      updatedFormValue[name] = "";
    }

    setformData((prev) => ({ ...prev, ...updatedFormValue }));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (filteredData.length === 1 && doctorObj) {
      setSelectStep("reference");
    }
  }, [filteredData]);

  return (
    <div className="consulting-section">
      <h6>
        {t("Choose a domain")} <span>{t("consultation")}</span>{" "}
      </h6>
      {!doctorObj ? (
        <div className="search-input" ref={wrapperRef}>
          <Form>
            <Form.Group className="search-input-form">
              <Form.Control
                className="form-control"
                type="text"
                placeholder={t("You can type the desired domain")}
                value={
                  searchText ||
                  (formData && lang === "EN"
                    ? formData?.consult?.eng
                    : formData?.consult?.name)
                }
                id="consult"
                onChange={handleSearch}
                autoComplete="off"
              />
            </Form.Group>
            {checkIchilov ? (
              <Form.Group className="partner-checkbox">
                <Form.Check
                  type="checkbox"
                  checked={formData?.partnerId ? formData?.partnerId : false}
                  name="partnerId"
                  onChange={handlePartnerConsent}
                  className={lang === "EN" ? "checkleft" : "checkright"}
                  label={<IchilovLable />}
                />
              </Form.Group>
            ) : null}
            {searchResult && searchText !== "" ? (
              <SearchResultComponent
                filteredData={filteredData}
                boldQuery={boldQuery}
                searchText={searchText}
                handleSelectResult={handleSelectResult}
                lang={lang}
                t={t}
              />
            ) : null}
          </Form>
        </div>
      ) : (
        <div className="consulting-area">
          <div className="consulting-box">
            {filteredData?.length > 0 ? (
              filteredData.map((item, i) => {
                return (
                  <h6
                    key={i}
                    className={
                      formData?.consult?.eng === item.eng ||
                      formData?.consult?.name === item.heb
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      handleCategory(item);
                    }}
                  >
                    {lang === "EN" ? item.eng : item.heb}
                  </h6>
                );
              })
            ) : (
              <div className="consulting-no-result">
                <h6>{t("No result found")}</h6>
              </div>
            )}
          </div>
        </div>
      )}
      {!filteredData ? (
        <div className="loader consulting-loader">
          <RotatingLines
            strokeColor="black"
            strokeWidth="5"
            animationDuration="0.75"
            width="48"
            visible={true}
          />
        </div>
      ) : (
        <div className="continue-btn">
          <Button
            disabled={formData?.consult ? false : loader}
            onClick={() => handleCategory(selectedData)}
          >
            {t("Continue")}
          </Button>

          {storagePartnerId === "64d326cd869f4f8664538775" ? (
            <a
              href="https://api.whatsapp.com/send?phone=972526001369"
              target="_blank"
            >
              {t(
                "Didn't find the field you were looking for? It is written to us"
              )}
            </a>
          ) : (
            <a href="https://www.hidoc.co.il/contact">
              {t("We will be happy to help")}
            </a>
          )}
        </div>
      )}
    </div>
  );
};
export default ConsultingField;

const SearchResultComponent = ({
  filteredData,
  boldQuery,
  handleSelectResult,
  t,
}) => {
  return (
    <div className="search-result-section">
      <ul>
        {filteredData && filteredData?.length > 0 ? (
          filteredData.map((data, i) => {
            return (
              <li
                dangerouslySetInnerHTML={{
                  __html: `${boldQuery(data)}`,
                }}
                key={i}
                onClick={() => handleSelectResult(data)}
              />
            );
          })
        ) : (
          <li className="no-result-match">{t("No result found")}</li>
        )}
      </ul>
    </div>
  );
};

const IchilovLable = () => {
  const { t } = useTranslation();
  return (
    <div className="ichilov-label">
      <p>
        {t("I prefer to receive service from")}{" "}
        <span>{t("Ichilov experts")} </span>{" "}
      </p>
      <img src={ichilovLogo} alt="logo" />
    </div>
  );
};
