import React from "react";
import NewFooter from "../footer/newFooter";

const Consent = () => {
  return (
    <>
      <div className="mainConsent">
        <div className="container">
          <div className="mainConsentItems">
            <h1>
              הסכמה מדעת לקבלת השירות ומסירת המידע הרפואי- בהתאם לחוק זכויות
              החולה, התשנ"ו -1996 ("חוק זכויות החולה")
            </h1>
            <div className="consent_text">
              <ol>
                <li>
                  בעת פנייתי לקבלת שירותי בריאות מרחוק אני נותן בזאת הסכמה
                  מתמשכת כנדרש בחוק לטיפול, בי או במי שאני משמש כאפוטרופוס שלו
                  על פי דין, לרבות לביצוע התייעצויות מקצועיות והעברת המידע
                  הרפואי למטפלים אחרים,{" "}
                  <span>
                    ושמירת המידע הרפואי והאישי בענן בהתאם למפורט במדיניות
                    הפרטיות
                  </span>{" "}
                  , בין אם מעניקים שירות באמצעות חברה זו ובין אם אינם, ולמנהל
                  הרפואי של המיזם, בהתאם לשיקולים רפואיים, בהתאם לשיקולים
                  מקצועיים כגון בקרה רפואית, וכן לגורמים מוסמכים בהתאם להגדרות
                  הפרטיות של החברה.
                </li>
                <li>
                  השירות המוצע כשירות בריאות מרחוק (להלן השירות) כולל טיפול
                  רפואי (כהגדרתו בחוק זכויות חולה) באמצעות שיחת וידיאו או טלפון,
                  בתחומים רפואיים שונים ובכלל זה, שירותים רפואיים ברפואת עור,
                  רפואה פנימית, רפואת ילדים, אורתופדיה, קרדיולוגיה, סקסולוגיה,
                  רפואת כאב ועוד.
                </li>
                <li>
                  העובדה כי שירות הבריאות ניתן מרחוק עשוי לעיתים להקשות על
                  המטפל. לפיכך, במקרים מסוימים המטפל ימליץ לי על קיום מפגש רפואי
                  נוסף, לצורך השלמת הבדיקה הרפואית המתחייבת.
                </li>
                <li>
                  השירות אינו מהווה תחליף לבדיקה ו/או לטיפול ו/או לייעוץ רפואי
                  פרונטלי על ידי רופא, בהתאם לצורך. כמו כן, ההמלצה או המידע
                  שיינתנו על ידי המטפל, ככל שיינתנו, בהתאם להכשרתו המקצועית,
                  אינם מהווים תחליף לביצוע פעולות נוספות על ידי, שמטרתן לשפר את
                  בריאותי ו/או למנוע הדרדרות במצבי.
                </li>
                <li>
                  בכל מקרה של בעיה דחופה, מקרה חירום או מצב מצוקה גופני או נפשי
                  יש לי אפשרות לפנות לקבל טיפול רפואי מיידי, למחלקה לרפואה דחופה
                  (מיון) של בית החולים או למוקד רפואי אחר המספק שירותי רפואה
                  דחופה. למען הסר ספק החברה לא תישא בתשלום עבור ביקור זה ואם
                  יידרש תשלום הוא יתבצע לפי הכללים הקבועים בחוק ביטוח בריאות
                  ממלכתי ובהסדרים של קופת החולים בה אני מבוטח.
                </li>
                <li>
                  אני מודע לכך כי מידע שיימסר על ידי ו/או התיעוד של המטפל בנוגע
                  לטיפול הרפואי יישמר ברשומה רפואית, כהגדרתה בחוק זכויות החולה.
                </li>
                <li>
                  המטפלים בשירות יעשו ככל הניתן לשמור על צנעת הפרט והסודיות
                  הרפואית.
                </li>
                <li>
                  קיימות מגבלות בשמירה על פרטיות וחסיון רפואי ואף יתכנו אירועי
                  כשל תקשורתי וניתוק פתאומי ביני לבין השירות. החברה עושה ככל
                  יכולתה לאבטח את המידע הנמסר על ידי. באחריותי הבלעדית לדאוג
                  לשמירת פרטיותו ביחס למידע רפואי אישי המופיע על הצג הדיגיטלי
                  האישי שממנו מבוצעת שיחת השירות (לדוגמא: מסך מחשב, מסוף, טלפון
                  וכדומה) העלול להיות חשוף לאנשים בסביבתי.
                </li>
                <li>
                  פנייה לשירות באמצעות תקשורת שאיננה מאובטחת יכולה להיות חשופה
                  ברשת האינטרנט. החברה תבטיח ככל יכולתה את פרטיות המידע השמור
                  אצלה אך אין באפשרותה להבטיח את פרטיות המידע במהלך ההתקשרות
                  מנקודת הקצה ממנה אני משתמש ואת המידע השמור בנקודת הקצה אצלי.
                  לפיכך, מומלץ בתום כל שימוש להסיר את המידע ו/או את נתוני הזיהוי
                  לרבות שם משתמש וסיסמא או כל נתון אחר מנקודת הקצה בה השתמשתי,
                  כדי לצמצם את אפשרויות לגישה למידע אודותי ו/או לשירות למי שאינו
                  מורשה לכך על ידי. אבטחת המידע השמור בנקודת הקצה שלי הינה
                  באחריותי הבלעדית ואין לספק השירות כל יכולת לאבטח מידע זה.
                </li>
                <li>
                  כמו כן ידוע לי כי השימוש בתקשורת אלקטרונית ואחסון מידע במדיה
                  דיגיטלית חשופים לתקיפות סייבר, או לדליפות מידע, ואני מאשר כי
                  ידוע לי כי מידע אישי ו/או רפואי שיועבר על ידי ו/או אלי במסגרת
                  הטיפול הרפואי ו/או בקשר אליו עלולים להיחשף עקב כך. השימוש
                  בתקשורת אלקטרונית ואחסון מידע במדיה דיגיטלית עלול גם לגרום
                  לאובדן חלקי או מלא של המידע כמו גם להפסקת השירות.
                </li>
                <li>השירות הניתן כרוך בתשלום של 480 ש״ח.</li>
                <li>
                  אני מאשר כי אני מודע לכך שפנייתי, המידע שמסרתי והמפגש עם המטפל
                  מתועדים באופן מלא על ידי החברה, וכי בעצם ההרשמה לקבלת השירות
                  אני מסכים לכך ומצהיר בזאת, כי הפרטים האישיים ונתוני הזיהוי,
                  שאני מוסר אודותיך או אודות מי שאני משמש אפוטרופוס שלו, הם
                  מלאים ומדויקים. אני מאשר בזאת, כי ידוע לי, שעשיית שימוש בפרטים
                  אישיים ובנתוני זיהוי, אשר אינם שלי ו/או של מי שאני משמש
                  אפוטרופוס שלו, או מסירה של פרטים אישיים ונתוני זיהוי, אשר אינם
                  שלי ו/או של מי שאני משמש אפוטרופוס שלו ו/או ללא הרשאה הינה
                  אסורה, מהווה פגיעה על־פי חוק הגנת הפרטיות, התשמ"א-1981 ועלולה
                  לגרור ענישה פלילית. אני מתחייב בזאת, כי אפצה את החברה ו/או
                  המטפל על כל הוצאה ו/או נזק, שעלולים להיגרם עקב עשיית שימוש
                  בפרטים אישיים ובנתוני זיהוי, אשר אינם שלי או של המשתמש שאני
                  משמש כאפוטרופוס שלו, ו/או עקב שימוש במערכת תוך התחזות לאחר.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};
export default Consent;
