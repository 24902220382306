import React  from "react";
import Footer from "../footer/footer";
import Abc from "../../assets/images/dashboard/request_icon.svg"
import Bcd from "../../assets/images/dashboard/under_hello.jpg"
import "./dashboard.css"
import { useNavigate } from 'react-router-dom'
import MainHeader from "../header/MainHeader";

const Dashboardcontent = () => {
    const navigate = useNavigate()
    return (
        <div >

            <div className="content">
                <div className="mainContent">
                    <MainHeader />
                    
                    <h2> Hello {localStorage.getItem("userName")}</h2>

                    <div className="secondDiv">
                        <div>
                            We make an effort to have a doctor get back to you with two appointment times as soon as possible
                        </div>
                        <div>
                            <img src={Abc} className="firstImage" />
                        </div>
                    </div>
                    <div className="appointment">
                        {
                            localStorage.getItem("role") === "doctor" ?
                                <div className="expert">
                                    <h4> : Make Your Appointment</h4>
                                    <div onClick={() => navigate("/Queues")}>
                                        <h5> View Appointment</h5>
                                    </div>
                                </div> :
                                <div className="expert">
                                    <h4> : Finding en expert</h4>
                                    <div onClick={() => navigate("/meetingrequest")}>
                                        <h5> Create Appointment</h5>
                                    </div>
                                </div>

                        }

                        <div>
                            <img src={Bcd} className="appointmentImage" />
                        </div>
                    </div>
                </div>
            </div >

            <Footer />
        </div>

    )
}
export default Dashboardcontent