import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./client.css";
import question from "../../assets/images/dashboard/question.png";

import Pay from "./Pay";
import moment from "moment";
import { Button } from "react-bootstrap";
import CancelModal from "./CancelModal";
// import UpdateModal from "./UpdateModal";
import { useNavigate } from "react-router-dom";
import { Price } from "../../config/service";
import { useTranslation } from "react-i18next";
import MedicalInfoModal from "./MedicalInfoModal";
let index;
export const filterdata = (datearray, data, i) => {
  if (datearray.includes(moment(data).format("DD.MM.YYYY"))) {
    return true;
  } else {
    index = i;
    datearray.push(moment(data).format("DD.MM.YYYY"));
    return false;
  }
};

const ClientMyRequest = (props) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState();
  const [payData, setPayData] = useState();
  const [cancelId, setCancelId] = useState();

  const [dId, setDId] = useState();
  const [insurance, setInsurance] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  let lang = localStorage.getItem("lang");
  let datafullscreen = [];
  let datasmallscreen = [];

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const shift = (d) => {
    let shifttime = "";
    if (["8", "9", "10", "11"].includes(d)) {
      shifttime = "Morning";
    }
    if (["12", "13", "14", "15"].includes(d)) {
      shifttime = "Afternoon";
    }
    if (["16", "17", "18", "19", "20", "21", "22"].includes(d)) {
      shifttime = "Evening";
    }

    return shifttime;
  };

  const cancelModal = (id) => {
    setCancelId(id);
    props.setCancelModal(true);
  };

  const insuranceDetails = async (id) => {
    setLoader(true);
    let data = {
      meetingId: id,
      doctorId: dId,
    };
    const response = await Price(data);
    setInsurance(response.data.paymentOptions);
    setLoader(false);
  };
  const handlePay = (data) => {
    setPayData(data);
    insuranceDetails(data._id);
    props.setShow(true);
    if (props.medInfo) {
      props.setMedInfo(false);
    }
  };

  const handleMedInfo = (data) => {
    setPayData(data);
    insuranceDetails(data._id);
    props.setMedInfo(true);
  };
  const handleCloseMedInfo = () => {
    props.setMedInfo(false);
    props.handleCancelPartnerOffer();
    setPayData();
  };

  return (
    <div>
      <Pay
        dates={dates}
        handleClose={props.handleClose3}
        payData={payData}
        show={props.show}
        insurance={insurance}
        cancelId={cancelId}
        cancelModal={cancelModal}
        iprice={props.iprice}
        setPrice={props.setPrice}
        dId={dId}
        showIframe={props.showIframe}
        pacseeClose={props.pacseeClose}
        setShowIframe={props.setShowIframe}
        loader={loader}
        setInsurance={setInsurance}
      />
      <CancelModal
        cancelId={cancelId}
        handleClose1={props.handleClose}
        show={props.cancelModalOpen}
        appointment={props.allAppointment}
      />
      {/* <UpdateModal updateId={updateId} handleClose={props.handleClose2} show={props.updateModal} /> */}
      {props.medInfo ? (
        <MedicalInfoModal
          medInfo={props.medInfo}
          setMedInfo={props.setMedInfo}
          handlePay={handlePay}
          handleCloseMedInfo={handleCloseMedInfo}
          payData={payData}
        />
      ) : null}

      <div className="toggletab">
        <section className="userWrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="userInnerWrapper userInnerWrapB">
                  {props.loader1 ? (
                    <h2 className="loader-text"> {t("Please wait")}....</h2>
                  ) : props?.createdList?.length != 0 ? (
                    <ul>
                      <>
                        <h1> {t("Waiting for doctor")}</h1>
                        {props.createdList.map((data, i) => {
                          return (
                            <li key={i}>
                              <div className="cfBase1">
                                <div className="cfFirst1 ">
                                  <div className="cfImgbg">
                                    <div className="adult">
                                      <h3>{t("Expertise")}</h3>
                                      <h2>
                                        {lang === "HE"
                                          ? data.consultation.heb
                                          : data.consultation.eng}{" "}
                                        {data.patientAge
                                          ? data.patientAge === 18
                                            ? t("Adults")
                                            : t("Children")
                                          : null}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`cfDate maincomplaint ${
                                    lang === "EN" ? "rightborder" : "leftborder"
                                  }`}
                                >
                                  <h4>{t("Status")}</h4>
                                  <h2>{t("Pending Doctor")}</h2>
                                  {data?.isFollowUp ? (
                                    <h4 className="followup-text">
                                      {t("Follow Up Meeting")}
                                    </h4>
                                  ) : null}
                                </div>
                                <div className="requestdate ">
                                  <div className="requestdateinfo datebox">
                                    <div>
                                      <span className="dayhours">
                                        {t("Preferred time")}
                                      </span>
                                      {!data.isUrgent ? (
                                        data.requestedDates.sort().map((d) => {
                                          return filterdata(
                                            index >= i
                                              ? datasmallscreen
                                              : (datasmallscreen = []),
                                            d,
                                            i
                                          ) ? (
                                            <>
                                              <span className="datetimeshift">
                                                {" "}
                                                {t(
                                                  shift(moment(d).format("H"))
                                                )}{" "}
                                              </span>{" "}
                                            </>
                                          ) : (
                                            <>
                                              <br></br>
                                              <span className="datetimeshift">{` ${moment(
                                                d
                                              ).format("DD.MM.YYYY")} ${t(
                                                shift(moment(d).format("H"))
                                              )}`}</span>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <h2 className="datetimeshift">
                                          {" "}
                                          {t("ASAP")}
                                        </h2>
                                      )}
                                    </div>
                                  </div>
                                  <div className="offer">
                                    <Button
                                      size="sm"
                                      className="offer_button"
                                      onClick={() => cancelModal(data._id)}
                                    >
                                      {" "}
                                      {t("CANCEL")}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </>
                    </ul>
                  ) : null}

                  {props.loader ? (
                    <h2 className="loader-text"> {t("Please wait")}....</h2>
                  ) : props?.allAppointment &&
                    props?.allAppointment?.length !== 0 ? (
                    <ul>
                      <h1> {t("Waiting for Payment")}</h1>

                      {props.allAppointment.map((data, i) => {
                        return (
                          <li key={i}>
                            <div className="cfBase1">
                              <div className="cfFirst1 dateforpay">
                                <div className="cfImgbg">
                                  <div className="cfImgmain">
                                    {data.offerDates.length == 1 ? (
                                      <img
                                        className=""
                                        src={
                                          data?.offerDates[0]?.doctor
                                            ?.croppedAvatar
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img className="" src={question} alt="" />
                                    )}
                                  </div>
                                  <div className="cfImgTxt">
                                    <h3>{t("Expertise")}</h3>
                                    <h2>
                                      {lang === "HE"
                                        ? data.consultation.heb
                                        : data.consultation.eng}
                                    </h2>
                                  </div>
                                </div>
                              </div>

                              <div className="drDate">
                                <div>
                                  {data.offerDates.length != 0
                                    ? data.offerDates.map((data1, index1) => {
                                        return data1.offeredDates
                                          ? data1.offeredDates.map(
                                              (d, index2) => {
                                                return (
                                                  <div
                                                    className="scheduleDate"
                                                    key={`${index2}${index1}`}
                                                  >
                                                    {d ? (
                                                      <>
                                                        <div className="inputdate">
                                                          <input
                                                            type="radio"
                                                            className="radioSize"
                                                            disabled={
                                                              new Date(d) <=
                                                              new Date()
                                                                ? true
                                                                : false
                                                            }
                                                            name={`check`}
                                                            value={moment(
                                                              d
                                                            ).format(
                                                              "YYYY-MM-DD h:mm:ss a"
                                                            )}
                                                            onChange={() => {
                                                              setDates(d),
                                                                props.setMId(
                                                                  data._id
                                                                ),
                                                                setDId(
                                                                  data1.DoctorId
                                                                );
                                                            }}
                                                          />
                                                          <div className="showday">
                                                            <span
                                                              style={{
                                                                color:
                                                                  new Date(d) <=
                                                                  new Date()
                                                                    ? "#685E7C"
                                                                    : "#1E0F3C",
                                                              }}
                                                            >
                                                              {t(
                                                                weekday[
                                                                  new Date(
                                                                    d
                                                                  ).getDay()
                                                                ]
                                                              )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <span
                                                          className="dayhours"
                                                          style={{
                                                            color:
                                                              new Date(d) <=
                                                              new Date()
                                                                ? "#685E7C"
                                                                : "#1E0F3C",
                                                          }}
                                                        >
                                                          {" "}
                                                          {moment(d).format(
                                                            "DD.MM.YYYY"
                                                          )}
                                                        </span>
                                                        <span
                                                          className="dayhours"
                                                          style={{
                                                            color:
                                                              new Date(d) <=
                                                              new Date()
                                                                ? "#685E7C"
                                                                : "#1E0F3C",
                                                          }}
                                                        >
                                                          {moment(d).format(
                                                            "H:mm"
                                                          )}
                                                        </span>
                                                        <div>
                                                          <span
                                                            className="dayhours"
                                                            style={{
                                                              color:
                                                                new Date(d) <=
                                                                new Date()
                                                                  ? "#685E7C"
                                                                  : "#1E0F3C",
                                                            }}
                                                          >
                                                            {" "}
                                                            {`${data1.doctor.first_name} ${data1.doctor.last_name}`}
                                                          </span>
                                                        </div>
                                                      </>
                                                    ) : null}
                                                  </div>
                                                );
                                              }
                                            )
                                          : t("NO Date");
                                      })
                                    : ""}
                                </div>
                              </div>

                              <div className="payButton">
                                <div className="buttonInfo">
                                  {data?.partnerId?.includes("64d326cd869f4f8664538775")
                                  ? (
                                    <Button
                                      className="buttton_content"
                                      disabled={
                                        props.mid == data._id ? false : true
                                      }
                                      // onClick={() => handlePay(data)}
                                      onClick={() => handleMedInfo(data)}
                                    >
                                      {/* {t("PAY")} */}
                                      {t("Continue")}
                                    </Button>
                                  ) : (
                                    <Button
                                    className="buttton_content"
                                    disabled={
                                      props.mid == data._id ? false : true
                                    }
                                    onClick={() => handlePay(data)}
                                    >
                                      {t("PAY")}
                                    </Button>
                                  )}
                                  <h3 onClick={() => cancelModal(data._id)}>
                                    {" "}
                                    {t("Cancel")}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}

                  {props.createdList.length === 0 &&
                    props.allAppointment.length === 0 && (
                      <>
                        <h3 className="no_meeting">
                          {t("You have no meeting requests")}
                        </h3>
                        <div className="create-appointment">
                          <button
                            onClick={() => navigate("/meetingrequest")}
                            className="new-appointment"
                          >
                            {t("click here to create one")}
                          </button>
                          <button
                            className="new-appointment"
                            onClick={props.handleRedirectPast}
                          >
                            {t(
                              "You can also create a follow-up meeting. Just go to past meetings and click on revisit"
                            )}
                          </button>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="toggleContent">
        <section className="userWrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="userInnerWrapper userInnerWrapB">
                  {props.loader1 ? (
                    <h2 className="loader-text"> {t("Please wait")}....</h2>
                  ) : props.createdList.length != 0 ? (
                    <ul>
                      <>
                        <h1> {t("Waiting for doctor")}</h1>
                        {props.createdList.map((data, i) => {
                          return (
                            <li key={i}>
                              <div className="cfBase1 alingment">
                                <div className="cfFirst1 sortshiftwidth">
                                  <div className="cfImgbg">
                                    <div className="cfImgTxt">
                                      <h3>{t("Expertise")}</h3>
                                      <h2>
                                        {lang === "HE"
                                          ? data.consultation.heb
                                          : data.consultation.eng}{" "}
                                        {data.patientAge
                                          ? data.patientAge === 18
                                            ? t("Adults")
                                            : t("Children")
                                          : null}
                                      </h2>
                                      <div>
                                        <h3>{t("Requested Times")}</h3>
                                        {!data.isUrgent ? (
                                          data.requestedDates
                                            .sort()
                                            .map((d) => {
                                              return filterdata(
                                                index >= i
                                                  ? datafullscreen
                                                  : (datafullscreen = []),
                                                d,
                                                i
                                              ) ? (
                                                <>
                                                  <span className="datetimeshift">
                                                    {" "}
                                                    {t(
                                                      shift(
                                                        moment(d).format("H")
                                                      )
                                                    )}{" "}
                                                  </span>{" "}
                                                </>
                                              ) : (
                                                <>
                                                  <br></br>
                                                  <span className="datetimeshift">{` ${moment(
                                                    d
                                                  ).format("DD.MM.YYYY")} ${t(
                                                    shift(moment(d).format("H"))
                                                  )}`}</span>
                                                </>
                                              );
                                            })
                                        ) : (
                                          <h2 className="asap"> {t("ASAP")}</h2>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`cfDate maincomplaint ${
                                    lang === "EN" ? "rightborder" : "leftborder"
                                  }`}
                                >
                                  <h3>{t("Status")}</h3>
                                  <h2>{t("Pending Doctor")}</h2>
                                  {data?.isFollowUp ? (
                                    <h4 className="followup-text">
                                      {t("Follow Up Meeting")}
                                    </h4>
                                  ) : null}
                                </div>
                                <div className="requestdate ">
                                  <div className="requestdateinfo datebox">
                                    <div>
                                      {!data.isUrgent ? (
                                        data.requestedDates.map((d, index) => {
                                          return (
                                            <div
                                              className="scheduleDate1"
                                              key={index}
                                            >
                                              <div>
                                                <span className="dayhours">
                                                  {t("Preferred time")}
                                                </span>
                                                <span className="dayhours">
                                                  {" "}
                                                  {moment(d).format(
                                                    "DD.MM.YYYY"
                                                  )}
                                                </span>
                                                <span className="dayhours">
                                                  {data?.ASAP
                                                    ? t("ASAP")
                                                    : t(
                                                        shift(
                                                          moment(d).format("H")
                                                        )
                                                      )}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <h2 className="asap"> {t("ASAP")}</h2>
                                      )}
                                    </div>
                                  </div>
                                  <div className="offer">
                                    <Button
                                      size="sm"
                                      className="offer_button"
                                      onClick={() => cancelModal(data._id)}
                                    >
                                      {" "}
                                      {t("CANCEL")}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </>
                    </ul>
                  ) : (
                    <></>
                  )}

                  {props.createdList.length === 0 &&
                  props.allAppointment.length === 0 ? (
                    <ul>
                      <>
                        <h3 className="no_meeting">
                          {t("You have no meeting requests")}
                        </h3>
                        <div className="create-appointment">
                          <button
                            onClick={() => navigate("/meetingrequest")}
                            className="new-appointment"
                          >
                            {t("click here to create one")}
                          </button>
                          <button
                            className="new-appointment"
                            onClick={props.handleRedirectPast}
                          >
                            {t(
                              "You can also create a follow-up meeting. Just go to past meetings and click on revisit"
                            )}
                          </button>
                        </div>
                      </>
                    </ul>
                  ) : (
                    <></>
                  )}

                  {props.loader ? (
                    <h2 className="loader-text"> {t("Please wait")}....</h2>
                  ) : props.allAppointment.length !== 0 ? (
                    <ul>
                      <h1> {t("Waiting for Payment")}</h1>

                      {props.allAppointment.map((data, i) => {
                        return (
                          <li key={i}>
                            <div className="cfBase1">
                              <div className="cfFirst1 dateforpay">
                                <div className="cfImgbg">
                                  <div className="cfImgmain">
                                    {data.offerDates.length == 1 ? (
                                      <img
                                        className=""
                                        src={
                                          data?.offerDates[0]?.doctor
                                            ?.croppedAvatar
                                        }
                                        alt=""
                                      />
                                    ) : (
                                      <img className="" src={question} alt="" />
                                    )}
                                  </div>
                                  <div className="cfImgTxt">
                                    <h3>{t("Expertise")}</h3>
                                    <h2>
                                      {lang === "HE"
                                        ? data.consultation.heb
                                        : data.consultation.eng}
                                    </h2>
                                    {data?.isFollowUp ? (
                                      <h4 className="followup-text">
                                        {t("Follow Up Meeting")}
                                      </h4>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="drDate">
                                <div>
                                  {data.offerDates.length != 0
                                    ? data.offerDates.map((data1, index1) => {
                                        return data1.offeredDates
                                          ? data1.offeredDates.map(
                                              (d, index2) => {
                                                return (
                                                  <div
                                                    className="scheduleDate"
                                                    key={`${index1}${index2}`}
                                                  >
                                                    {d ? (
                                                      <>
                                                        <div className="inputdate">
                                                          <input
                                                            type="radio"
                                                            className="radioSize"
                                                            name={`check`}
                                                            disabled={
                                                              new Date(d) <=
                                                              new Date()
                                                                ? true
                                                                : false
                                                            }
                                                            value={moment(
                                                              d
                                                            ).format(
                                                              "YYYY-MM-DD h:mm:ss a"
                                                            )}
                                                            onChange={() => {
                                                              setDates(d),
                                                                props.setMId(
                                                                  data._id
                                                                ),
                                                                setDId(
                                                                  data1.DoctorId
                                                                );
                                                            }}
                                                          />
                                                          <div className="showday">
                                                            <span
                                                              style={{
                                                                color:
                                                                  new Date(d) <=
                                                                  new Date()
                                                                    ? "#685E7C"
                                                                    : "#1E0F3C",
                                                              }}
                                                            >
                                                              {t(
                                                                weekday[
                                                                  new Date(
                                                                    d
                                                                  ).getDay()
                                                                ]
                                                              )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <span
                                                          className="dayhours"
                                                          style={{
                                                            color:
                                                              new Date(d) <=
                                                              new Date()
                                                                ? "#685E7C"
                                                                : "#1E0F3C",
                                                          }}
                                                        >
                                                          {" "}
                                                          {moment(d).format(
                                                            "DD.MM.YYYY"
                                                          )}
                                                        </span>
                                                        <span
                                                          className="dayhours"
                                                          style={{
                                                            color:
                                                              new Date(d) <=
                                                              new Date()
                                                                ? "#685E7C"
                                                                : "#1E0F3C",
                                                          }}
                                                        >
                                                          {moment(d).format(
                                                            "H:mm"
                                                          )}
                                                        </span>
                                                        <div>
                                                          <span
                                                            className="dayhours"
                                                            style={{
                                                              color:
                                                                new Date(d) <=
                                                                new Date()
                                                                  ? "#685E7C"
                                                                  : "#1E0F3C",
                                                            }}
                                                          >
                                                            {" "}
                                                            {`${data1.doctor.first_name} ${data1.doctor.last_name}`}
                                                          </span>
                                                        </div>
                                                      </>
                                                    ) : null}
                                                  </div>
                                                );
                                              }
                                            )
                                          : t("NO Date");
                                      })
                                    : ""}
                                </div>
                              </div>

                              <div className="payButton">
                                <div className="buttonInfo">
                                  {data?.partnerId?.includes("64d326cd869f4f8664538775")
                                   ? (
                                    <Button
                                      className="buttton_content"
                                      disabled={
                                        props.mid == data._id ? false : true
                                      }
                                      // onClick={() => handlePay(data)}
                                      onClick={() => handleMedInfo(data)}
                                    >
                                      {/* {t("PAY")} */}
                                      {t("Continue")}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="buttton_content"
                                      disabled={
                                        props.mid == data._id ? false : true
                                      }
                                      onClick={() => handlePay(data)}
                                    >
                                      {t("PAY")}
                                    </Button>
                                  )}
                                  <h3 onClick={() => cancelModal(data._id)}>
                                    {" "}
                                    {t("Cancel")}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ClientMyRequest;
