import { DyteMeeting } from "@dytesdk/react-ui-kit";
import { useDyteMeeting } from "@dytesdk/react-web-core";

const MyDyteMeeting = () => {
  const { meeting } = useDyteMeeting();

  return (
    <div className="hidoc-dyte-container">
      <DyteMeeting mode="fill" meeting={meeting} showSetupScreen={false} />
    </div>
  );
};

export default MyDyteMeeting;
