import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import "./dashboard.css";
import { rejectPartnerOffer, userPartnerConsent } from "../../config/service";

const MedicalInfoModal = (props) => {
  const { t } = useTranslation();
  let lang = localStorage.getItem("lang");

  const [formValue, setformValue] = useState({
    healthFund: "",
    partnerId: "",
    partnerReject: "",
  });
  const [confirm, setConfirm] = useState(false);
  const halthFundArray = [
    { eng: "Maccabi", heb: "מכבי" },
    { eng: "National", heb: "לאומית" },
    { eng: "General", heb: "כללית" },
    { eng: "United", heb: "מאוחדת" },
    { eng: "I am not a resident of Israel", heb: "אינני תושב/ת ישראל" },
  ];

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  const handlePartnerConsent = (event) => {
    const { name, checked } = event.target;
    const updatedFormValue = {};
    if (name === "partnerId" && checked) {
      updatedFormValue[name] = props?.payData?.partnerId;
      updatedFormValue["partnerReject"] = false;
    } else {
      updatedFormValue["partnerId"] = false;
      updatedFormValue["healthFund"] = "";
      updatedFormValue[name] = checked;
    }

    setformValue((prevFormValue) => ({
      ...prevFormValue,
      ...updatedFormValue,
    }));
  };

  const handleConfirm = async () => {
    if (formValue.partnerReject) {
      try {
        const data = {
          meetingId: props?.payData?._id,
        };
        const response = await rejectPartnerOffer(data);
        toast.success(t(response.data.msg));
        props.handleCloseMedInfo();
      } catch (error) {
        console.error(error);
      }
    } else {
      const isValid = validation();
      if (!isValid) {
        return;
      }
      try {
        const data = {
          healthFund: formValue.healthFund,
          partnerId: formValue.partnerId,
        };
        const response = await userPartnerConsent(data);
        const { code, msg } = response.data;
        if (code === 200) {
          props.handlePay(props.payData);
          toast.success(msg);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCancelConfirm = () => {
    setConfirm(!confirm);
  };

  const validation = () => {
    let isValid = true;
    if (!formValue.healthFund) {
      toast.error(t("Please choose Health Fund"), { toastId: "healthFund" });
      return (isValid = false);
    }
    if (!formValue.partnerId) {
      toast.error(
        t(
          "It must be confirmed that my medical information will also be transferred and saved in the Ichilov information system"
        ),
        {
          toastId: "partnerId",
        }
      );
      return (isValid = false);
    }

    return isValid;
  };

  return (
    <div>
      <CancelModal
        t={t}
        handleCancelConfirm={handleCancelConfirm}
        confirm={confirm}
        payData={props.payData}
        handleCloseMedInfo={props.handleCloseMedInfo}
      />
      <Modal
        open={props.medInfo}
        onClose={props.handleCloseMedInfo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card med-info-content">
          <MDBCardBody>
            <Form.Group>
              {/* <Form.Label> {t("Select Health Fund")} </Form.Label> */}
              <Form.Select
                aria-label="Default select example"
                id="healthFund"
                name="healthFund"
                value={formValue.healthFund}
                onChange={handleChange}
              >
                <option value="">{t("Select Health Fund")}</option>
                {halthFundArray.map((el) => (
                  <option value={lang === "EN" ? el.eng : el.heb}>
                    {lang === "EN" ? el.eng : el.heb}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Check
              type="checkbox"
              checked={formValue?.partnerId ? formValue?.partnerId : false}
              name="partnerId"
              onChange={handlePartnerConsent}
              className={lang === "EN" ? "checkleft" : "checkright"}
              label={<IchilovLabel t={t} />}
            />
            <Form.Check
              type="checkbox"
              checked={
                formValue?.partnerReject ? formValue?.partnerReject : false
              }
              name="partnerReject"
              onChange={handlePartnerConsent}
              className={lang === "EN" ? "checkleft" : "checkright"}
              label={t(
                "I don’t want to share information with ichilov, please offer me a doctor outside of ichilov"
              )}
            />

            <Button className="black-btn" onClick={() => handleConfirm()}>
              {t("Continue")}
            </Button>

            <div style={{ display: "none" }}>
              <h6>
                {t(
                  "I don’t want to share information with ichilov, please offer me a doctor outside of ichilov"
                )}{" "}
              </h6>
              <Button
                className="border-btn"
                onClick={() => handleCancelConfirm()}
              >
                {t("I don’t")}
              </Button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default MedicalInfoModal;

const CancelModal = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const cancelMeeting = async () => {
    setLoading(true);
    const data = {
      meetingId: props?.payData?._id,
    };
    const response = await rejectPartnerOffer(data);
    toast.success(t(response.data.message));
    props.handleCancelConfirm();
    props.handleCloseMedInfo();
    setLoading(false);
  };
  return (
    <div>
      <Modal
        open={props.confirm}
        onClose={props.handleCancelConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1> {t("Are you sure?")}</h1>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <MDBCardText>
                <Button
                  size="lg"
                  className="buttton_content cancel-b"
                  onClick={() => props.handleCancelConfirm()}
                >
                  {t("No")}
                </Button>
              </MDBCardText>
              <MDBCardText>
                <Button
                  variant="primary"
                  size="lg"
                  className="buttton_content cancel-b"
                  onClick={cancelMeeting}
                  disabled={loading}
                >
                  {t("Yes")}
                </Button>
              </MDBCardText>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};

const IchilovLabel = ({ t }) => {
  return (
    <div>
      <Link to="/ichilovConsent" target="_blank">
        {" "}
        {t("I confirm")}{" "}
      </Link>
      {t(
        "that my medical information will also be transferred and saved in the Ichilov information system (required only upon consultation by Ichilov doctors)"
      )}
    </div>
  );
};
