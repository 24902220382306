import React from "react";
import "./footer.css";
import {
  FacebookIconSVG,
  InstagramIconSVG,
  LinkedinIconSVG,
  LogoFile,
  OpinionSVG,
} from "./footerIconsSVG";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="top-footer">
          <div className="footer-left-col">
            <div className="footer-social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/hidocisrael"
                  >
                    {FacebookIconSVG}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/37465712"
                  >
                    {LinkedinIconSVG}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/israelhidoc"
                  >
                    {InstagramIconSVG}
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-logo">
              <a href="https://hidoc.co.il/">
                {LogoFile}
                {/* <img src={footerlogo} alt="" /> */}
              </a>
            </div>
          </div>

          <div className="footer-menu">
            <h2>אודות</h2>
            <div className="footer-links-section">
              <div>
                <p className="address">
                  רחוב השלושה 2
                  <br />
                  תל אביב־יפו, 6706054
                </p>
                <div className="number-grid">
                  <a
                    href="tel:0732072226"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-number"
                  >
                    073-2072226
                  </a>
                </div>
              </div>
              <ul className="links-list">
                <li>
                  <a href="https://hidoc.co.il/privacy">מדיניות פרטיות</a>
                </li>
                <li>
                  <a href="https://hidoc.co.il/terms">תנאי שימוש</a>
                </li>
                <li>
                  <a href="https://hidoc.co.il/medaat">הסכמה מדעת</a>
                </li>
                <li>
                  <a href="https://hidoc.co.il/accessibility">הצהרת נגישות</a>
                </li>
                <li>
                  <a href="https://madanes.com/">מבית מדנס</a>
                </li>
              </ul>
              <ul className="links-list">
                {/* <li>
                  <span role="presentation" onClick={scrollToElementVisa}>
                    Visa Types
                  </span>
                </li> */}
                <li>
                  <a href="https://hidoc.co.il/about">אודות</a>
                </li>
                <li>
                  <a href="/DoctorRegistration">רופא מומחה? הצטרף אלינו</a>
                </li>
                <li>
                  <a href="https://hidoc.co.il/faq_clients">שאלות ותשובות</a>
                </li>
                {/* <li>
                  <span role="presentation" onClick={scrollToElement}>
                    FAQ
                  </span>
                </li>
                <li>
                  <Link to="https://www.hidoc.com/blog/">Blog</Link>
                </li> */}
                <li>
                  <a href="https://www.hidoc.co.il/contact">צור קשר</a>
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="footer-search" onClick={handleSearch} role="presentation">
                <img src={searchicon} alt="" />
              </div> */}
        </div>
      </div>

      <div className="container">
        <div className="bottom-footer">
          {/* <div className="footer-grid">
            <p>
              <img src={locationicon} alt="" />
              Eichhornstraße 3, 10785 Berlin, Germany
            </p>
          </div>
          <div className="footer-grid">
            <p>
              <img src={emailicon} alt="" />
              support@hidoc.com
            </p>
            <p>
              <img src={callicon} alt="" />
              +49-345 454 33 44
            </p>
          </div> */}

          <div className="footer-grid">
            <div className="powered-by">
              <p>powered by {OpinionSVG}</p>
              <p>© 2020 hidoc Israel.</p>
            </div>
          </div>

          <div className="footer-grid footer-note">
            <div className="note-section">
              <p>
                האתר אינו מיועד למצב חירום - אין להשתמש באתר זה במצב מסכן
                חיים.&nbsp;לקבלת עזרה מיידית התקשרו 101
              </p>
            </div>
            {/* <ul>
              <li>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/termconditions">Terms &amp; Conditions</Link>
              </li>
            </ul> */}
          </div>

          {/* {visible ? (
            <div className="footer-goto" onClick={scrollToTop}>
              <img src={topicon} alt="" />
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
