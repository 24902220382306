import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const TemplateSearchList = ({
  filteredTemplate,
  handleSelectTemp,
  showSearch,
  setShowSearch,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showSearch}
      onHide={() => setShowSearch(false)}
      className="temp-list-modal"
      centered
      size="sm"
    >
      <Modal.Body>
        <div className="temp-list-grid">
          {filteredTemplate.length > 0 ? (
            filteredTemplate.map((data) => (
              <button
                key={data._id}
                onClick={() => handleSelectTemp(data)}
                role="option"
              >
                {data.templateName}{" "}
              </button>
            ))
          ) : (
            <div className="no-temp-list">
              <h6>{t("No template available")}</h6>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TemplateSearchList;
