import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

let mData;

const ReferenceDetails = ({
  formData,
  setformData,
  setSelectStep,
  doctorObj,
  filteredData,
  storagePartnerId,
}) => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [refStep] = useState(searchParams.get("mId"));

  const getAppoinmentData = async () => {
    mData = JSON.parse(localStorage.getItem("meetData"));
    setformData((prev) => ({
      ...prev,
      ["age"]: mData?.patientAge,
      ["consult"]: mData?.consultation,
    }));
  };
  useEffect(() => {
    if (refStep) {
      getAppoinmentData();
    }
  }, []);

  const handleReferral = (e) => {
    if (doctorObj) {
      setformData((prev) => ({
        ...prev,
        ["age"]: doctorObj?.patientsCategory.includes("Children") ? 1 : 18,
      }));
    }
    if (filteredData && filteredData?.length === 1) {
      setformData((prev) => ({
        ...prev,
        ["consult"]: filteredData[0],
      }));
    }
    setformData((prev) => ({ ...prev, reference: e.target.value }));
  };
  const handleContinue = () => {
    setSelectStep("time");
    window.gtag("event", "new-request-description", {});
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="reference-details confirmation">
      <h6>{t("The cause of referral")}</h6>
      <p>
        {t(
          "Please specify the reason for the referral so that the system knows how to associate you with the appropriate doctor"
        )}
      </p>
      <Form.Group
        className="form-group"
        controlId="exampleForm.ControlTextarea1"
      >
        <Form.Control
          as="textarea"
          rows={6}
          onKeyDown={(event) => {
            if (event.key == "Enter") {
              event.preventDefault();
            }
          }}
          placeholder={t("You can expand on the application here...")}
          value={formData?.reference || ""}
          onChange={handleReferral}
        />
      </Form.Group>
      <div className="continue-btn">
        <Button
          disabled={formData?.reference ? false : true}
          onClick={() => handleContinue()}
        >
          {t("Continue")}
        </Button>
        {storagePartnerId === "64d326cd869f4f8664538775" ? (
          <a
            href="https://api.whatsapp.com/send?phone=972526001369"
            target="_blank"
          >
            {t(
              "Didn't find the field you were looking for? It is written to us"
            )}
          </a>
        ) : (
          <a href="https://www.hidoc.co.il/contact">
            {t("We will be happy to help")}
          </a>
        )}
      </div>
    </div>
  );
};
export default ReferenceDetails;
