import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashborad/dashboard.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ViewDescription = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={props.descriptionOpen}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1> {t("Patient Description")}</h1>
            <div>
              <MDBCardText>{props.description}</MDBCardText>
            </div>
            <Button
              size="lg"
              className="buttton_content"
              style={{ marginTop: "30px" }}
              onClick={() => props.handleClose()}
            >
              {t("Close")}
            </Button>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default ViewDescription;
