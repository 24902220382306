export const LinkedInIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 2.99902H19C20.1046 2.99902 21 3.89445 21 4.99902V18.999C21 20.1036 20.1046 20.999 19 20.999H5C3.89543 20.999 3 20.1036 3 18.999V4.99902C3 3.89445 3.89543 2.99902 5 2.99902ZM8 17.999C8.27614 17.999 8.5 17.7751 8.5 17.499V10.499C8.5 10.2229 8.27614 9.99902 8 9.99902H6.5C6.22386 9.99902 6 10.2229 6 10.499V17.499C6 17.7751 6.22386 17.999 6.5 17.999H8ZM7.25 8.99902C6.42157 8.99902 5.75 8.32745 5.75 7.49902C5.75 6.67059 6.42157 5.99902 7.25 5.99902C8.07843 5.99902 8.75 6.67059 8.75 7.49902C8.75 8.32745 8.07843 8.99902 7.25 8.99902ZM17.5 17.999C17.7761 17.999 18 17.7751 18 17.499V12.899C18.0325 11.3098 16.8576 9.95354 15.28 9.75902C14.177 9.65827 13.1083 10.1734 12.5 11.099V10.499C12.5 10.2229 12.2761 9.99902 12 9.99902H10.5C10.2239 9.99902 10 10.2229 10 10.499V17.499C10 17.7751 10.2239 17.999 10.5 17.999H12C12.2761 17.999 12.5 17.7751 12.5 17.499V13.749C12.5 12.9206 13.1716 12.249 14 12.249C14.8284 12.249 15.5 12.9206 15.5 13.749V17.499C15.5 17.7751 15.7239 17.999 16 17.999H17.5Z"
      fill="#E9E7EC"
    />
  </svg>
);
export const TwitterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.9727 6.71643C20.5083 7.33595 19.9469 7.87636 19.3102 8.31679C19.3102 8.47862 19.3102 8.64045 19.3102 8.81128C19.3153 11.7501 18.1414 14.5681 16.0517 16.6335C13.9619 18.6989 11.1311 19.8389 8.19393 19.7979C6.49587 19.8036 4.81955 19.4159 3.2963 18.6651C3.21416 18.6292 3.16119 18.548 3.1615 18.4583V18.3594C3.1615 18.2303 3.26611 18.1257 3.39515 18.1257C5.0643 18.0706 6.6739 17.4919 7.99622 16.4714C6.48541 16.4409 5.12607 15.5459 4.50048 14.1697C4.46889 14.0946 4.47872 14.0083 4.52645 13.9422C4.57417 13.8761 4.65288 13.8397 4.73413 13.8461C5.1933 13.8922 5.65706 13.8495 6.10008 13.7202C4.43227 13.374 3.17909 11.9894 2.99974 10.2947C2.99337 10.2134 3.0298 10.1347 3.09588 10.0869C3.16195 10.0392 3.24812 10.0293 3.32326 10.061C3.77082 10.2585 4.25397 10.3625 4.74312 10.3666C3.28172 9.40749 2.65049 7.58308 3.20643 5.92525C3.26382 5.76416 3.40169 5.64515 3.56938 5.61197C3.73706 5.57878 3.90984 5.63631 4.0242 5.76342C5.99627 7.86228 8.70592 9.11299 11.5818 9.25182C11.5082 8.95788 11.472 8.65579 11.474 8.35275C11.5009 6.76375 12.4841 5.34824 13.9633 4.7689C15.4424 4.18957 17.1248 4.56106 18.2229 5.70947C18.9713 5.56688 19.6949 5.31548 20.3706 4.96324C20.4201 4.93234 20.4829 4.93234 20.5324 4.96324C20.5633 5.01276 20.5633 5.07555 20.5324 5.12507C20.2051 5.87455 19.6522 6.50315 18.9508 6.92322C19.565 6.85199 20.1684 6.7071 20.7481 6.49167C20.7969 6.45845 20.861 6.45845 20.9098 6.49167C20.9507 6.51037 20.9813 6.54614 20.9934 6.58945C21.0055 6.63276 20.9979 6.67921 20.9727 6.71643Z"
      fill="#E9E7EC"
    />
  </svg>
);
export const InstagramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 2.99902H8C5.23858 2.99902 3 5.2376 3 7.99902V15.999C3 18.7604 5.23858 20.999 8 20.999H16C18.7614 20.999 21 18.7604 21 15.999V7.99902C21 5.2376 18.7614 2.99902 16 2.99902ZM19.25 15.999C19.2445 17.7916 17.7926 19.2435 16 19.249H8C6.20735 19.2435 4.75549 17.7916 4.75 15.999V7.99902C4.75549 6.20637 6.20735 4.75451 8 4.74902H16C17.7926 4.75451 19.2445 6.20637 19.25 7.99902V15.999ZM16.75 8.24902C17.3023 8.24902 17.75 7.8013 17.75 7.24902C17.75 6.69674 17.3023 6.24902 16.75 6.24902C16.1977 6.24902 15.75 6.69674 15.75 7.24902C15.75 7.8013 16.1977 8.24902 16.75 8.24902ZM12 7.49902C9.51472 7.49902 7.5 9.51374 7.5 11.999C7.5 14.4843 9.51472 16.499 12 16.499C14.4853 16.499 16.5 14.4843 16.5 11.999C16.5027 10.8047 16.0294 9.65859 15.1849 8.8141C14.3404 7.96961 13.1943 7.49636 12 7.49902ZM9.25 11.999C9.25 13.5178 10.4812 14.749 12 14.749C13.5188 14.749 14.75 13.5178 14.75 11.999C14.75 10.4802 13.5188 9.24902 12 9.24902C10.4812 9.24902 9.25 10.4802 9.25 11.999Z"
      fill="#E9E7EC"
    />
  </svg>
);
export const FacebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.5 5.99902H13.5C12.9477 5.99902 12.5 6.44674 12.5 6.99902V9.99902H16.5C16.6137 9.9965 16.7216 10.0494 16.7892 10.1409C16.8568 10.2324 16.8758 10.351 16.84 10.459L16.1 12.659C16.0318 12.8609 15.8431 12.9974 15.63 12.999H12.5V20.499C12.5 20.7751 12.2761 20.999 12 20.999H9.5C9.22386 20.999 9 20.7751 9 20.499V12.999H7.5C7.22386 12.999 7 12.7751 7 12.499V10.499C7 10.2229 7.22386 9.99902 7.5 9.99902H9V6.99902C9 4.78988 10.7909 2.99902 13 2.99902H16.5C16.7761 2.99902 17 3.22288 17 3.49902V5.49902C17 5.77516 16.7761 5.99902 16.5 5.99902Z"
      fill="#E9E7EC"
    />
  </svg>
);

export const SmallSearchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M9 17.9412C13.4183 17.9412 17 14.3594 17 9.94116C17 5.52288 13.4183 1.94116 9 1.94116C4.58172 1.94116 1 5.52288 1 9.94116C1 14.3594 4.58172 17.9412 9 17.9412Z"
      stroke="#E9E7EC"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0004 19.9412L14.6504 15.5912"
      stroke="#E9E7EC"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const SearchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M11 19.9412C15.4183 19.9412 19 16.3594 19 11.9412C19 7.52288 15.4183 3.94116 11 3.94116C6.58172 3.94116 3 7.52288 3 11.9412C3 16.3594 6.58172 19.9412 11 19.9412Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.0004 21.9412L16.6504 17.5912"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
  >
    <path
      d="M9.07088 0.612343C9.41462 -0.204115 10.5854 -0.204114 10.9291 0.612346L12.9579 5.43123C13.1029 5.77543 13.4306 6.01061 13.8067 6.0404L19.0727 6.45748C19.9649 6.52814 20.3267 7.62813 19.6469 8.2034L15.6348 11.5987C15.3482 11.8412 15.223 12.2218 15.3106 12.5843L16.5363 17.661C16.744 18.5211 15.7969 19.201 15.033 18.7401L10.5245 16.0196C10.2025 15.8252 9.7975 15.8252 9.47548 16.0196L4.96699 18.7401C4.20311 19.201 3.25596 18.5211 3.46363 17.661L4.68942 12.5843C4.77698 12.2218 4.65182 11.8412 4.36526 11.5987L0.353062 8.2034C-0.326718 7.62813 0.0350679 6.52814 0.927291 6.45748L6.19336 6.0404C6.5695 6.01061 6.89716 5.77543 7.04207 5.43123L9.07088 0.612343Z"
      fill="url(#paint0_linear_70_3724)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_70_3724"
        x1="10.1404"
        y1="19.6507"
        x2="4.58538"
        y2="-0.0456428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
    </defs>
  </svg>
);

export const TickIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M20.5 6.5L9.5 17.5L4.5 12.5"
      stroke="#FC624C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const SadEmoji = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="url(#paint0_linear_70_2537)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 15H16"
      stroke="url(#paint1_linear_70_2537)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 9H9.01"
      stroke="url(#paint2_linear_70_2537)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 9H15.01"
      stroke="url(#paint3_linear_70_2537)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_70_2537"
        x1="12.1405"
        y1="22.8066"
        x2="5.96776"
        y2="2.13611"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_70_2537"
        x1="12.0562"
        y1="16.0403"
        x2="12.0142"
        y2="14.9162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_70_2537"
        x1="9.00507"
        y1="10.0403"
        x2="8.96742"
        y2="10.0391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_70_2537"
        x1="15.0051"
        y1="10.0403"
        x2="14.9674"
        y2="10.0391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0F7B" />
        <stop offset="1" stop-color="#F89B29" />
      </linearGradient>
    </defs>
  </svg>
);

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M2.75 8.75L6.25 12.25L13.25 4.75"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
