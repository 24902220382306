import React, { useEffect, useState } from "react";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import { Modal } from "@material-ui/core";
import { Button } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import { Network } from "../../../config/axios";
import { adminOfferDate } from "../../../config/service";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OfferDateModal = (props) => {
  const {
    offerModal,
    offerMeetingId,
    handleCloseModal,
    consultId,
    mstatus,
    requestedDates,
    assapValue,
  } = props;
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const [firstDate, setFirstDate] = useState(new Date());
  const [secondDate, setSecondDate] = useState(new Date());
  const navigate = useNavigate();
  const [doctorList, setDoctorList] = useState([]);
  const [doctor, setDoctor] = useState();
  useEffect(() => {
    if (consultId) {
      alldoctorList(consultId);
    }
    setDoctor(
      props.offerDates && props.offerDates.length != 0
        ? props.offerDates[0].DoctorId
        : ""
    );
  }, [consultId]);
  useEffect(() => {
    setFirstDate();
    setSecondDate();
  }, [handleCloseModal]);

  const shift = (d) => {
    let shifttime = "";
    if (["8", "9", "10", "11"].includes(d)) {
      shifttime = "Morning";
    }
    if (["12", "13", "14", "15"].includes(d)) {
      shifttime = "Afternoon";
    }
    if (["16", "17", "18", "19", "20", "21", "22"].includes(d)) {
      shifttime = "Evening";
    }

    return shifttime;
  };
  const Offer = async (e) => {
    e.preventDefault();
    let DateTime = [];

    if (mstatus === "DatesOffered") {
      !firstDate &&
        props.offerDates &&
        props.offerDates.length != 0 &&
        DateTime.push(moment(props.offerDates[0]?.offeredDates[0]).toDate());
      firstDate && DateTime.push(moment(firstDate).toDate());

      !secondDate &&
        props.offerDates &&
        props.offerDates.length != 0 &&
        props.offerDates[0]?.offeredDates[1] &&
        DateTime.push(moment(props.offerDates[0]?.offeredDates[1]).toDate());
      secondDate && DateTime.push(moment(secondDate).toDate());
    } else {
      firstDate && DateTime.push(moment(firstDate).toDate());
      secondDate && DateTime.push(moment(secondDate).toDate());
    }

    const data = {
      id: offerMeetingId,
      offeredDates: DateTime,
      doctorId: doctor,
    };
    if (doctor) {
      const response = await adminOfferDate(data);
      response.data.message
        ? toast.success(t(response.data.message))
        : toast.success(t(response.data.msg));
      handleCloseModal();
      props.AllApoinmentDoctor({ val: "Created" });
    } else {
      toast.error(t("Please select doctor"));
    }
  };
  const alldoctorList = async (data) => {
    try {
      const response = await Network().get(
        "/admin/getDoctorsByConsultationId/" + data,
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setDoctorList(response.data.doctors);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
      console.log(error);
    }
  };
  let datearray = [];
  const filterdata = (data) => {
    if (datearray.includes(moment(data).format("DD.MM.YYYY"))) {
      return true;
    } else {
      datearray.push(moment(data).format("DD.MM.YYYY"));
      return false;
    }
  };

  const doctorOptions = doctorList.map((d) => ({
    value: d?.doctorData._id,
    label: `${d?.doctorData.first_name} ${d?.doctorData.last_name}`,
  }));

  const defaultValue =
    props.offerDates && props.offerDates.length !== 0
      ? doctorOptions.find(
          (data) =>
            data.value ===
            doctorList.find(
              (doc) => doc.doctorId === props.offerDates[0].DoctorId
            )?.doctorData._id
        )
      : { value: "placeholdervalue", label: t("Select doctor") };

  const handleDoctorChange = (selectedOption) => {
    setDoctor(selectedOption.value);
  };
  return (
    <div>
      <Modal
        open={offerModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card" style={{ minHeight: "500px" }}>
          <MDBCardBody>
            <h1>{t("Please choose/provide a date for consult")}</h1>
            <h3>{t("Requested dates")}</h3>
            {assapValue ? (
              <span className="offeredDateText"> {t("ASAP")} </span>
            ) : requestedDates && requestedDates.length != 0 ? (
              <ul className="offeredDateText">
                {requestedDates.sort().map((d) => {
                  return filterdata(d) ? (
                    <>
                      <span> {t(shift(moment(d).format("H")))} </span>{" "}
                    </>
                  ) : (
                    <>
                      <br></br>
                      {` ${moment(d).format("DD.MM.YYYY")} ${t(
                        shift(moment(d).format("H"))
                      )}`}
                    </>
                  );
                })}
              </ul>
            ) : null}

            {props.offerDates && props.offerDates.length != 0 ? (
              <>
                <h3> {t("Dates are already offered for this meeting")} </h3>
                <ul className="offeredDateText">
                  {props.offerDates.map((d, i) => {
                    return (
                      <>
                        {doctorList &&
                          d.offeredDates.map((d1, k) => {
                            return (
                              <li key={i * k}>
                                {moment(d1).format("H:mm")}{" "}
                                {moment(d1).format("DD.MM.YYYY")}{" "}
                                {
                                  doctorList.find(
                                    (data) => data.doctorId === d.DoctorId
                                  )?.doctorData?.first_name
                                }{" "}
                                {
                                  doctorList.find(
                                    (data) => data.doctorId === d.DoctorId
                                  )?.doctorData?.last_name
                                }
                              </li>
                            );
                          })}
                      </>
                    );
                  })}
                </ul>
              </>
            ) : null}

            <h3> {t("Doctor List")}</h3>
            {doctorList && doctorList[0]?.doctorData.first_name && (
              <Select
                classNamePrefix={`prescription doc-${
                  lang === "HE" ? "right-list" : "left-list"
                }`}
                defaultValue={defaultValue}
                isRtl={lang === "HE"}
                noOptionsMessage={() => t("No option available")}
                isSearchable={true}
                options={doctorOptions}
                placeholder={t("Select doctor")}
                name="doctor_list"
                onChange={handleDoctorChange}
              />
            )}
            <div className="dateSelect">
              <DatePicker
                id="datepick"
                selected={
                  firstDate
                    ? firstDate
                    : props.offerDates[0]?.offeredDates[0]
                    ? moment(props.offerDates[0]?.offeredDates[0]).toDate()
                    : firstDate
                }
                onChange={(date) => setFirstDate(date)}
                onfocus="blur()"
                onKeyDown={(e) => e.preventDefault()}
                minDate={new Date()}
                minTime={
                  new Date(
                    firstDate || props.offerDates[0]?.offeredDates[0]
                  ).getDate() > new Date().getDate() ||
                  new Date(
                    firstDate || props.offerDates[0]?.offeredDates[0]
                  ).getMonth() > new Date().getMonth()
                    ? new Date().setHours(8, 0, 0, 0)
                    : new Date().setHours(new Date().getHours() + 0, 30, 0, 0)
                }
                maxTime={new Date().setHours(21, 30, 0, 0)}
                className="form-control forceltr"
                timeIntervals={15}
                scrollIntoView
                showTimeSelect
                dateFormat="MMMM d, yyyy HH:mm"
                timeFormat="HH:mm"
                placeholderText={t("Offer Date")}
              />

              <DatePicker
                id="datepick"
                selected={
                  secondDate
                    ? secondDate
                    : props.offerDates[0]?.offeredDates[1]
                    ? moment(props.offerDates[0]?.offeredDates[1]).toDate()
                    : secondDate
                }
                onChange={(date) => setSecondDate(date)}
                onfocus="blur()"
                onKeyDown={(e) => e.preventDefault()}
                minDate={new Date()}
                minTime={
                  new Date(
                    secondDate || props.offerDates[0]?.offeredDates[1]
                  ).getDate() > new Date().getDate() ||
                  new Date(
                    secondDate || props.offerDates[0]?.offeredDates[1]
                  ).getMonth() > new Date().getMonth()
                    ? new Date().setHours(8, 0, 0, 0)
                    : new Date().setHours(new Date().getHours() + 0, 30, 0, 0)
                }
                maxTime={new Date().setHours(21, 30, 0, 0)}
                className="form-control forceltr"
                dateFormat="MMMM d, yyyy HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                showTimeSelect
                placeholderText={t("Offer Date")}
              />
            </div>
            <MDBCardText>
              <Button
                variant="primary"
                size="lg"
                className="buttton_content"
                onClick={Offer}
                disabled={!firstDate && !secondDate}
              >
                {t("Offer Date")}
              </Button>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default OfferDateModal;
