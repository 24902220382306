import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const PrescriptionAlertModal = ({ showModal, handlePrescriptionAlert }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showModal}
      onHide={handlePrescriptionAlert}
      className="prescription-alert"
      centered
      size="lg"
    >
      <Modal.Body>
        <div className="prescription-alert">
          <h6>
            {t(
              "The possibility of producing prescriptions for private customers is offered for a fee"
            )}
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a href="https://hidoc.co.il/contact" className="border-btn">
          {t("Contact")}
        </a>
        <Button className="border-btn" onClick={handlePrescriptionAlert}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrescriptionAlertModal;
