import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const DeleteFieldModal = ({
  deleteFieldModal,
  setDeleteFieldModal,
  deletePrescriptionSetting,
  additionalFieldData,
  removeProfileFieldData,
  setShowFieldModal,
}) => {
  const { t } = useTranslation();
  const fieldStatus = additionalFieldData.fieldPermanentStatus === "false";

  return (
    <Modal
      show={deleteFieldModal}
      onHide={() => setDeleteFieldModal(false)}
      className="prescription-alert add-field-modal delete-field"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <h6>{t("Do you want to remove additional field ?")} </h6>
      </Modal.Header>
      <Modal.Body>
        <div className="add-field-content" style={{ paddingTop: "25px" }}>
          <div className="yes_no">
            <Button
              size="sm"
              className="border-btn"
              onClick={() => setDeleteFieldModal(false)}
            >
              {" "}
              {t("No")}
            </Button>
            {fieldStatus ? (
              <Button
                size="sm"
                className="border-btn"
                onClick={() => (
                  setDeleteFieldModal(false),
                  removeProfileFieldData(additionalFieldData.fieldPosition),
                  setShowFieldModal(false)
                )}
              >
                {" "}
                {t("Yes")}
              </Button>
            ) : (
              <Button
                size="sm"
                className="border-btn"
                onClick={() => (
                  setDeleteFieldModal(false), deletePrescriptionSetting(true)
                )}
              >
                {" "}
                {t("Yes")}
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteFieldModal;
