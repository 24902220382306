import React from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./client.css";
import { useState } from "react";
import MadicalQuestionnaire from "./MedicalQuestionnaire";
import moment from "moment";
import { useTranslation } from "react-i18next";
import UploadDocument from "./UploadDocument";
import profile from "../../assets/images/dashboard/docProfile.png";
import { useEffect } from "react";
import { Network } from "../../config/axios";
import { CheckIcon } from "../common/icon";
import IdfApproveDocModal from "./IdfApproveDoc";
import AddParticipantModal from "../Admin/Dashboard/AddParticipantModal";

const ClientFutureMeeting = (props) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const mode = localStorage.getItem("mode");
  const uploadModalStatus = localStorage.getItem("uploadModal")
  const { cropimageClose, uploadModal, setUploadModal } = props;
  const { t } = useTranslation();
  const [qid, setQId] = useState();
  const [meetingId, setMeetingId] = useState();
  const [pacseeDocument, setPacseeDocument] = useState([]);
  const [cId, setCid] = useState();
  const [scheduledate, setscheduledate] = useState();
  const [remaintime, setRemainTime] = useState();
  const [pacsseeDisc, setPacseeDisc] = useState(true);
  const [idfApprove, setIdfApprove] = useState(false);
  const [singleMeetObj, setSingleMeetObj] = useState(null);
  const [currentAppointmentIndex, setCurrentAppointmentIndex] = useState(0);
  const [participantModal, setParticipantModal] = useState(false);

  useEffect(() => {
    setPacseeDocument([]);
  }, [cropimageClose]);
  useEffect(() => {
    setInterval(() => {
      setRemainTime(new Date());
    }, 1000);
  });
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const DetailsUpload = (e, id, cid) => {
    e.preventDefault();
    mridocument(id);
    setMeetingId(id);
    setCid(cid);
    setUploadModal(true);
  };
  const mridocument = async (id) => {
    try {
      setPacseeDisc(true);
      const response = await Network().get(
        `/users/pacseeMeetingDocuments?meetingId=${id}`,
        {
          headers: {
            authorization: token,
          },
        }
      );

      if (response?.status === 200 && response?.data?.pacseeFiles.length > 0) {
        setPacseeDisc(false);
      }
      setPacseeDocument(response.data.pacseeFiles);

      if (response?.status !== 200) {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };


  const openDyteMeeting = (event, meeting) => {
    if (meeting?.videoPlatform === 'dyte') {
      window.open(`/dyte-video-call/${meeting._id}`, '_blank', 'location=yes,height=800,width=640,scrollbars=yes,status=yes');
    } else {
      window.open(meeting?.meetingLink, "_blank")
    }

    //window.open(`/dyte-video-call/${meeting._id}`, '_blank');

  };


  const handleIdfApprove = () => {
    setIdfApprove(true);
  };

  useEffect(() => {
    if (
      props?.allAppointment?.length > 0 &&
      mode === "IDF" &&
      uploadModalStatus === "open" && !props?.showTermsModal
    ) {
      const hasScheduledMeeting = props?.allAppointment.some(
        (appointment) => appointment.statuses === "Scheduled"
      );
      if(hasScheduledMeeting) {
        processNextAppointment();
      }
    }
  }, [props?.allAppointment, currentAppointmentIndex, uploadModalStatus, props?.showTermsModal]);

  const processNextAppointment = () => {
    if (currentAppointmentIndex < props.allAppointment.length) {
      const foundAppointment = props.allAppointment.find(
        (appointment, index) =>
          index >= currentAppointmentIndex &&
          appointment?.consultation?.meetingGroupId === "640ed7103f08dcee2d1f1719"
      );

      if (foundAppointment) {
        setSingleMeetObj(foundAppointment);
        handleIdfApprove();
      }
    }
  };
  const handleAddParticipant = (data) => {
    setParticipantModal(true);
    setMeetingId(data?._id)
    setSingleMeetObj(data)
  };
  const handleCloseAddParticipant = () => {
    setParticipantModal(false);
  };

  return (
    <div>
      {uploadModal && meetingId && (
        <UploadDocument
          uploadModal={uploadModal}
          handleClose={cropimageClose}
          meetingId={meetingId}
          cId={cId}
          pacseeDocument={pacseeDocument}
          pacsseeDisc={pacsseeDisc}
          mode={mode}
          setIdfApprove={setIdfApprove}
          singleMeetObj={singleMeetObj}
          futureAppointment={props?.allAppointment}
          currentAppointmentIndex={currentAppointmentIndex}
          setCurrentAppointmentIndex={setCurrentAppointmentIndex}
        />
      )}
      <MadicalQuestionnaire
        handleClose={props.handleClose5}
        medicalq={props.medicalq}
        qid={qid}
        qData={props.qData}
        readOnly={false}
        scheduledate={scheduledate}
      />
      {idfApprove && mode === "IDF" && (
        <IdfApproveDocModal
          idfApprove={idfApprove}
          setIdfApprove={setIdfApprove}
          DetailsUpload={DetailsUpload}
          singleMeetObj={singleMeetObj}
          currentAppointmentIndex={currentAppointmentIndex}
        />
      )}
      {participantModal && (
        <AddParticipantModal
          show={participantModal}
          handleClose={handleCloseAddParticipant}
          meetingId={meetingId}
          refetchAllmeetings={props?.refetchFutureMeetings}
          updatedData={singleMeetObj}
          allMeetingData={props?.allAppointment}
          setUpdateData={setSingleMeetObj}
        />
      )}
      <section className="userWrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="userInnerWrapper userInnerWrapB">
                {props.loader ? (
                  <h2 className="loader-text"> {t("Please wait")}....</h2>
                ) : (
                  <ul>
                    {props?.allAppointment?.length != 0 ? (
                      props?.allAppointment
                        .sort(
                          (objA, objB) =>
                            new Date(objA.scheduledDate) -
                            new Date(objB.scheduledDate)
                        )
                        .map((data, i) => {
                          return (
                            <li key={i} className="updated-design">
                              <div className="cfBase">
                                <div className="cfFirst">
                                  <div className="cfImgbg">
                                    <div className="cfImgmain">
                                      {data.doctorDetails ? (
                                        <img
                                          src={
                                            data.doctorDetails?.croppedAvatar
                                              ? data.doctorDetails
                                                ?.croppedAvatar
                                              : profile
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <img src={profile} alt="" />
                                      )}
                                    </div>

                                    <div className="doctorInfo">
                                      <div className="cfDate">
                                        <h3>
                                          {moment(data?.scheduledDate).format(
                                            "DD.MM.YYYY"
                                          )}
                                        </h3>
                                        <div className="cfDay">
                                          <span>
                                            {" "}
                                            {t(
                                              weekday[
                                              new Date(
                                                data?.scheduledDate
                                              ).getDay()
                                              ]
                                            )}
                                          </span>
                                          <span>
                                            {moment(data?.scheduledDate).format(
                                              "HH:mm"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <h2>
                                        {" "}
                                        {t(
                                          data?.doctorDataScheduled?.title
                                        )}{" "}
                                        {`${data?.doctorDetails?.first_name} ${data?.doctorDetails?.last_name} `}
                                      </h2>
                                      <div className="consultation-info">
                                        <h4>
                                          {lang === "HE"
                                            ? data?.consultation?.heb
                                            : data?.consultation?.eng}
                                        </h4>
                                        {data?.isFollowUp ? (
                                          <h4>{t("Follow Up Meeting")} </h4>
                                        ) : null}
                                      </div>
                                      {/* <div className="consultation-info">
                                        <h4>{data?.requestDescription} </h4>
                                      </div> */}
                                      {data?.doctorDataScheduled?.experience ? (
                                        <div className="cfLocate">
                                          {
                                            data?.doctorDataScheduled
                                              ?.experience
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>

                                <div className="cflast">

                                  {/*
                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      <h3 className="active no_document">
                                        {t("Join Dyte Meeting")}
                                      </h3>
                                    </div>


                                    <button className="active" onClick={(e) => openDyteMeeting(e, data)}>
                                      {t("Join Dyte Meeting")}
                                    </button>
                                  </div> */}



                                  {/* There is a  condition zoom button will show only for the normal meeting not for the idf meeting */}
                                  {data?.consultation?.meetingGroupId !==
                                    "640ed7103f08dcee2d1f1719" ? (



                                    <div className="cfInfo" id="target-div">
                                      <div className="cfChk">
                                        {
                                          new Date(
                                            data?.scheduledDate
                                          ).setMinutes(
                                            new Date(
                                              data?.scheduledDate
                                            ).getMinutes() - 9
                                          ) <= remaintime ? (
                                            <CheckIcon />
                                          ) : null}
                                        {
                                          new Date(
                                            data?.scheduledDate
                                          ).setMinutes(
                                            new Date(
                                              data?.scheduledDate
                                            ).getMinutes() - 9
                                          ) <= remaintime ? (
                                            <h3 className="active">
                                              {t("Start meeting")}
                                            </h3>
                                          ) : (
                                            <h3 className="active no_document">
                                              {t("Start meeting")}
                                            </h3>
                                          )}
                                      </div>
                                      {
                                        new Date(data?.scheduledDate).setMinutes(
                                          new Date(
                                            data?.scheduledDate
                                          ).getMinutes() - 10
                                        ) <= remaintime ? (
                                          <button
                                            className="active"
                                            onClick={(e) =>

                                              openDyteMeeting(e, data)
                                            }
                                          >
                                            {t("Open")}
                                          </button>
                                        ) : (
                                          <button>{t("Open")}</button>
                                        )}
                                    </div>
                                  ) : null}

                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.documents.length != 0 ||
                                        data.userDetails.pacseeAccount ? (
                                        <CheckIcon />
                                      ) : null}
                                      {data.documents.length != 0 ||
                                        data.userDetails.pacseeAccount ? (
                                        <h3 className="active">
                                          {t("Upload Document")}
                                        </h3>
                                      ) : (
                                        <h3 className="active no_document">
                                          {t("Upload Document")}
                                        </h3>
                                      )}
                                    </div>
                                    {data.documents.length != 0 ||
                                      data.userDetails.pacseeAccount ? (
                                      <button
                                        className="active"
                                        onClick={(e) =>
                                          DetailsUpload(
                                            e,
                                            data._id,
                                            data.ClientId
                                          )
                                        }
                                      >
                                        {t("Update")}
                                      </button>
                                    ) : (
                                      <button
                                        onClick={(e) =>
                                          DetailsUpload(
                                            e,
                                            data._id,
                                            data.ClientId
                                          )
                                        }
                                      >
                                        {t("Upload")}
                                      </button>
                                    )}
                                  </div>
                                  {props?.mode !== "IDF" ? (
                                    <div className="cfInfo">
                                      <div className="cfChk">
                                        {data.survey?.mainComplaint ||
                                        data.survey?.medicalHistory ||
                                        data.survey?.medications ? (
                                          <>
                                            <CheckIcon />
                                            <h3 className="active ">
                                              {t("Medical questionnaire")}
                                            </h3>
                                          </>
                                        ) : (
                                          <h3 className="active no_document">
                                            {t("Medical questionnaire")}
                                          </h3>
                                        )}
                                      </div>
                                      {data.survey?.mainComplaint ||
                                      data.survey?.medicalHistory ||
                                      data.survey?.medications ? (
                                        <button
                                          className="active"
                                          onClick={() => (
                                            props.setMedicalQ(true),
                                            setQId(data._id),
                                            props.setQData(data.survey),
                                            setscheduledate(data?.scheduledDate)
                                          )}
                                        >
                                          {t("Update")}
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => (
                                            props.setMedicalQ(true),
                                            setQId(data._id),
                                            setscheduledate(data?.scheduledDate)
                                          )}
                                        >
                                          {t("Upload ")}
                                        </button>
                                      )}
                                    </div>
                                  ) : null}
                                  {data?.consultation?.meetingGroupId !==
                                    "640ed7103f08dcee2d1f1719" &&
                                  props?.mode !== "IDF" ? (
                                    <div className="cfInfo">
                                      <div className="cfChk">
                                        <h3 className="active no_document">
                                          {t("Add participant to the call")}
                                        </h3>
                                      </div>

                                      <button
                                        className="active"
                                        onClick={() => handleAddParticipant(data)}
                                      >
                                        {t("Invite")}
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </li>
                          );
                        })
                    ) : (
                      <h3 className="no_meeting">
                        {" "}
                        {t("You have no future appointments")}{" "}
                      </h3>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ClientFutureMeeting;
