import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const AddFieldModal = ({
  showFieldModal,
  handleCloseFieldModal,
  handleAddFieldChange,
  addField,
  handleFieldSubmit,
  setDeleteFieldModal,
  summaryFieldArray,
  deleteFieldModal,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showFieldModal}
      onHide={handleCloseFieldModal}
      className={`prescription-alert add-field-modal ${deleteFieldModal ? "delete-show" : ""
        }`}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <h6>{t("Additional prescription field")} </h6>
      </Modal.Header>
      <Modal.Body>
        <div className="add-field-content">
          <Form>
            <Form.Group className="form-group">
              <Form.Label>{t("Field name")} </Form.Label>
              <Form.Control
                type="text"
                name="fieldName"
                placeholder="name"
                onChange={handleAddFieldChange}
                value={addField?.fieldName}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>{t("Field position")} </Form.Label>
              <Form.Select
                name="fieldPosition"
                onChange={handleAddFieldChange}
                value={addField?.fieldPosition}
              >
                <option value="">{t("Select")}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>{t("Field permanently")} </Form.Label>
              <Form.Select
                name="fieldPermanentStatus"
                onChange={handleAddFieldChange}
                value={addField?.fieldPermanentStatus}
              >
                <option value="">{t("Select")} </option>
                <option value={true}>{t("Yes")}</option>
                <option value={false}>{t("No")}</option>
              </Form.Select>
            </Form.Group>
            <div className="field-btn-grid">
              <Button className="border-btn" onClick={handleFieldSubmit}>
                {t("Save")}
              </Button>
              {summaryFieldArray.length > 4 ? (
                <Button
                  variant="outline-danger"
                  onClick={() => setDeleteFieldModal(true)}
                >
                  {t("Remove")} <DeleteOutlineIcon />
                </Button>
              ) : null}
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddFieldModal;
