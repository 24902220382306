import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDyteClient, DyteProvider } from "@dytesdk/react-web-core";
import MyDyteMeeting from "./dyteMeeting";
import { toast } from "react-toastify";
import { getUserDyteToken } from "../../config/service";
import { Network } from "../../config/axios";

const DyteMeetingConatiner = ({ mId }) => {
  const [meeting, initMeeting] = useDyteClient();
  const { meetingId } = useParams();
  const location = useLocation();
  const [isMeetingFinish, setIsMeetingFinish] = useState({
    status: true,
    message: "",
  });

  const getDyteAuthTokenForUser = async () => {
    try {
      const meetingIdentifier = mId || meetingId;
      if (!meetingIdentifier) return;

      const isJoining = location.pathname.includes("/join");

      if (isJoining) {
        const response = await Network().get(
          `meeting/getDyteMeetingToken/${meetingId}`
        );
        const { status, data } = response || {};

        if (status === 200) {
          if (data?.statusCode === 402) {
            setIsMeetingFinish({
              status: data?.success,
              message: data?.message,
            });
            toast.error(data?.message, { toastId: "status" });
            return;
          }
          initMeeting({
            authToken: data?.token,
            defaults: {
              audio: true,
              video: true,
            },
          });
        } else {
          toast.error(data?.message || "Failed to fetch meeting token.", {
            toastId: "fetchError",
          });
        }
      } else {
        const payload = { meetingId: meetingIdentifier };
        const { data: tokenResponse } = await getUserDyteToken(payload);

        if (tokenResponse?.code === 200) {
          initMeeting({
            authToken: tokenResponse?.token,
            defaults: {
              audio: true,
              video: true,
            },
          });
        } else {
          toast.error(tokenResponse?.message || "Failed to retrieve token.", {
            toastId: "tokenError",
          });
        }
      }
    } catch (error) {
      toast.error(error?.message || "Failed to initialize the meeting.", {
        toastId: "initError",
      });
    }
  };

  useEffect(() => {
    getDyteAuthTokenForUser();
  }, [meetingId]);

  return (
    <DyteProvider
      value={meeting}
      fallback={
        <div className="loading-design">
          {!isMeetingFinish?.status ? (
            <h2>{isMeetingFinish?.message}</h2>
          ) : (
            <h2>Loading...</h2>
          )}
        </div>
      }
    >
      <MyDyteMeeting />
    </DyteProvider>
  );
};

export default DyteMeetingConatiner;
