import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import { MDBCard, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import moment from "moment";
import { Network } from "../../config/axios";
import { documentView } from "../../config/service";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import { RotatingLines } from "react-loader-spinner";
import pdfIcon from "../../assets/images/icons/pdf.png";
import imageIcon from "../../assets/images/icons/image-icon.png";
let holdFile;
const UploadDocument = (props) => {
  const inputElement = useRef(null);
  const { pacseeDocument, pacsseeDisc, futureAppointment, singleMeetObj } =
    props;
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const mode = localStorage.getItem("mode");
  const [fileNames, setFileNames] = useState([]);
  const [flName, setFlName] = useState();
  const [flExt, setFlExt] = useState();
  const [fileDeleteId, setFileDeleteId] = useState();
  const [confirm, setConfirm] = useState(false);
  const [iframe, setIframe] = useState(false);
  const [iframeData, setIframeData] = useState();
  const [document, setDocument] = useState([]);
  const [loader, setLoader] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [renameFile, setRenameFile] = useState(flName);
  const handleClose3 = () => setConfirm(false);
  const handleDrop = (acceptedFiles) => {
    holdFile = acceptedFiles.target.files[0];
    setFlExt(holdFile.name.split(".").pop());
    setFlName(holdFile.name.split(".")[0]);
    setRenameModal(true);
  };
  const handleRenameFile = (e) => {
    setRenameFile(e.target.value + "." + flExt);
  };
  const fileRenameModal = () => setRenameModal(false);

  useEffect(() => {
    allDocument();
  }, []);
  const allDocument = async () => {
    try {
      const response = await Network().get(
        `/meeting/meetingDocumentsByMeetingId/${props.meetingId}`,
        {
          headers: {
            authorization: token,
          },
        }
      );

      if ([200, 201, 202].includes(response.data.code)) {
        setDocument(response.data.meetingDocuments);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Upload = async () => {
    setLoader(true);
    let formData = new FormData();
    formData.append("document", holdFile, renameFile);

    formData.append("clientId", props.cId);
    formData.append("meetingId", props.meetingId);
    try {
      const response = await Network().post(
        "/users/uploadDocuments",
        formData,
        {
          headers: {
            authorization: token,
          },
        }
      );

      if ([200, 201, 202].includes(response.data.code)) {
        toast.success(t(response.data.msg));
        setFileNames([]);
        setTimeout(() => {
          allDocument();
        });
        if (props?.mode === "IDF") {
          props?.setIdfApprove(false);
        }
      }
    } catch (error) {
      if (error.response.status == 413) {
        toast.error(t(error.response.data.message));
        setFileNames([]);
      }
      console.log(error);
    }
  };
  function formatFileSize(bytes) {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const viewPdf = async (path) => {
    const data = {
      url: path,
    };

    const response = await documentView(data);
    setTimeout(() => {
      window.open(response.data, "_blank");
    });
  };
  const deleteFile = async () => {
    try {
      const response = await Network().delete(
        "/users/deleteDocumentsByDocumentId/" + fileDeleteId,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 203].includes(response.status)) {
        toast.success(t(response.data.msg));
        setDocument(
          document.filter((d) => {
            return d._id != fileDeleteId;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadMRI = async () => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await Network().get(
        `/users/uploadPacssee?userId=${userId}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if (response.data.url) {
        pacseeIframe(response.data.url);
        // window.location.replace(response.data.url);
      } else {
        toast.error(t(response.data.message));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const pacseeIframe = (data) => {
    setIframe(true);
    setIframeData(data);
  };

  const handleCloseModal = () => {
    props.handleClose();
    if (mode === "IDF") {
      props.setCurrentAppointmentIndex((prevIndex) => prevIndex + 1);
      if (props.currentAppointmentIndex >= props.futureAppointment.length - 1) {
        localStorage.setItem("uploadModal", "close");
      }
    }
  };
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <div>
      <div>
        <Modal
          open={iframe}
          onClose={() => setIframe(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalContent"
        >
          <div className="Iframe_content">
            <Iframe url={iframeData} className="tranzila_iframe" id="myId" />
            <Button
              className="iframeclose"
              onClick={() => (setIframe(false), props.handleClose())}
            >
              {t("Close")}
            </Button>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          open={confirm}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalDiv modal-confirmation"
          style={{
            backgroundColor: "transparent",
            width: "60%",
            margin: "auto",
            height: "50%",
          }}
        >
          <MDBCard className="modal_card">
            <MDBCardBody>
              <div className="confirmation-modal">
                <h2> {t("Are you sure")}</h2>
                <div className="yes_no">
                  <Button
                    disabled={loader}
                    size="sm"
                    className="document1"
                    onClick={() => setConfirm(false)}
                  >
                    {" "}
                    {t("No")}
                  </Button>
                  <Button
                    disabled={loader}
                    size="sm"
                    className="document1"
                    onClick={() => (setConfirm(false), deleteFile())}
                  >
                    {" "}
                    {t("Yes")}
                  </Button>
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </Modal>
      </div>
      {renameModal ? (
        <div>
          <Modal
            open={renameModal}
            onHide={fileRenameModal}
            backdrop="static"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modalContent"
          >
            <MDBCard className="modal_card">
              <MDBCardBody>
                <div className="rename-modal-body">
                  <h3 className="support_text">
                    {t("Please rename your file")}
                  </h3>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Control
                      type="text"
                      defaultValue={flName}
                      placeholder="Rename your file"
                      onChange={(e) => handleRenameFile(e)}
                    />
                  </Form.Group>
                  <div className="yes_no">
                    <Button
                      disabled={loader}
                      size="sm"
                      className="document1"
                      onClick={() => fileRenameModal()}
                    >
                      {t("Close")}
                    </Button>
                    <Button
                      disabled={loader}
                      size="sm"
                      className="document1"
                      onClick={() => {
                        Upload();
                        fileRenameModal();
                      }}
                    >
                      {t("Upload")}
                    </Button>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </Modal>
        </div>
      ) : null}
      <Modal
        open={props.uploadModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody className={loader ? "fade_content" : ""}>
            <MDBCardTitle className="headerText">
              {t("Upload Some Documents")}
            </MDBCardTitle>
            <h4 className="support_text">
              {t("Upload only images, pdf, txt files")}
            </h4>
            {props?.mode !== "IDF" && pacsseeDisc ? (
              <h5 className="disclaimer-text">
                {t("Pacsee disclaimer Message")}
              </h5>
            ) : null}

            {futureAppointment && futureAppointment?.length > 1 && singleMeetObj ? (
              <li className="updated-design idf-mode-meet">
                <div className="doctorInfo">
                  <div className="cfDate">
                    <h3>
                      {moment(singleMeetObj?.scheduledDate).format(
                        "DD.MM.YYYY"
                      )}
                    </h3>
                    <div className="cfDay">
                      <span>
                        {" "}
                        {t(
                          weekday[
                            new Date(singleMeetObj?.scheduledDate).getDay()
                          ]
                        )}
                      </span>
                      <span>
                        {moment(singleMeetObj?.scheduledDate).format("HH:mm")}
                      </span>
                    </div>
                  </div>
                  <h2>
                    {" "}
                    {t(singleMeetObj?.doctorDataScheduled?.title)}{" "}
                    {`${singleMeetObj?.doctorDetails?.first_name} ${singleMeetObj?.doctorDetails?.last_name} `}
                  </h2>
                  <div className="consultation-info">
                    <h4>
                      {lang === "HE"
                        ? singleMeetObj?.consultation?.heb
                        : singleMeetObj?.consultation?.eng}
                    </h4>
                  </div>

                  {singleMeetObj?.doctorDataScheduled?.experience ? (
                    <div className="cfLocate">
                      {singleMeetObj?.doctorDataScheduled?.experience}
                    </div>
                  ) : null}
                </div>
              </li>
            ) : null}
            <div>
              <div className="dropcontainer">
                <div className="dragAndDrop">
                  <form>
                    <input
                      type="file"
                      onChange={handleDrop}
                      className="filechoosen"
                      accept="text/plain, application/pdf, image/jpg,image/jpeg,image/png"
                      id={"fileUploadInput"}
                      hidden
                      ref={inputElement}
                    />
                    <Button
                      disabled={loader}
                      variant="primary"
                      size="lg"
                      className="buttton_content mri_ct_xray"
                      onClick={() => {
                        inputElement.current.click();
                      }}
                    >
                      {t("Upload Document")}
                    </Button>
                  </form>
                </div>
                {props?.mode !== "IDF" ? (
                  <Button
                    disabled={loader}
                    variant="primary"
                    size="lg"
                    className="buttton_content mri_ct_xray"
                    onClick={uploadMRI}
                  >
                    {t("Upload MRI / X-RAY / CT")}
                  </Button>
                ) : null}
              </div>
              {pacseeDocument?.length != 0 ? (
                <>
                  {pacseeDocument?.map((d, i) => {
                    return (
                      <div className="row fileList">
                        <li key={i} className="col-md-6">
                          {t("File")}-{d.study_descr}
                        </li>
                        <div className="col-md-3">
                          <Button
                            disabled={loader}
                            onClick={() =>
                              window.open(d.view_url_options, "_blank")
                            }
                          >
                            {" "}
                            {t("View")}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              {loader && (
                <div className="loader">
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={loader}
                  />
                </div>
              )}
              <div>
                {document.length != 0
                  ? document.map((fileName, i) => {
                      return (
                        <div className="row fileList" key={i}>
                          <div className="col-md-5">
                            <p className="file-name">
                              {t("File Name")}-{fileName.name}
                            </p>
                          </div>
                          <div key={fileName} className="col-md-3">
                            <div className="file-thumbnail">
                              <img
                                src={
                                  fileName?.fileType === "pdf" ||
                                  fileName?.fileType === "application/pdf"
                                    ? pdfIcon
                                    : imageIcon
                                }
                                alt="thumbnail"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <Button
                              disabled={loader}
                              onClick={() => viewPdf(fileName.path)}
                              className="view-doc-pdf"
                            >
                              {" "}
                              {t("View")}
                            </Button>
                          </div>
                          <div className="col-md-2">
                            <DeleteIcon
                              onClick={() =>
                                !loader
                                  ? (setFileDeleteId(fileName._id),
                                    setConfirm(true))
                                  : null
                              }
                              sx={{
                                color: !loader ? "#e60000" : "#c7adad",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  : null}

                {fileNames.map((fileName, i) => {
                  return (
                    <div className="fileList" key={i}>
                      <li>
                        {t("File Name")}-{fileName.name}{" "}
                        {`(${formatFileSize(fileName.size)})`}
                      </li>
                    </div>
                  );
                })}
              </div>
              <Button
                disabled={loader}
                variant="primary"
                size="lg"
                className="buttton_content close-doc-btn"
                onClick={() => handleCloseModal()}
              >
                {t("Close")}
              </Button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default UploadDocument;
