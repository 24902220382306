import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import "../ThankYouPage/index.css";
import thank from "../../assets/images/new/thank-you-banner.png";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import MadnessAlertModal from "../common/madnessAlert";

const ThankYou = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [madnessAlert, setMadnessAlert] = useState(false);
  const { madnessAlertId } = location.state || {};

  const handleMadnessAlertModal = () => {
    setMadnessAlert(false);
  };

  useEffect(() => {
    if (madnessAlertId) {
      setMadnessAlert(true);
    }
  }, [madnessAlertId]);
  return (
    <Layout>
      <div className="thankYouMeeting">
        <div className="container">
          <div className="thankYouMeeting-content">
            <img src={thank} alt="icon" className="img-fluid" />
            <h2
              dangerouslySetInnerHTML={{
                __html: t("Your meeting request is successfully sent"),
              }}
            />
            <p> {t("Updates will be received on WhatsApp soon")} </p>
            <div className="viewpending">
              <Button onClick={() => navigate("/Queues")}>
                {t("View my pending request")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <MadnessAlertModal
        madnessAlert={madnessAlert}
        setMadnessAlert={setMadnessAlert}
        handleMadnessAlertModal={handleMadnessAlertModal}
      />
    </Layout>
  );
};

export default ThankYou;
