import { userInstance } from "./axios";

export const userLogin = (data) => {
  return userInstance("POST", "users/login", data, {}, true);
};
export const verifyOtp = (data) => {
  return userInstance("POST", "users/verify", data, {}, true);
};
export const doctorSignUp = (data) => {
  return userInstance("POST", "doctor/signup", data, {}, true);
};
export const adminAllConsultation = () => {
  return userInstance("GET", "admin/getAllConsultation", {}, {}, false);
};
export const adminAllSubscriptions = () => {
  return userInstance("GET", "admin/subscriptions", {}, {}, false);
};
export const adminGetPartnesList = () => {
  return userInstance("GET", "admin/getPartners", {}, {}, false);
};
export const rejectPartnerOffer = (data) => {
  return userInstance("POST", "meeting/rejectPartnerOffer", data);
};
export const userPartnerConsent = (data) => {
  return userInstance("POST", "users/partnerConsent", data);
};
export const allConsultation = () => {
  return userInstance("GET", "consultation/getAllConsultation", {}, {}, true);
};
export const getUserProfile = (data) => {
  return userInstance("POST", "admin/getUserProfile", data);
};
export const userGetUserProfile = (data) => {
  return userInstance("POST", "users/getUserProfile", data);
};
export const updateUserProfile = (data) => {
  return userInstance("PATCH", "users/updateUserProfile", data);
};
export const MeetingPrice = () => {
  return userInstance("GET", "users/getDefaultPrice", {}, {}, true);
};
export const createMeeting = (data) => {
  return userInstance("POST", "meeting/createAppointment", data, {}, true);
};
export const adminCreateMeeting = (data) => {
  return userInstance("POST", "admin/createAppointment", data, {}, true);
};
export const meetingVerifyOtp = (data) => {
  return userInstance("POST", "meeting/verifyMeetingOtp", data, {}, true);
};
export const getCancel = (data) => {
  return userInstance("POST", "admin/cancelMeeting", data);
};
export const Price = (data) => {
  return userInstance("POST", "users/getMeetingPrice", data);
};
export const documentView = (data) => {
  return userInstance("POST", "users/viewDocument", data);
};
export const medicalQuestionnaire = (data) => {
  return userInstance("POST", "users/addUpdateQuestionairre", data);
};
export const getAllAppointment = (data) => {
  return userInstance("POST", "meeting/getAllAppointment", data);
};
export const doctorComment = (data) => {
  return userInstance("POST", "meeting/doctorCommentOnMeeting", data);
};
export const doctorAllAppointment = (data) => {
  return userInstance("POST", "meeting/getAllAppointmentByDoctor", data);
};
export const doctorOfferDate = (data) => {
  return userInstance("POST", "doctor/offerDatesByMeetingid", data);
};
export const adminOfferDate = (data) => {
  return userInstance("POST", "admin/offerDatesByMeetingid", data);
};
export const doctorSummary = (data) => {
  return userInstance("POST", "doctor/addSummary", data);
};
export const doctorCreatePrescription = (data) => {
  return userInstance("POST", "doctor/prescriptions/create", data);
};
export const doctorSetPrescriptionSettings = (data) => {
  return userInstance("POST", "doctor/setPrescriptionSettings", data);
};
export const doctorGeneratePdf = (data) => {
  return userInstance("POST", "doctor/generatePdf", data);
};
export const refreshTokenGenerate = (data) => {
  return userInstance("GET", "users/refresh", data);
};
export const adminAllAppoinment = (data) => {
  return userInstance("GET", "admin/getAllAppointment", data);
};
export const adminAppoinment = (data) => {
  return userInstance("POST", "admin/getAppointment", data);
};
export const adminUpdatePrice = (data) => {
  return userInstance("POST", "admin/updatePrice", data);
};
export const adminCovertPartnersMeeting = (data) => {
  return userInstance("POST", "meeting/covertPartnersMeeting", data);
};
export const generatePartnersXmlMeetingReport = (data) => {
  return userInstance("POST", "reports/generatePartnersXmlMeetingReport", data);
};
export const uploadMeetingFile = (data) => {
  return userInstance("POST", "admin/uploadMeetingFile", data);
};
export const uploadMyDoctorFile = (data) => {
  return userInstance("POST", "admin/saveMyDoctors", data);
};
export const adminUpdateStatus = (data) => {
  return userInstance("POST", "admin/updateDoctorStatus", data);
};
export const adminGetUsers = (data) => {
  return userInstance("POST", "admin/getUsers", data);
};
export const adminCreateUser = (data) => {
  return userInstance("POST", "admin/createUser", data);
};
export const adminNoticiation = (data) => {
  return userInstance("POST", "admin/updateNotificationStatusOfDoctor", data);
};
export const doctorNoticiation = (data) => {
  return userInstance("POST", "doctor/updateNotificationStatusOfDoctor", data);
};
export const userCalendarNotification = (data) => {
  return userInstance(
    "POST",
    "users/activateCalendarNotificationByMeetingId",
    data
  );
};
export const collectiveData = () => {
  return userInstance("GET", "admin/getCollectives", {}, {}, false);
};

export const doctorSubscriptions = (data) => {
  return userInstance("GET", "doctor/subscriptions", data);
};

export const doctorSaveTemplate = (data) => {
  return userInstance("POST", "doctor/saveTemplate", data);
};

export const doctorUpdateTemplate = (data) => {
  return userInstance("POST", "doctor/updateTemplate", data);
};

export const doctorGetTemplate = () => {
  return userInstance("GET", "doctor/getTemplates");
};


export const getUserDyteToken = (data) => {
  return userInstance("POST", "users/dyte-token", data);
};