import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./queues.css";
import { Modal } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Network } from "../config/axios";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

export const ImageCrop = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [originalData, setOriginalData] = useState();
  const [isLoader, setIsloader] = useState(false);
  const onChange = (e) => {
    setOriginalData(e.target.files[0]);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
    ImageUpload(cropper.getCroppedCanvas().toDataURL());
  };

  const SaveData = () => {
    // ImageUpload();
  };
  const ImageUpload = async (data) => {
    let formData = new FormData();
    formData.append("avatar", originalData);
    formData.append("croppedAvatar", data);

    try {
      setIsloader(true);
      // make axios post request
      const response = await Network().post("/doctor/upload", formData, {
        headers: {
          authorization: token,
        },
      });

      if ([201, 200, 203].includes(response.data.code)) {
        setIsloader(false);
        toast.success(t(response.data.msg));
        setShowDiv(false);
        props.handleClose();
        setOriginalData(null);
        props.setImageData1(data);
        props.userDetails();
      } else {
        setIsloader(false);
        toast.error(t(response.data.msg));
      }
    } catch (error) {
      setIsloader(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <div
        className="crop_content"
        style={{
          height: image ? "auto" : "25%",
        }}
      >
        <h1> {t("Change Profile Picture")}</h1>
        <input type="file" onChange={onChange} style={{ margin: "0 auto" }} />
        <br />
        <br />
        {isLoader && (
          <div className="spinner">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="150"
              visible={true}
            />
          </div>
        )}

        <Cropper
          // style={{ height: "250px", width: "30%", margin: "0 auto" }}
          // zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
        {image ? (
          <div>
            <h1>
              <Button
                className="buttton_content"
                disabled={isLoader}
                onClick={getCropData}
              >
                {t("Save")}
              </Button>
            </h1>
          </div>
        ) : null}
      </div>
      <div>
        <Modal
          open={showDiv}
          onClose={() => setShowDiv(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            backgroundColor: "#000",
            overflow: "auto",
            width: "100%",
            margin: "30px auto",
            height: "100%",
          }}
          // className="modalClass"
        >
          <div style={{ height: "100%", width: "30%", margin: "0 auto" }}>
            <div
              className="box"
              style={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                height: "auto",
              }}
            >
              <img style={{ width: "100%" }} src={cropData} alt="cropped" />
              <div className="concelconfirm">
                <Button className="cancel" onClick={() => setShowDiv(false)}>
                  {" "}
                  {t("Cancel")}
                </Button>
                <Button className="confirm" onClick={SaveData}>
                  {" "}
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <br style={{ clear: "both" }} />
    </div>
  );
};

export default ImageCrop;
