import React, { useState } from "react";
import "../Login/login.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { userInstance } from "../../config/axios";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [formValue, setformValue] = React.useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [idError, setIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cpasswordError, setCPasswordError] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    // store the states in the form data
    let data = {
      oldPassword: formValue.old_password,
      password: formValue.new_password,
    };

    if (validate()) {
      try {
        // make axios post request
        const response = await userInstance().patch(
          "/users/changePassword",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: token,
            },
          }
        );

        if ([200, 201, 203].includes(response.status)) {
          toast.success(t(response.data.msg));
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          toast.error(t(response.data.msg));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const validate = () => {
    let isValid = true;

    if (!formValue.old_password) {
      isValid = false;
      setIdError(t("Please Enter Old Password"));
    } else {
      setIdError("");
    }

    if (!formValue.new_password) {
      isValid = false;
      setPasswordError(t("Please Enter New Password"));
    } else {
      setPasswordError("");
    }
    if (
      formValue.confirm_new_password &&
      formValue.new_password != formValue.confirm_new_password
    ) {
      isValid = false;
      setCPasswordError(t("Password does not match"));
    } else {
      setCPasswordError("");
    }

    return isValid;
  };

  return (
    <div>
      <div className="newRegd">
        <div style={{ marginTop: "30%" }}>
          <Form>
            <Form.Group className="form-group ">
              <input
                className="form-control"
                name="old_password"
                type="text"
                onChange={handleChange}
                id="old_password"
                placeholder={t("Old Password")}
              />
            </Form.Group>

            <span style={{ color: "red", textAlign: "center" }}>
              {t(`${idError}`)}
            </span>
            <Form.Group className="form-group ">
              <input
                className="form-control"
                name="new_password"
                type="text"
                onChange={handleChange}
                id="new_password"
                placeholder={t("New Password")}
              />
            </Form.Group>
            <span style={{ color: "red", textAlign: "center" }}>
              {t(`${passwordError}`)}
            </span>

            <Form.Group className="form-group ">
              <input
                className="form-control"
                name="confirm_new_password"
                type="text"
                onChange={handleChange}
                id="confirm_new_password"
                placeholder={t("Confirm New Password")}
              />
            </Form.Group>
            <span style={{ color: "red", textAlign: "center" }}>
              {t(`${cpasswordError}`)}
            </span>

            <button type="submit" onClick={handleSubmit}>
              {t("Submit")}
            </button>
          </Form>
          <hr
            style={{
              background: "black",
              color: "lime",
              borderColor: "black",
              height: "1px",
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
