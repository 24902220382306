export const MenuIconSVG = (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.29395 20.4604H26.6273M1.29395 1.46045H26.6273H1.29395ZM1.29395 10.9605H26.6273H1.29395Z"
      stroke="white"
      stroke-width="2.375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const LoginIconSVG = (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1254 5.0641C9.72188 4.66054 9.06759 4.66054 8.66404 5.0641C8.26048 5.46766 8.26048 6.12195 8.66404 6.5255L8.72826 6.58972C9.43646 7.29792 8.93488 8.50884 7.93333 8.50884H1.04386C0.467352 8.50884 0 8.97619 0 9.5527C0 10.1292 0.467352 10.5966 1.04386 10.5966H7.93333C8.93488 10.5966 9.43646 11.8075 8.72826 12.5157L8.66404 12.5799C8.26048 12.9834 8.26048 13.6377 8.66404 14.0413C9.06759 14.4448 9.72188 14.4448 10.1254 14.0413L13.665 10.5017C14.1891 9.97758 14.1891 9.12781 13.665 8.60368L10.1254 5.0641ZM18.7895 15.5176C18.7895 16.2588 18.1886 16.8597 17.4474 16.8597H11.4825C10.9059 16.8597 10.4386 17.3271 10.4386 17.9036C10.4386 18.4801 10.9059 18.9474 11.4825 18.9474H18.7895C19.9377 18.9474 20.8772 18.008 20.8772 16.8597V2.24568C20.8772 1.09743 19.9377 0.157959 18.7895 0.157959H11.4825C10.9059 0.157959 10.4386 0.625311 10.4386 1.20182C10.4386 1.77833 10.9059 2.24568 11.4825 2.24568H17.4474C18.1886 2.24568 18.7895 2.84656 18.7895 3.58778V15.5176Z"
      fill="white"
    />
  </svg>
);

export const SearchIconSVG = (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7673 20.9617L16.1524 16.2775L20.7673 20.9617ZM18.7099 10.0172C18.7099 12.367 17.7886 14.6205 16.1488 16.282C14.5089 17.9436 12.2848 18.877 9.96572 18.877C7.64663 18.877 5.42253 17.9436 3.78269 16.282C2.14284 14.6205 1.22159 12.367 1.22159 10.0172C1.22159 7.66748 2.14284 5.41397 3.78269 3.75244C5.42253 2.09091 7.64663 1.15747 9.96572 1.15747C12.2848 1.15747 14.5089 2.09091 16.1488 3.75244C17.7886 5.41397 18.7099 7.66748 18.7099 10.0172V10.0172Z"
      stroke="#fff"
      strokeWidth="2.13781"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseIconSVG = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.53208 7.88792L14.5439 2.88704C14.7634 2.66754 14.8867 2.36982 14.8867 2.05939C14.8867 1.74896 14.7634 1.45125 14.5439 1.23174C14.3244 1.01223 14.0268 0.888916 13.7164 0.888916C13.406 0.888916 13.1083 1.01223 12.8888 1.23174L7.88867 6.24428L2.8885 1.23174C2.66903 1.01223 2.37136 0.888916 2.06097 0.888916C1.75059 0.888916 1.45291 1.01223 1.23344 1.23174C1.01396 1.45125 0.890661 1.74896 0.890661 2.05939C0.890661 2.36982 1.01396 2.66754 1.23344 2.88704L6.24526 7.88792L1.23344 12.8888C1.12419 12.9972 1.03748 13.1261 0.97831 13.2681C0.919137 13.4102 0.888672 13.5626 0.888672 13.7165C0.888672 13.8703 0.919137 14.0227 0.97831 14.1648C1.03748 14.3068 1.12419 14.4357 1.23344 14.5441C1.34179 14.6534 1.4707 14.7401 1.61273 14.7993C1.75476 14.8584 1.90711 14.8889 2.06097 14.8889C2.21484 14.8889 2.36718 14.8584 2.50921 14.7993C2.65124 14.7401 2.78015 14.6534 2.8885 14.5441L7.88867 9.53157L12.8888 14.5441C12.9972 14.6534 13.1261 14.7401 13.2681 14.7993C13.4102 14.8584 13.5625 14.8889 13.7164 14.8889C13.8702 14.8889 14.0226 14.8584 14.1646 14.7993C14.3066 14.7401 14.4356 14.6534 14.5439 14.5441C14.6532 14.4357 14.7399 14.3068 14.799 14.1648C14.8582 14.0227 14.8887 13.8703 14.8887 13.7165C14.8887 13.5626 14.8582 13.4102 14.799 13.2681C14.7399 13.1261 14.6532 12.9972 14.5439 12.8888L9.53208 7.88792Z"
      fill="white"
    />
  </svg>
);

export const EnvelopeIconSVG = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    id="magicoon-Regular"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>{".cls-1{fill:#fff;}"}</style>
    </defs>
    <title>{"envelope"}</title>
    <g id="envelope-Regular">
      <path
        id="envelope-Regular-2"
        data-name="envelope-Regular"
        className="cls-1"
        d="M17,4.25H7A4.756,4.756,0,0,0,2.25,9v6A4.756,4.756,0,0,0,7,19.75H17A4.756,4.756,0,0,0,21.75,15V9A4.756,4.756,0,0,0,17,4.25ZM7,5.75H17a3.255,3.255,0,0,1,3,2.013L13.8,11.9a3.246,3.246,0,0,1-3.606,0L4,7.763A3.255,3.255,0,0,1,7,5.75Zm10,12.5H7A3.254,3.254,0,0,1,3.75,15V9.4l5.615,3.744a4.74,4.74,0,0,0,5.27,0L20.25,9.4V15A3.254,3.254,0,0,1,17,18.25Z"
      />
    </g>
  </svg>
);

export const PhoneIconSVG = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    id="magicoon-Regular"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>{".cls-1{fill:#fff;}"}</style>
    </defs>
    <title>{"phone"}</title>
    <g id="phone-Regular">
      <path
        id="phone-Regular-2"
        data-name="phone-Regular"
        className="cls-1"
        d="M21.686,16.6h0a3.608,3.608,0,0,0-.08-.358,1.72,1.72,0,0,0-1.386-1.211l-4.332-.721a1.746,1.746,0,0,0-1.669.641c-.054.069-.106.139-.154.211a.3.3,0,0,1-.365.133,9.6,9.6,0,0,1-5-5,.3.3,0,0,1,.129-.361c.095-.064.186-.132.276-.2a1.768,1.768,0,0,0,.631-1.645L9.068,3.833A1.736,1.736,0,0,0,7.9,2.444a3.647,3.647,0,0,0-.508-.127A4.286,4.286,0,0,0,3.836,3.3a4.528,4.528,0,0,0-1.584,3.57A15.292,15.292,0,0,0,17.125,21.747c.041,0,.082,0,.123,0a4.52,4.52,0,0,0,3.43-1.576A4.315,4.315,0,0,0,21.686,16.6Zm-2.142,2.593a3.083,3.083,0,0,1-2.379,1.055A13.793,13.793,0,0,1,3.751,6.833,3.039,3.039,0,0,1,4.813,4.441a2.771,2.771,0,0,1,1.819-.692,2.881,2.881,0,0,1,.5.044,2.237,2.237,0,0,1,.3.075.244.244,0,0,1,.159.2l.671,4.255a.262.262,0,0,1-.092.243c-.058.047-.117.091-.178.133a1.777,1.777,0,0,0-.661,2.2A11.106,11.106,0,0,0,13.1,16.671a1.787,1.787,0,0,0,2.2-.664c.03-.044.061-.087.095-.13a.26.26,0,0,1,.243-.088l4.332.721a.229.229,0,0,1,.188.137c.017.062.033.131.047.21A2.83,2.83,0,0,1,19.544,19.193Z"
      />
    </g>
  </svg>
);

export const LangIconSVG = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    id="magicoon-Regular"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>{".cls-1{fill:#fff;}"}</style>
    </defs>
    <title>{"globe"}</title>
    <g id="globe-Regular">
      <path
        id="globe-Regular-2"
        data-name="globe-Regular"
        className="cls-1"
        d="M21.188,8.752A9.686,9.686,0,0,0,13.4,2.347a10.081,10.081,0,0,0-2.792,0,9.679,9.679,0,0,0-7.789,6.4,9.663,9.663,0,0,0,0,6.5,9.686,9.686,0,0,0,7.792,6.4,10.072,10.072,0,0,0,1.4.1,9.919,9.919,0,0,0,1.4-.1,9.679,9.679,0,0,0,7.789-6.4,9.663,9.663,0,0,0,0-6.5Zm-1.844-.5H16.3a16.182,16.182,0,0,0-1.443-3.977A8.165,8.165,0,0,1,19.344,8.251ZM20.25,12a8.089,8.089,0,0,1-.321,2.251H16.576A16.454,16.454,0,0,0,16.75,12a16.387,16.387,0,0,0-.173-2.249H19.93A8.09,8.09,0,0,1,20.25,12ZM8.938,14.251A14.862,14.862,0,0,1,8.75,12a14.879,14.879,0,0,1,.188-2.249h6.125A14.976,14.976,0,0,1,15.25,12a14.894,14.894,0,0,1-.188,2.251Zm-4.867,0a8.046,8.046,0,0,1,0-4.5H7.424A16.28,16.28,0,0,0,7.25,12a16.429,16.429,0,0,0,.174,2.251ZM12.849,3.809a14.8,14.8,0,0,1,1.912,4.442H9.238a14.816,14.816,0,0,1,1.913-4.442A8.459,8.459,0,0,1,12,3.75,8.439,8.439,0,0,1,12.849,3.809Zm-3.708.465A16.257,16.257,0,0,0,7.7,8.251H4.656A8.16,8.16,0,0,1,9.141,4.274ZM4.657,15.751H7.7a16.3,16.3,0,0,0,1.442,3.975A8.16,8.16,0,0,1,4.657,15.751Zm6.494,4.44a14.824,14.824,0,0,1-1.912-4.44h5.522a14.806,14.806,0,0,1-1.912,4.44A8.6,8.6,0,0,1,11.151,20.191Zm3.708-.465A16.251,16.251,0,0,0,16.3,15.751h3.042A8.158,8.158,0,0,1,14.859,19.726Z"
      />
    </g>
  </svg>
);
