import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashborad/dashboard.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { doctorComment } from "../../config/service";
import { toast } from "react-toastify";
const CommentModal = (props) => {
  const { t } = useTranslation();

  const uploadComment = async () => {
    let data = {
      meetingId: props.mid,
      comment: props.note,
    };
    const response = await doctorComment(data);
    toast.success(t(response.data.msg));
    props.setNote("");
    props.handleClose();
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody className="textchange">
            <h1> {t("Meeting comments for internal use only")}</h1>
            <h3 className="commenttext"> {t("Notes")} </h3>
            <textarea
              rows={2}
              className="form-control textDiv"
              name="mainComplaint"
              defaultValue={props.cdata ? props.cdata : ""}
              onChange={(e) => props.setNote(e.target.value)}
            />
            <Button
              size="lg"
              onClick={uploadComment}
              className="buttton_content"
            >
              {t("Save")}
            </Button>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default CommentModal;
