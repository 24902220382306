import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../../../My Queues/queues.css";
import { Modal } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Network } from "../../../config/axios";
import { useTranslation } from "react-i18next";

export const AdminImageCropper = (props) => {
  const { t } = useTranslation();
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState("");
  const [showDiv, setShowDiv] = useState(false);
  const [originalData, setOriginalData] = useState();
  const onChange = (e) => {
    setOriginalData(e.target.files[0]);
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
    setShowDiv(true);
  };

  const SaveData = () => {
    ImageUpload();
  };
  const ImageUpload = async () => {
    let formData = new FormData();
    formData.append("avatar", originalData);
    formData.append("croppedAvatar", cropData);
    formData.append("doctorId", props.doctorId);

    try {
      // make axios post request
      const response = await Network().post(
        "admin/updateProfilePictureOfDoctor",
        formData,
        {
          headers: {
            authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      if ([201, 200, 203].includes(response.data.code)) {
        toast.success(t(response.data.msg));
        setShowDiv(false);
        props.handleClose();
        props.setImageData1(cropData);
      } else {
        toast.error(t(response.data.msg));
      }
    } catch (error) {
      toast.error(error.response.data.message);
      //   setErrorMsg(true);
    }
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          padding: "20px",
          height: image ? "auto" : "25%",
        }}
      >
        <h1> {t("Change Profile Picture")}</h1>
        <input type="file" onChange={onChange} style={{ margin: "0 auto" }} />
        <br />
        <br />
        {image ? (
          <>
            <Cropper
              // style={{ height: "250px", width: "30%", margin: "0 auto" }}
              // zoomTo={0.5}
              style={{ height: 500, width: "100%" }}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />

            <div>
              <h1>
                <Button
                  className="cancel"
                  style={{
                    backgroundColor: "#14427d",
                    borderRadius: "35px",
                    margin: "10px",
                  }}
                  onClick={getCropData}
                >
                  {t("Crop Image")}
                </Button>
              </h1>
            </div>
          </>
        ) : null}
      </div>
      <div>
        <Modal
          open={showDiv}
          onClose={() => setShowDiv(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            // backgroundColor: "#fff",
            overflow: "auto",
            width: "30%",
            margin: "30px auto",
            height: "70%",
          }}
          // className="modalClass"
        >
          <div style={{ height: "100%" }}>
            <div
              className="box"
              style={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                height: "auto",
              }}
            >
              <img style={{ width: "100%" }} src={cropData} alt="cropped" />
              <div className="concelconfirm">
                <Button className="cancel" onClick={() => setShowDiv(false)}>
                  {" "}
                  {t("Cancel")}
                </Button>
                <Button className="confirm" onClick={SaveData}>
                  {" "}
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <br style={{ clear: "both" }} />
    </div>
  );
};

export default AdminImageCropper;
