import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { getCancel } from "../../config/service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CancelModal = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const cancelMeeting = async () => {
    setLoading(true);
    const data = {
      meetingId: props.cancelId,
      userId: localStorage.getItem("userId"),
    };
    const response = await getCancel(data);
    toast.success(t(response.data.msg));
    props.appointment.filter((d) => {
      return d._id !== props.cancelId;
    });
    props.handleClose1();
    setLoading(false);
  };
  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1> {t("Are you sure?")}</h1>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <MDBCardText>
                <Button
                  size="lg"
                  className="buttton_content cancel-b"
                  onClick={() => props.handleClose1()}
                >
                  {t("No")}
                </Button>
              </MDBCardText>
              <MDBCardText>
                <Button
                  variant="primary"
                  size="lg"
                  className="buttton_content cancel-b"
                  onClick={cancelMeeting}
                  disabled={loading}
                >
                  {t("Yes")}
                </Button>
              </MDBCardText>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default CancelModal;
