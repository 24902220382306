import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "../../contact/contact.css";
import { Modal } from "@material-ui/core";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { RotatingLines } from "react-loader-spinner";
import { getUserProfile, allConsultation } from "../../../config/service";
import profile from "../../../assets/images/dashboard/docProfile.png";
const ProfileDetails = ({ detailsopen, role, userid, handleDetailsClose }) => {
  const { t } = useTranslation();
  let lang = localStorage.getItem("lang");
  const [show, setShow] = useState(false);
  const [profileDetails, setProfileDetails] = useState();
  const [terms, setTerms] = useState(false);
  const [consent, setConsent] = useState(false);
  const patienttype = [
    { eng: "Children", heb: "ילד.ה" },
    { eng: "Adults", heb: "מבוגר" },
  ];
  const [consultData, setConsultData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedtype, setSelectedType] = useState([]);
  const [id, setSelectId] = useState([]);
  const [typevalue, setTypeValue] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [doctorData, setDoctorData] = useState("");
  useEffect(() => {
    consultingFields();
    userDetails();
    setShow(profileDetails?.isFromIsrael);
    setTerms(profileDetails?.termsAndConditions);
  }, [detailsopen]);
  useEffect(() => {
    setProfileDetails();
  }, [handleDetailsClose]);

  const userDetails = async () => {
    const data = {
      userId: userid,
    };
    const response = await getUserProfile(data);
    if (role === "client") {
      setProfileDetails(response.data.profileDetails[0]);
      setTerms(response?.data?.profileDetails?.termsAndConditions);
      setShow(response?.data?.profileDetails?.isFromIsrael);
      setConsent(response?.data?.profileDetails?.insuranceConsent);
      setProfileImage(response.data.profileDetails?.croppedAvatar);
    } else {
      setProfileDetails(response.data.profileDetails[0]);
      setTerms(response?.data?.profileDetails[0].termsAndConditions);
      setShow(response?.data?.profileDetails[0].isFromIsrael);
      setProfileImage(
        response.data.profileDetails[0].croppedAvatar
          ? response.data.profileDetails[0].croppedAvatar
          : profile
      );
      setDoctorData(response.data.profileDetails[0].doctorData[0]);
      setSelectedValue(response.data.consultationNames);
      setSelectedType(
        response.data?.profileDetails[0]?.doctorData[0]?.patientsCategory.map(
          (d) => {
            return { eng: d, heb: d == "Children" ? "ילד.ה" : "מבוגר" };
          }
        )
      );
      setSelectId(
        response.data?.profileDetails[0]?.doctorData[0]?.consultationSpeciality
      );
    }
  };
  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue([...selectedValue, selectedItem]);
    setSelectId([...id, selectedItem._id]);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(
      selectedValue.filter((d) => {
        return d != removedItem;
      })
    );
    setSelectId(
      id.filter((d) => {
        return d != removedItem._id;
      })
    );
  };

  const onSelectType = (selectedList, selectedItem) => {
    setSelectedType([...selectedtype, selectedItem]);
    setTypeValue([...typevalue, selectedItem.eng]);
  };

  const onRemoveType = (selectedList, removedItem) => {
    setSelectedType(
      selectedtype.filter((d) => {
        return d != removedItem;
      })
    );
    setTypeValue(
      typevalue.filter((d) => {
        return d != removedItem.eng;
      })
    );
  };

  const consultingFields = async () => {
    const response = await allConsultation();
    setConsultData(response.data.result);
  };

  $("#rsd__select-day option:first-child").text(t("Day"));
  $("#rsd__select-month option:first-child").text(t("Month"));
  $("#rsd__select-year option:first-child").text(t("Year"));

  return (
    <Modal
      open={detailsopen}
      onClose={handleDetailsClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="imagecropmodal"
    >
      <MDBCard className="modal_card">
        <MDBCardBody>
          <div className="container ">
            <div className="row ">
              <div className="col-md-12">
                <div className="contact-form ">
                  <div className="contact-form-content">
                    <div>
                      <div>
                        <h2> {t("Personal Information")}</h2>
                        {role === "doctor" && (
                          <div className="team-profile-img">
                            {!profileImage ? (
                              <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="96"
                                visible={true}
                              />
                            ) : (
                              <img
                                src={profileImage ? profileImage : ""}
                                alt=""
                              />
                            )}
                          </div>
                        )}
                        <div className="col-md-8"></div>
                        <Form>
                          <Form.Group className="form-group ">
                            <Form.Label>{t("First Name")}</Form.Label>
                            <input
                              className="form-control"
                              name="firstname"
                              type="text"
                              readOnly={true}
                              id="firstname"
                              value={
                                profileDetails ? profileDetails.first_name : ""
                              }
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>{t("Last Name")} </Form.Label>
                            <input
                              className="form-control"
                              name="lastname"
                              type="text"
                              readOnly={true}
                              id="lastname"
                              value={
                                profileDetails ? profileDetails.last_name : ""
                              }
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>{t("Email")} </Form.Label>
                            <input
                              className="form-control forceLtrInput"
                              name="email"
                              type="text"
                              readOnly={true}
                              id="email"
                              value={profileDetails ? profileDetails.email : ""}
                            />
                          </Form.Group>

                          <Form.Group className="form-group">
                            <Form.Label>{t("Mobile Number")}</Form.Label>
                            <input
                              className="form-control forceLtrInput phone_number"
                              name="mobile_number"
                              type="text"
                              // value={formValue.phoneNumber}
                              readOnly={true}
                              id="mobile_number"
                              value={profileDetails ? profileDetails.phone : ""}
                            />
                          </Form.Group>

                          <Form.Group className="form-group">
                            <Form.Label>{t("ID Number")} </Form.Label>
                            <input
                              className="form-control textcenter"
                              name="id"
                              type="number"
                              // value={formValue.phoneNumber}
                              readOnly={true}
                              onWheel={(e) => e.target.blur()}
                              id="id"
                              value={profileDetails ? profileDetails.id : ""}
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label>{t("Gender")} </Form.Label>
                            {profileDetails && (
                              <select
                                className="form-control text"
                                name="clientGender"
                                readOnly={true}
                                value={profileDetails?.clientGender}
                              >
                                <option>{t("Select")}</option>
                                <option value="Male">{t("Male")}</option>
                                <option value="Female">{t("Female")}</option>
                              </select>
                            )}
                          </Form.Group>
                          {profileDetails?.personalId ? (
                            <Form.Group className="form-group">
                              <Form.Label>
                                {t("Personal ID Number")}{" "}
                              </Form.Label>
                              <input
                                className="form-control textcenter"
                                name="personalId"
                                type="text"
                                readOnly={true}
                                onWheel={(e) => e.target.blur()}
                                id="personalId"
                                value={
                                  profileDetails?.personalId
                                    ? profileDetails?.personalId
                                    : ""
                                }
                              />
                            </Form.Group>
                          ) : null}
                          {role === "doctor" ? null : (
                            <Form.Group className="form-group">
                              <Form.Label>{t("Marital Status")}</Form.Label>
                              {profileDetails && (
                                <select
                                  className="form-control text"
                                  name="MaritalStatus"
                                  value={profileDetails?.clientMaritalStatus}
                                  readOnly={true}
                                >
                                  <option>{t("Select")}</option>
                                  <option value="Married">
                                    {t("Married")}
                                  </option>
                                  <option value="Single">{t("Single")}</option>
                                  <option value="Divorced">
                                    {t("Divorced")}
                                  </option>
                                </select>
                              )}
                            </Form.Group>
                          )}
                          {role === "doctor" &&
                          doctorData &&
                          doctorData.doctorId ? (
                            <>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Doctor license")}</Form.Label>
                                <input
                                  className="form-control"
                                  name="doctor_license"
                                  type="number"
                                  // value={formValue.phoneNumber}
                                  readOnly={true}
                                  id="doctor_license"
                                  value={
                                    doctorData ? doctorData.licenceNumber : ""
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Title")}</Form.Label>
                                <select
                                  className="form-control"
                                  name="title"
                                  readOnly={true}
                                  value={doctorData ? doctorData.title : ""}
                                >
                                  <option value="Professor">
                                    {t("Professor")}
                                  </option>
                                  <option value="Doctor">{t("Doctor")}</option>
                                </select>
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Specilization")} </Form.Label>
                                <Multiselect
                                  className="form-control"
                                  options={consultData}
                                  selectedValues={selectedValue}
                                  onSelect={onSelect}
                                  onRemove={onRemove}
                                  displayValue={lang === "EN" ? "eng" : "heb"}
                                  placeholder=""
                                  id="consult"
                                />
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Patient Type")} </Form.Label>
                                <Multiselect
                                  className="form-control"
                                  options={patienttype}
                                  selectedValues={selectedtype}
                                  onSelect={onSelectType}
                                  onRemove={onRemoveType}
                                  displayValue={lang === "EN" ? "eng" : "heb"}
                                  placeholder=""
                                />
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>
                                  {t("Practice")} / {t("Experience")}
                                </Form.Label>
                                <input
                                  className="form-control"
                                  name="experience"
                                  type="text"
                                  // value={formValue.phoneNumber}
                                  readOnly={true}
                                  id="experience"
                                  value={
                                    doctorData ? doctorData.experience : ""
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Bio")}</Form.Label>
                                <textarea
                                  className="form-control"
                                  maxlength="300"
                                  name="bio"
                                  type="text"
                                  // value={formValue.phoneNumber}
                                  readOnly={true}
                                  id="bio"
                                  value={doctorData ? doctorData.bio : ""}
                                />
                              </Form.Group>
                            </>
                          ) : null}
                        </Form>
                        <h2> {t("Address")}</h2>
                        <Form className="form" id="b">
                          <div className="check" id="check">
                            <input
                              type="checkbox"
                              defaultChecked={show}
                              onClick={() => setShow(!show)}
                            />
                            <span> {t("I do not live in Israel")}</span>
                          </div>
                          {show ? (
                            <>
                              <Form.Group className="form-group">
                                <Form.Label>{t("Country")} </Form.Label>
                                <input
                                  className="form-control"
                                  name="country"
                                  type="text"
                                  id="country"
                                  readOnly={true}
                                  value={
                                    profileDetails &&
                                    profileDetails.clientAddress
                                      ? profileDetails?.clientAddress?.country
                                      : ""
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="form-group">
                                <Form.Label>
                                  {t(
                                    "Local phone number (including area code and plus sign +)"
                                  )}
                                </Form.Label>
                                <input
                                  className="form-control"
                                  name="localphoneNumber"
                                  type="tel"
                                  // value={formValue.phoneNumber}
                                  readOnly={true}
                                  id="localphoneNumber"
                                  value={
                                    profileDetails &&
                                    profileDetails.clientAddress
                                      ? profileDetails.clientAddress.localPhone
                                      : ""
                                  }
                                />
                              </Form.Group>
                            </>
                          ) : (
                            ""
                          )}

                          <Form.Group className="phone-form">
                            <Form.Label>{t("Street")}</Form.Label>
                            <input
                              className="form-control"
                              name="street"
                              type="text"
                              // value={formValue.phoneNumber}
                              readOnly={true}
                              id="street"
                              value={
                                profileDetails && profileDetails.clientAddress
                                  ? profileDetails.clientAddress.street
                                  : ""
                              }
                            />
                          </Form.Group>
                          <Form.Group className="number-form">
                            <Form.Label>{t("House No")}</Form.Label>
                            <input
                              className="form-control"
                              name="houseNo"
                              type="text"
                              // value={formValue.phoneNumber}
                              readOnly={true}
                              id="houseNo"
                              value={
                                profileDetails && profileDetails.clientAddress
                                  ? profileDetails.clientAddress.house
                                  : ""
                              }
                            />
                          </Form.Group>

                          <Form.Group className="phone-form">
                            <Form.Label>{t("City")}</Form.Label>
                            <input
                              className="form-control"
                              name="city"
                              type="text"
                              // value={formValue.phoneNumber}
                              readOnly={true}
                              id="city"
                              value={
                                profileDetails && profileDetails.clientAddress
                                  ? profileDetails.clientAddress.city
                                  : ""
                              }
                            />
                          </Form.Group>
                          <Form.Group className="number-form">
                            <Form.Label>{t("Postal Code")}</Form.Label>
                            <input
                              className="form-control"
                              name="postalcode"
                              type="tel"
                              // value={formValue.phoneNumber}
                              readOnly={true}
                              id="postalcode"
                              dir="auto"
                              value={
                                profileDetails && profileDetails.clientAddress
                                  ? profileDetails.clientAddress.pincode
                                  : ""
                              }
                            />
                          </Form.Group>
                          {role === "client" && (
                            <>
                              <div className="check">
                                <input
                                  type="checkbox"
                                  checked={consent}
                                  onClick={() => setConsent(!consent)}
                                />
                                <span>
                                  {" "}
                                  {t("I agree to the insurance consent")}
                                </span>
                              </div>
                              <div className="check">
                                <input
                                  type="checkbox"
                                  checked={terms}
                                  onClick={() => setTerms(!terms)}
                                />
                                <span>
                                  {" "}
                                  {t("I agree to the site term's of use")}
                                </span>
                              </div>
                            </>
                          )}
                        </Form>
                        <Button type="submit" onClick={handleDetailsClose}>
                          {" "}
                          {t("Close")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </Modal>
  );
};

export default ProfileDetails;
