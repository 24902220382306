import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./common.css";

const AlertModal = ({
  showAlertModal,
  setShowAlertModal,
  handleCloseAlertModal,
}) => {
  const { t } = useTranslation();
  console.log("showAlertModal", showAlertModal);
  return (
    <Modal
      show={false} // Replace this false value with showAlertModal useState for open this modal
      onHide={() => {
        setShowAlertModal(false);
      }}
      className="modal-alert"
      size="xl"
      backdrop="static"
    >
      <Modal.Body>
        <div className="modal-alert-content">
          <h6>
            {t(
              "In light of the security situation, the availability of doctors may be lower"
            )}
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="border-btn" onClick={handleCloseAlertModal}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
