import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Dashborad/dashboard.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Network } from "../../../config/axios";
import { useEffect } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";

const MeetingLog = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [meetinglogData, setMeetingLogData] = useState([]);
  useEffect(() => {
    if (props.meetingId) {
      meetingLogDetails();
    }
  }, [props.meetinglogModal]);
  const meetingLogDetails = async () => {
    try {
      const response = await Network().get(
        "/admin/getMeetingLogs/" + props.meetingId,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 202].includes(response.status)) {
        setMeetingLogData(response.data.meetingLogs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        open={props.meetinglogModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1>Meeting log</h1>
            <ul>
              {meetinglogData.length != 0 ? (
                meetinglogData.map((data, i) => {
                  return (
                    <p className="meetingLogContent" key={i}>
                      {data.action}{" "}
                      {`(${moment(data.updatedAt).format(
                        "DD.MM.YYYY"
                      )} ${moment(data.updatedAt).format("h:mm:ss a")})`}
                    </p>
                  );
                })
              ) : (
                <p className="meetingLogContent">
                  {t("This meeting has no log")}
                </p>
              )}
            </ul>
            <Button onClick={() => props.handleClose()}>{t("Close")}</Button>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default MeetingLog;
