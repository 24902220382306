import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { refreshTokenGenerate } from "../config/service";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Timer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [timer, setTimer] = useState("00:00");
  const [flag, setFlag] = useState(false);
  const [timerflag, settimerFlag] = useState(true);
  let token;

  useEffect(() => {
    setInterval(() => {
      startTimer();
    }, 10000);
  });
  const getTimeRemaining = (expirationTime) => {
    const total = Date.parse(expirationTime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = () => {
    token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const decoded = jwt_decode(token);
    const expirationTime = new Date(decoded.exp * 1000);
    let { total, hours, minutes, seconds } = getTimeRemaining(expirationTime);

    if (total >= 0) {
      setTimer("0" + minutes + ":" + (seconds > 9 ? seconds : "0" + seconds));

      if (hours == 0 && minutes <= 9) {
        setFlag(true);
      }
      for (var i = 10; i >= 1; i--) {
        if (hours == 0 && minutes == i && seconds == 0) {
          settimerFlag(true);
        }
      }
    } else {
      settimerFlag(false);
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");

      navigate("/login");
    }
  };
  const refreshToken = async () => {
    const refToken = localStorage.getItem("refreshToken");
    const response = await refreshTokenGenerate({ refToken });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    toast.success(t(response.data.msg));
    setFlag(false);
    setTimer("00:00");
    settimerFlag(false);
  };
  return (
    <div>
      {timerflag && (
        <Modal
          open={flag}
          onClose={() => setFlag(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalContent"
        >
          <MDBCard className="modal_card">
            <MDBCardBody>
              <h2>
                {t("You are about to be logged out of your account withing")}{" "}
                <strong>{timer} </strong> {t("mins")}
              </h2>
              <h3>{t("Want continue using the system ?")}</h3>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <MDBCardText>
                  <Button
                    size="lg"
                    className="buttton_content cancel-b"
                    onClick={() => (settimerFlag(false), setFlag(false))}
                  >
                    {t("No")}
                  </Button>
                </MDBCardText>
                <MDBCardText>
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttton_content cancel-b"
                    onClick={refreshToken}
                  >
                    {t("Yes")}
                  </Button>
                </MDBCardText>
              </div>
            </MDBCardBody>
          </MDBCard>
        </Modal>
      )}
    </div>
  );
};
export default Timer;
