import React from "react";
import { Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const IdfApproveDocModal = (props) => {
  const { t } = useTranslation();

  const handleConfirmation = (e) => {
    e.preventDefault();
    props.setIdfApprove(false);
    props.DetailsUpload(
      e,
      props?.singleMeetObj?._id,
      props?.singleMeetObj?.ClientId
    );
  };

  return (
    <div>
      <Modal
        open={props.idfApprove}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <Form onSubmit={handleConfirmation}>
              <div className="form-check-grid">
                <div className="form-check idf-note">
                  {props.currentAppointmentIndex === 0 ? (
                    <>
                      <label className="form-check-label ">
                        {t("It is important !")}{" "}
                      </label>
                      <label className="form-check-label">
                        {t(
                          "Relevant photos/documents must be uploaded for the doctor to view"
                        )}{" "}
                      </label>
                      <label className="form-check-label">
                        {t(
                          "(photos of the lesion/wound/rash, results of tests done, etc.)."
                        )}{" "}
                      </label>
                      <label className="form-check-label">
                        {t(
                          "Without documents related to the reason for referral, it will not be possible to provide optimal advice!!"
                        )}{" "}
                      </label>
                      <label className="form-check-label">
                        {t(
                          "Pay attention to the doctor. There is no access to your medical file in the IDF"
                        )}{" "}
                      </label>
                    </>
                  ) : (
                    <>
                      <label className="form-check-label ">
                        {t(
                          "Please notice, that you have one more meeting, for which you need to upload documents as well"
                        )}{" "}
                      </label>
                    </>
                  )}
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <MDBCardText>
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttton_content cancel-b"
                    type="submit"
                  >
                    {t("Approve")}
                  </Button>
                </MDBCardText>
              </div>
            </Form>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default IdfApproveDocModal;
