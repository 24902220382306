import { RotatingLines } from "react-loader-spinner";
import "./common.css";

const ButtonSpinner = ({ visible, strokeColor, strokeWidth, width }) => {
  return (
    <div className="button-spinner">
      <RotatingLines
        visible={visible}
        strokeColor={strokeColor}
        strokeWidth={strokeWidth}
        animationDuration="0.75"
        width={width}
      />
    </div>
  );
};

export default ButtonSpinner;
