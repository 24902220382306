import React from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { clientPath } from "../../config/key";
import { handleCopyToClipboard } from "../../utils/function";

const DyteMeetingLink = (props) => {
  const { updateData } = props;
  const { t } = useTranslation();
  const role = localStorage.getItem("role");

  const doctorDyteLink = `${clientPath}/dyte-video-call/${updateData?._id}`;
  const clientDyteLink = `${clientPath}/dyte-video-call/${updateData?._id}`;
  const guestDyteLink = `${clientPath}/join/${updateData?._id}`;
  return (
    <Form.Group className="mb-3">
      <h5 className="meeting-link-text">
        {role === "admin" ? t("Dyte meeting Links") : t("Guest join link")}
      </h5>
      <div className="meeting-link-grid">
        {updateData?.dyteMeetingResponse?.doctorParticipant &&
          role === "admin" && (
            <div>
              <a href={doctorDyteLink}>{t("Doctor")} </a>{" "}
              <Button
                onClick={() => {
                  handleCopyToClipboard(doctorDyteLink, "doctor", t);
                }}
                className="copy-btn"
              >
                {t("Copy")} <i class="las la-copy"></i>
              </Button>
            </div>
          )}
        {updateData?.dyteMeetingResponse?.clientParticipant &&
          role === "admin" && (
            <>
              |&nbsp;
              <div>
                <a href={clientDyteLink}> {t("Client")} </a>{" "}
                <Button
                  onClick={() => {
                    handleCopyToClipboard(clientDyteLink, "client", t);
                  }}
                  className="copy-btn"
                >
                  {t("Copy")} <i class="las la-copy"></i>
                </Button>{" "}
              </div>
            </>
          )}
        {updateData?.dyteMeetingResponse?.additionalParticipant && (
          <>
            {role === "admin" && <span>|&nbsp;</span>}
            <div>
              {role === "admin" && <a href={guestDyteLink}>{t("Guest")}</a>}
              <Button
                onClick={() => handleCopyToClipboard(guestDyteLink, "guest", t)}
                className="copy-btn"
              >
                {t("Copy")} <i className="las la-copy"></i>
              </Button>
            </div>
          </>
        )}
      </div>
    </Form.Group>
  );
};
export default DyteMeetingLink;
