import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../layout/layout";
import "./CreatePrescription.css";
import RxSummaryComponent from "./RxSummary";

const CreatePrescription = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="create-prescription-page">
        <div className="container">
          <div className="create-prescription-title">
            <h2>{t("Create a private prescription")}</h2>
          </div>
          <div className="summary-section">
            <RxSummaryComponent />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CreatePrescription;
