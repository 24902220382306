import React, { useEffect, useState, useCallback } from "react";

import { Button, Form } from "react-bootstrap";
import "../../contact/contact.css";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import $ from "jquery";
import { SelectDatepicker } from "react-select-datepicker";
import {
  adminAllConsultation,
  adminAllSubscriptions,
  adminCreateUser,
  adminGetPartnesList,
} from "../../../config/service";
import ButtonSpinner from "../../common/buttonSpinner";

const CreateUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formValue, setformValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    id_number: "",
    mobile: "",
    title: "",
    Doctor_Id: "",
    UTM: "",
    collective: "",
    clientGender: "",
    partner: "",
  });
  const [toggle, setToggle] = useState(false);
  const [consultData, setConsultData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [id, setSelectId] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [fnameError, setfnameError] = useState("");
  const [lnameError, setlnameError] = useState("");
  const [idError, setIdError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [DoctorIdError, setDoctorError] = useState("");
  const [ConsultError, setConsultError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedtype, setSelectedType] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [typevalue, setTypeValue] = useState([]);
  const [subscriptionsValue, setSubscriptionsValue] = useState([]);
  const [value, setValue] = useState();
  const [genderError, setgenderError] = useState("");
  const [dobError, setDobError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subscriptionsError, setSubscriptionsError] = useState("");
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [loading, setLoading] = useState(false);

  const patienttype = [
    { eng: "Children", heb: "ילד.ה" },
    { eng: "Adults", heb: "מבוגר" },
  ];
  let lang = localStorage.getItem("lang");
  useEffect(() => {
    consultingFields();
    getSubscriptionsList();
    getPartnersList();
  }, []);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue([...selectedValue, selectedItem]);
    setSelectId([...id, selectedItem._id]);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(
      selectedValue.filter((d) => {
        return d != removedItem;
      })
    );
    setSelectId(
      id.filter((d) => {
        return d != removedItem._id;
      })
    );
  };
  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  const onDateChange = useCallback((date) => {
    setValue(date);
  }, []);
  const consultingFields = async () => {
    try {
      const response = await adminAllConsultation();
      setConsultData(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const getSubscriptionsList = async () => {
    try {
      const response = await adminAllSubscriptions();
      setSubscriptionsList(response.data.subscriptions);
    } catch (error) {
      console.log(error);
    }
  };

  const getPartnersList = async () => {
    try {
      const response = await adminGetPartnesList();
      setPartnersList(response.data.partners);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // store the states in the form data
    try {
      if (validate()) {
        setLoading(true);
        let doctordata = {
          email: formValue.email,
          phone: formValue.mobile,
          id: formValue.id_number,
          first_name: formValue.first_name,
          last_name: formValue.last_name,
          consultationSpeciality: id,
          doctorRegistrantNumber: formValue.Doctor_Id,
          title: "Doctor",
          patientsCategory: typevalue,
          subscriptions: subscriptionsValue,
          licenceNumber: formValue.Doctor_Id,
          role: "doctor",
        };
        if (doctordata) {
          doctordata.partnerId = (formValue.partner) ? formValue.partner : [];
        }
        let clientdata = {
          email: formValue.email,
          phone: formValue.mobile,
          id: formValue.id_number,
          first_name: formValue.first_name,
          last_name: formValue.last_name,
          clientGender: formValue.clientGender,
          clientDOB: value,
          UTM: formValue.UTM,
          collective: formValue.collective,
          role: "client",
        };
        // make axios post request
        const response = await adminCreateUser(
          toggle ? doctordata : clientdata
        );
        toast.success(t(response.data.msg));
        setLoading(false);
        navigate("/AdminDashboard");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validate = () => {
    let isValid = true;

    if (!formValue.first_name) {
      isValid = false;
      setfnameError(t("Please enter first name"));
    } else {
      setfnameError("");
    }
    if (!formValue.last_name) {
      isValid = false;
      setlnameError(t("Please enter last name"));
    } else {
      setlnameError("");
    }

    if (!formValue.id_number) {
      isValid = false;
      setIdError(t("Please enter id number"));
    } else {
      setIdError("");
    }
    if (!formValue.email) {
      isValid = false;
      setEmailError(t("Please enter email address"));
    }
    if (!toggle) {
      if (!formValue.clientGender) {
        isValid = false;
        setgenderError(t("Please select gender"));
      } else {
        setgenderError("");
      }
      if (!value) {
        isValid = false;
        setDobError(t("Please choose date of birth"));
      } else {
        setDobError("");
      }
    }

    if (typeof formValue.email !== "undefined") {
      const pattern = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!pattern.test(formValue.email)) {
        isValid = false;
        setEmailError(t("Please enter valid email"));
      }
    }
    if (!formValue.mobile) {
      isValid = false;

      setmobileError(t("Please enter mobile number"));
    }
    if (formValue.mobile) {
      if (formValue.mobile.length > 20) {
        isValid = false;

        setmobileError(t("Mobile number max length 20"));
      }
    }
    if (toggle) {
      if (selectedValue.length == 0) {
        isValid = false;
        setConsultError(t("Please select"));
      } else {
        setConsultError(" ");
      }

      if (!formValue.Doctor_Id) {
        isValid = false;
        setDoctorError(t("Please enter doctor Id"));
      } else {
        setDoctorError(" ");
      }
      if (typevalue.length == 0) {
        isValid = false;
        setCategoryError(t("Please select patient category"));
      } else {
        setCategoryError(" ");
      }
      if (subscriptionsValue.length === 0) {
        isValid = false;
        setSubscriptionsError(t("Please select the subscription category"));
      } else {
        setSubscriptionsError(" ");
      }
    }

    return isValid;
  };

  const onSelectType = (selectedList, selectedItem) => {
    setSelectedType([...selectedtype, selectedItem]);
    setTypeValue([...typevalue, selectedItem.eng]);
  };

  const onRemoveType = (selectedList, removedItem) => {
    setSelectedType(
      selectedtype.filter((d) => {
        return d != removedItem;
      })
    );
    setTypeValue(
      typevalue.filter((d) => {
        return d != removedItem.eng;
      })
    );
  };

  const onSelectSubscriptions = (selectedList, selectedItem) => {
    setSelectedSubscriptions([...selectedSubscriptions, selectedItem]);
    setSubscriptionsValue([...subscriptionsValue, selectedItem]);
  };

  const onRemoveSubscriptions = (selectedList, removedItem) => {
    setSelectedSubscriptions(
      selectedSubscriptions.filter((d) => {
        return d != removedItem;
      })
    );
    setSubscriptionsValue(
      subscriptionsValue.filter((d) => {
        return d != removedItem;
      })
    );
  };

  $("#rsd__select-day option:first-child").text(t("Day"));
  $("#rsd__select-month option:first-child").text(t("Month"));
  $("#rsd__select-year option:first-child").text(t("Year"));

  return (
    <div className="create-user-page">
      <div className="container">
        <div className="row content">
          <div className="col-md-12">
            <Modal
              open={modalShow}
              onClose={() => setModalShow(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="modalContent"
            >
              <MDBCard className="modal_card">
                <MDBCardBody>
                  <h3>
                    {t(
                      "Your application is now going to be inspected by the team"
                    )}
                  </h3>
                  <Button
                    className="iframeclose"
                    onClick={() => (setModalShow(false), navigate("/login"))}
                  >
                    {t("Close")}
                  </Button>
                </MDBCardBody>
              </MDBCard>
            </Modal>
            <div className="contact-form ">
              <div className="contact-form-content">
                <div>
                  <div>
                    <Form className="form">
                      <>
                        <Form.Group className="form-group">
                          <div className="loginToggle">
                            <span
                              onClick={() => setToggle(true)}
                              className={`${toggle ? "active-tab" : ""}`}
                            >
                              {t("Create Doctor")}
                            </span>

                            <span
                              onClick={() => setToggle(false)}
                              className={`${!toggle ? "active-tab" : ""}`}
                            >
                              {t("Create Client")}
                            </span>
                          </div>
                        </Form.Group>
                        {!toggle ? (
                          <>
                            <Form.Group className="firstname-form">
                              <Form.Label>{t("First Name")} *</Form.Label>
                              <Form.Control
                                name="first_name"
                                type="text"
                                onChange={handleChange}
                                id="first_name"
                              />
                              <span className="errorsmg">
                                {t(`${fnameError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="lastname-form">
                              <Form.Label>{t("Last Name")} *</Form.Label>
                              <Form.Control
                                name="last_name"
                                type="text"
                                onChange={handleChange}
                                id="last_name"
                              />
                              <span className="errorsmg">
                                {t(`${lnameError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="firstname-form">
                              <Form.Label>{t("Email")} *</Form.Label>
                              <Form.Control
                                name="email"
                                type="text"
                                onChange={handleChange}
                                id="email"
                              />
                              <span className="errorsmg">
                                {t(`${emailError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="lastname-form">
                              <Form.Label>{t("ID Number")} *</Form.Label>
                              <Form.Control
                                name="id_number"
                                type="text"
                                onChange={handleChange}
                                id="id_number"
                              />
                              <span className="errorsmg">
                                {t(`${idError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className=" form-group">
                              <Form.Label> {t("Date of Birth")} *</Form.Label>
                              <SelectDatepicker
                                order="day/month/year"
                                selectedDate={value}
                                onDateChange={onDateChange}
                                className="contain"
                                monthPlaceholder={"Month"}
                              />
                              <span className="errorsmg">
                                {t(`${dobError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="firstname-form">
                              <Form.Label> {t("Gender")} *</Form.Label>
                              <select
                                className="form-control selectContainer"
                                id="a"
                                type="text"
                                placeholder=""
                                name="clientGender"
                                value={formValue.clientGender}
                                onChange={handleChange}
                              >
                                <option value="">{t("Select")}</option>
                                <option value={"Male"}>{t("Male")}</option>
                                <option value={"Female"}>{t("Female")}</option>
                              </select>
                              <span className="errorsmg">
                                {t(`${genderError}`)}
                              </span>
                            </Form.Group>

                            <Form.Group className="lastname-form">
                              <Form.Label>{t("Phone Number")}*</Form.Label>
                              <Form.Control
                                name="mobile"
                                type="text"
                                onChange={handleChange}
                                id="mobile"
                                className="phone_number"
                              />
                              <span className="errorsmg">
                                {t(`${mobileError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="lastname-form">
                              <Form.Label>{t("UTM")}</Form.Label>
                              <Form.Control
                                name="UTM"
                                type="text"
                                onChange={handleChange}
                                id="UTM"
                              />
                            </Form.Group>
                            <Form.Group className="lastname-form">
                              <Form.Label>{t("Collective")}</Form.Label>
                              <Form.Control
                                name="collective"
                                type="text"
                                onChange={handleChange}
                                id="collective"
                              />
                            </Form.Group>
                          </>
                        ) : (
                          <>
                            <Form.Group className="firstname-form">
                              <Form.Label>{t("First Name")} *</Form.Label>
                              <Form.Control
                                name="first_name"
                                type="text"
                                onChange={handleChange}
                                id="first_name"
                              />
                              <span className="errorsmg">
                                {t(`${fnameError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="lastname-form">
                              <Form.Label>{t("Last Name")} *</Form.Label>
                              <Form.Control
                                name="last_name"
                                type="text"
                                onChange={handleChange}
                                id="last_name"
                              />
                              <span className="errorsmg">
                                {t(`${lnameError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="firstname-form">
                              <Form.Label>{t("Email")} *</Form.Label>
                              <Form.Control
                                name="email"
                                type="text"
                                onChange={handleChange}
                                id="email"
                              />
                              <span className="errorsmg">
                                {t(`${emailError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="lastname-form">
                              <Form.Label>{t("ID Number")} *</Form.Label>
                              <Form.Control
                                name="id_number"
                                type="text"
                                onChange={handleChange}
                                id="id_number"
                              />
                              <span className="errorsmg">
                                {t(`${idError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="firstname-form">
                              <Form.Label>{t("Specialisation")} *</Form.Label>
                              <Multiselect
                                className="form-control speciality selectData"
                                options={consultData}
                                selectedValues={selectedValue}
                                onSelect={onSelect}
                                onRemove={onRemove}
                                displayValue={lang === "EN" ? "eng" : "heb"}
                                placeholder=""
                                id="consult"
                              />
                              <span className="errorsmg">
                                {t(`${ConsultError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="firstname-form">
                              <Form.Label>{t("Patient Type")} *</Form.Label>
                              <Multiselect
                                className="form-control speciality selectData"
                                options={patienttype}
                                selectedValues={selectedtype}
                                onSelect={onSelectType}
                                onRemove={onRemoveType}
                                displayValue={lang === "EN" ? "eng" : "heb"}
                                placeholder=""
                              />
                              <span className="errorsmg">
                                {t(`${categoryError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="firstname-form">
                              <Form.Label>{t("Subscriptions")} *</Form.Label>
                              <Multiselect
                                className="form-control speciality selectData"
                                options={subscriptionsList}
                                selectedValues={selectedSubscriptions}
                                onSelect={onSelectSubscriptions}
                                onRemove={onRemoveSubscriptions}
                                placeholder=""
                                isObject={false}
                              />
                              <span className="errorsmg">
                                {t(`${subscriptionsError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="lastname-form">
                              <Form.Label>{t("Phone Number")}*</Form.Label>
                              <Form.Control
                                name="mobile"
                                type="text"
                                onChange={handleChange}
                                id="mobile"
                                className="phone_number"
                              />
                              <span className="errorsmg">
                                {t(`${mobileError}`)}
                              </span>
                            </Form.Group>

                            <Form.Group className="firstname-form">
                              <Form.Label>
                                {t("Doctor License Number")} *
                              </Form.Label>
                              <Form.Control
                                name="Doctor_Id"
                                type="number"
                                onChange={handleChange}
                                id="Doctor_Id"
                              />
                              <span className="errorsmg">
                                {t(`${DoctorIdError}`)}
                              </span>
                            </Form.Group>
                            <Form.Group className="firstname-form">
                              <Form.Label> {t("Partner")} </Form.Label>
                              <select
                                className="form-control selectContainer"
                                id="partner"
                                name="partner"
                                value={formValue.partner}
                                onChange={handleChange}
                              >
                                <option value="">{t("Select")}</option>
                                {partnersList.map((el) => (
                                  <option value={el?._id}>
                                    {el?.partnerName}
                                  </option>
                                ))}
                              </select>
                            </Form.Group>
                          </>
                        )}
                      </>
                    </Form>

                    <Button
                      size="lg"
                      className="submit_button"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? (
                        <ButtonSpinner
                          visible={loading}
                          strokeColor="white"
                          strokeWidth="4"
                          width="36"
                        />
                      ) : (
                        t("Continue")
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateUser;
