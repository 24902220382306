import axios from "axios";
import { serverPath } from "./key";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

export const Network = () => {
  return axios.create({
    baseURL: serverPath,
  });
};

async function refreshToken(
  method,
  endpoint,
  data = {},
  headers,
  publicRequest = false
) {
  return new Promise((resolve, reject) => {
    const refToken = localStorage.getItem("refreshToken");
    axios({
      method: "get",
      url: `${serverPath}/users/refresh`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refToken}`,
        "Access-Control-Allow-Origin": "*",
        crossorigin: true,
      },
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        resolve(userInstance(method, endpoint, data, headers, publicRequest));
      })
      .catch(function (error) {
        console.log(error);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        localStorage.removeItem("role");
        window.location = "/";
        location.reload();
        reject();
      });
  });
}

export const userInstance = async (
  method,
  endpoint,
  data = {},
  headers,
  publicRequest = false
) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("token");
    if (!token || token === "undefined") {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userName");
      localStorage.removeItem("userId");
      localStorage.removeItem("role");
    }
    if (!publicRequest) {
      let decodedToken;
      try {
        decodedToken = jwt_decode(token);
      } catch (e) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        localStorage.removeItem("role");
      }
      const dateNow = new Date();
      if (decodedToken.exp < dateNow.getTime() / 1000) {
        resolve(refreshToken(method, endpoint, data, headers, publicRequest));
      } else {
        const token = localStorage.getItem("token");
        if (method === "GET") {
          axios({
            method: "get",
            url: `${serverPath}/${endpoint}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: (token && `Bearer ${token}`) || null,
              "Access-Control-Allow-Origin": "*",
              crossorigin: true,
            },
          })
            .then((res) => {
              if (res.status >= 200 && res.status < 400) {
                resolve(res);
              } else {
                throw new Error("Http response code is: " + res.status);
              }
            })
            .catch(function (error) {
              toast.error(error.response.data.message, {
                toastId: "error",
              });
              console.log(error.toJSON());
              return Promise.reject(error);
            });
        } else {
          axios({
            method: "post",
            url: `${serverPath}/${endpoint}`,
            data,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: (token && `Bearer ${token} `) || null,
            },
          })
            .then((res) => {
              if (res.status >= 200 && res.status < 400) {
                resolve(res);
              } else {
                throw new Error("Http response code is: " + res.status);
              }
            })
            .catch(function (error) {
              toast.error(error.response.data, {
                toastId: "error",
              });
              console.log(error.toJSON());
              return reject(error);
            });
        }
      }
    } else {
      if (method === "GET") {
        axios({
          method: "get",
          url: `${serverPath}/${endpoint}`,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            crossorigin: true,
          },
        })
          .then((res) => {
            if (res.status >= 200 && res.status < 400) {
              resolve(res);
            } else {
              throw new Error("Http response code is: " + res.status);
            }
          })
          .catch(function (error) {
            toast.error(error.response.data.message);
            console.log(error.toJSON());
            return Promise.reject(error);
          });
      } else {
        axios({
          method: "post",
          url: `${serverPath}/${endpoint}`,
          data,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status >= 200 && res.status < 400) {
              resolve(res);
            } else {
              throw new Error("Http response code is: " + res.status);
            }
          })
          .catch(function (error) {
            const errorMessage =
              error?.response?.data?.message || error?.message;
            if (errorMessage) {
              toast.error(errorMessage, {
                toastId: "error",
              });
            }
            console.log(error.toJSON());
            return reject(error);
          });
      }
    }
  });
};
