import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#000",
    fontWeight: "500",
    fontSize: "18px",
    padding: "10px 20px",
    lineHeight: "16px",
    cursor: "pointer",
    borderRadius: "0px",
    ":hover": {
      background: "#1E0F3C",
      borderRadius: "5px",
      color: "#E9E7EC",
    },
  }),
  control: () => ({
    background: "transparent",
    border: "2px solid #9891A5",
    borderRadius: "4px",
    color: "#000",
    display: "flex",
    alignItem: "center",
    height: "48px",
    margin: "0px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "600",
    ":hover": {
      background: "transparent",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "500",
    fontSize: "18px",
    color: "#000",
  }),
  input: (provided) => ({
    ...provided,
    color: "#000",
    fontWeight: "500",
    fontSize: "16px !important",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 20px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "20px",
    color: "#858585c7",
  }),
};

const AddTemplateModal = ({
  showTemplateModal,
  handleTemplateChange,
  addTemplate,
  handleTemplateSubmit,
  handleMultiChange,
  templateList,
  handleEditTemplate,
  summaryFieldArray,
  isEditTemp,
  submitEditTemplate,
  setDeleteTempModal,
  setTemplateId,
  deleteTempModal,
  handleCloseTempModal,
  handleAddTemplate,
  templateId,
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const handleDeleteData = (chipToDelete) => {
    setDeleteTempModal(true);
    setTemplateId(chipToDelete._id);
  };

  const handleDelete = (chipToDelete) => () => {
    handleDeleteData(chipToDelete);
  };

  return (
    <Modal
      show={showTemplateModal}
      onHide={() => handleCloseTempModal()}
      className={`prescription-alert add-template-modal ${
        deleteTempModal ? "delete-show" : ""
      }`}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <h6>
          {t("Templates settings")}{" "}
          <span>
            {t(
              "To retrieve the template press # in the relevant field, the list of templates you have defined for that field will appear"
            )}{" "}
          </span>{" "}
        </h6>
      </Modal.Header>
      <Modal.Body>
        <div className="add-template-content">
          {templateList && templateList.length > 0 ? (
            <div className="template-list-title">
              <p>{t("Template List")}</p>
            </div>
          ) : null}
          <div
            className={`template-list-grid ${lang === "HE" ? "he-list" : ""}`}
          >
            {templateList &&
              templateList.map((data) => {
                const isActive = data._id === templateId;

                return (
                  <Chip
                    key={data._id}
                    label={data.templateName}
                    onDelete={handleDelete(data)}
                    deleteIcon={<HighlightOffOutlinedIcon />}
                    onClick={() => handleEditTemplate(data)}
                    className={isActive ? "active-chip" : ""}
                  />
                );
              })}
            {isEditTemp ? (
              <Chip
                label={"Add"}
                icon={<AddCircleOutlineOutlinedIcon />}
                variant="outlined"
                onClick={() => handleAddTemplate()}
              />
            ) : null}
          </div>
          <div className="template-form">
            <Form>
              <Form.Group className="form-group">
                <Form.Label>{t("Template name")} </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Template name")}
                  name="templateName"
                  onChange={(e) => handleTemplateChange(e)}
                  value={addTemplate.templateName}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>{t("Template text")} </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={t("Template text")}
                  name="templateText"
                  onChange={(e) => handleTemplateChange(e)}
                  value={addTemplate.templateText}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>{t("Template field")} </Form.Label>
                <Select
                  classNamePrefix="template"
                  isMulti
                  isRtl={lang === "HE"}
                  noOptionsMessage={() => t("No option available")}
                  styles={customStyles}
                  isSearchable={true}
                  options={summaryFieldArray}
                  placeholder="Select"
                  name="templateField"
                  autocomplete={true}
                  onChange={handleMultiChange}
                  value={addTemplate.templateField}
                />
              </Form.Group>
              <Button
                className="border-btn"
                onClick={() =>
                  !isEditTemp ? handleTemplateSubmit() : submitEditTemplate()
                }
              >
                {t("Save")}
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddTemplateModal;
