import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  getUserProfile,
  adminUpdateStatus,
  adminGetUsers,
  adminNoticiation,
} from "../../../config/service";
import UpdateDetails from "./UpdateDetails";
import { Button } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Report from "./Report";

const UserTable = () => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState([]);
  const [updateId, setUpdateId] = useState();
  const [updateModal, setUpdateModal] = useState(false);
  const handleClose = () => (setUpdateModal(false), getUsers());
  const [userdata, setuserdata] = useState();
  const [selectedValue, setSelectedValue] = useState([]);
  const [id, setSelectId] = useState([]);
  const [doctorId, setDoctorId] = useState();
  const [value, setDOB] = useState(new Date());
  const [selectedtype, setSelectedType] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const handleReportModalClose = () => setReportModal(false);

  const [calenderInvite, setCalenderInvite] = useState(true);
  const [columnDefs] = useState([
    {
      field: "Physician/Client Name",
      cellRenderer: "agGroupCellRenderer",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: "auto",
    },
    { field: "Email" },
    { field: "Phone Number" },
    { field: "Id", width: 120 },
    {
      headerName: "Doctor/Client Id",
      field: "Doctor_ClientId",
    },
    {
      field: "Role",
      width: 120,
    },
    {
      headerName: "Doctor Expertise",
      field: "Doctor_expertise",
      // cellRendererFramework: (params) => (
      //   <div>
      //     {params.data?.Doctor_expertise.length != 0 &&
      //       params.data?.Doctor_expertise.map((d, i) => {
      //         return (
      //           <>
      //             <span key={i}>{d.eng}</span>
      //             <span>{i > 0 ? "," : null}</span>
      //           </>
      //         );
      //       })}
      //   </div>
      // ),
    },
    {
      field: "Action",
      width: 120,
      cellRendererFramework: (params) => (
        <div>
          <Button
            onClick={() =>
              Update(
                params.data.Doctor_ClientId,
                params.data.data.croppedAvatar,
                params.data.data
              )
            }
          >
            Update
          </Button>
        </div>
      ),
    },
    {
      field: "Active/Deactive",
      width: 140,
      cellRendererFramework: (params) => (
        <div>
          {params.data.Role == "doctor" ? (
            <select
              onChange={(e) =>
                handleChangeactive(e, params.data.Doctor_ClientId)
              }
              defaultValue={params.data.isActive ? "Active" : "Pending"}
              className="form-control status-text"
            >
              <option value={"Pending"}>Pending</option>
              <option value={"Active"}>Active</option>
            </select>
          ) : null}
        </div>
      ),
    },
    {
      field: "Notification",
      width: 110,
      cellRendererFramework: (params) => (
        <div>
          {params.data.Role == "doctor" ? (
            <input
              type="checkbox"
              defaultChecked={
                params.data.data?.doctorPersonalData?.isNotification
              }
              onChange={(e) =>
                handleNotifcation(params.data.Doctor_ClientId, e.target.checked)
              }
              className="notificationcheck"
            />
          ) : null}
        </div>
      ),
    },
  ]);
  const handleNotifcation = async (id, flag) => {
    console.log("checked", flag);
    const data = {
      isNotification: flag,
      doctorUserId: id,
    };
    const response = await adminNoticiation(data);
    toast.success(t(response.data.message));
    getUsers();
  };
  const userDetails = async (id) => {
    const data = {
      userId: id,
    };
    try {
      const response = await getUserProfile(data);
      if ([200, 201, 202].includes(response.status)) {
        setDoctorId(response.data.profileDetails[0]._id);
        setDOB(
          response.data?.profileDetails[0]?.clientDOB
            ? moment(response.data?.profileDetails[0]?.clientDOB).toDate()
            : null
        );
      }
      if (response.data.statusCode === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeactive = async (e, id) => {
    let data = {
      isActive: e.target.value == "Active" ? true : false,
      doctorId: id,
    };
    try {
      const response = await adminUpdateStatus(data);
      if ([200, 201, 203].includes(response.status)) {
        toast.success(t(response.data.message));
        allUser();
      } else {
        toast.error(t(response.data.msg));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const Update = (id, url, data) => {
    setUpdateId(id);
    setUpdateModal(true);
    userDetails(id);
    setuserdata(data);
    setCalenderInvite(data?.doctorPersonalData?.sendCalendarInvite);
    if (data.role == "doctor") {
      setSelectedValue(data?.consultation);
      setSelectId(
        data?.consultation.map((d) => {
          return d._id;
        })
      );
      setSelectedType(
        data?.doctorPersonalData?.patientsCategory.map((d) => {
          return { eng: d, heb: d == "Children" ? "ילד.ה" : "מבוגר" };
        })
      );
      setSelectedSubscriptions(
        data?.doctorPersonalData?.subscriptions.map((data) => {
          return data;
        })
      );
    } else {
      setSelectedValue([]);
      setSelectId([]);
      setSelectedType([]);
      setSelectedSubscriptions([]);
    }
  };
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    editable: true,
    resizable: true,
    floatingFilter: true,
    // flex: 1,
    width: "auto",
  }));

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = async () => {
    let data = {
      page: 1,
      perPage: 1000,
      text: "",
    };

    const response = await adminGetUsers(data);
    setAllUser(
      response.data.user.map((d) => {
        return {
          "Physician/Client Name": `${d.first_name} ${d.last_name}`,
          Email: d.email,
          "Phone Number": d.phone,
          Id: d.id,
          Doctor_ClientId: d._id,
          data: d,
          Role: d.role,
          isActive: d?.doctorPersonalData?.isActive,
          Doctor_expertise:
            d?.consultation && d?.consultation.length != 0
              ? d?.consultation.map((d2) => {
                  return d2.heb;
                })
              : null,
        };
      })
    );
  };
  let gridApi;
  const onGridReady = useCallback((params) => {
    gridApi = params.api;
  }, []);

  const onExportClick = useCallback(() => {
    gridApi.exportDataAsCsv();
  }, []);

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
  };

  return (
    <div className="user-mangement-page">
      <div className="container-fluid">
        {reportModal ? (
          <Report
            handleReportModalClose={handleReportModalClose}
            reportModal={reportModal}
          />
        ) : null}

        {updateModal ? (
          <UpdateDetails
            userdata={userdata}
            updateId={updateId}
            updateModal={updateModal}
            selectedValue={selectedValue}
            id={id}
            doctorId={doctorId}
            value={value}
            setDOB={setDOB}
            setUpdateModal={setUpdateModal}
            setSelectedValue={setSelectedValue}
            setSelectId={setSelectId}
            handleClose={handleClose}
            selectedtype={selectedtype}
            selectedSubscriptions={selectedSubscriptions}
            setSelectedSubscriptions={setSelectedSubscriptions}
            setSelectedType={setSelectedType}
            setCalenderInvite={setCalenderInvite}
            calenderInvite={calenderInvite}
          />
        ) : null}

        <div className="ag-theme-alpine" style={{ height: 630 }}>
          <Button onClick={onExportClick} className="download_report">
            Export to CSV
          </Button>

          <AgGridReact
            ref={gridRef}
            rowData={allUser}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
            onCellClicked={cellClickedListener}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTable;
