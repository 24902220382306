import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.css";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Network } from "../../config/axios";

const ApproveTermsModal = (props) => {
  const { t } = useTranslation();
  let lang = localStorage.getItem("lang");
  const token = localStorage.getItem("token");
  const termsInit = {
    consentDocument: false,
    termsAndConditions: false,
  };

  const [termsValue, setTermsValue] = useState(termsInit);
  const [loading, setLoading] = useState(false);

  const handleConfirmation = (e) => {
    const { name, checked } = e.target;
    setTermsValue({ ...termsValue, [name]: checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!termsValue?.consentDocument) {
      toast.error(
        t("The terms of consent of medical information must be approved"), {toastId: "consentDocument"}
      );
      return;
    } else if (!termsValue?.termsAndConditions) {
      toast.error(
        t(
          "The terms of service and delivery of medical information must be approved"
        ), {toastId: "termsAndConditions"}
      );
      return;
    }
    updatedTerms();
  };

  const updatedTerms = async () => {
    const data = {
      termsAndConditions: true,
    };
    setLoading(true)
    try {
      const response = await Network().patch("/users/updateUserProfile", data, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      });
      if ([200, 201, 202].includes(response?.status)) {
        toast.success(t(response?.data?.msg), {toastId: "termsAndConditionsSucces"});
        props.setShow(false);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false); 
    }
  };

  return (
    <div>
      <Modal
        open={props.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1>
              {t(
                "The terms of service and delivery of medical information must be approved"
              )}
            </h1>
            <Form onSubmit={handleSubmit}>
              <div className="form-check-grid">
                <Form.Check
                  type="checkbox"
                  label={
                    <span>
                      {" "}
                      {t("I confirm the terms of")}{" "}
                      <a href="/consent" target="_blank">
                        {" "}
                        {t("informed consent document")}
                      </a>{" "}
                    </span>
                  }
                  name="consentDocument"
                  className={lang === "EN" ? "checkleft" : "checkright"}
                  checked={termsValue?.consentDocument || false}
                  onChange={handleConfirmation}
                />
                <Form.Check
                  type="checkbox"
                  label={
                    <span>
                      {" "}
                      {t("I agree to the")}{" "}
                      <a href="/terms" target="_blank">
                        {" "}
                        {t("terms of  use")}
                      </a>
                    </span>
                  }
                  name="termsAndConditions"
                  className={lang === "EN" ? "checkleft" : "checkright"}
                  checked={termsValue?.termsAndConditions || false}
                  onChange={handleConfirmation}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <MDBCardText>
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttton_content cancel-b"
                    type="submit"
                    disabled={loading}
                  >
                    {t("Continue")}
                  </Button>
                </MDBCardText>
              </div>
            </Form>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default ApproveTermsModal;
