import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "../contact/contact.css";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Paper, Typography, Modal } from "@material-ui/core";
import OtpInput from "react-otp-input";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { doctorSignUp, verifyOtp, allConsultation } from "../../config/service";
import { handleKeyPress } from "../Login/login";
import ReCAPTCHA from "react-google-recaptcha";
import { checkEmailFormate } from "../../utils/function";
import NewFooter from "../footer/newFooter";

const DoctorRegistration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [bisablebutton, setDisableButton] = useState(false);
  const [catpchaKey, setCaptchaKey] = useState();
  const [formValue, setformValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    id_number: "",
    mobile: "",
    title: "",
    Doctor_Id: "",
  });
  const [consultData, setConsultData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [id, setSelectId] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [fnameError, setfnameError] = useState("");
  const [lnameError, setlnameError] = useState("");
  const [idError, setIdError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [DoctorIdError, setDoctorError] = useState("");
  const [ConsultError, setConsultError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedtype, setSelectedType] = useState([]);
  const [typevalue, setTypeValue] = useState([]);
  const patienttype = [
    { eng: "Children", heb: "ילד.ה" },
    { eng: "Adults", heb: "מבוגר" },
  ];
  let lang = localStorage.getItem("lang");
  useEffect(() => {
    consultingFields();
  }, []);
  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue([...selectedValue, selectedItem]);
    setSelectId([...id, selectedItem._id]);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(
      selectedValue.filter((d) => {
        return d != removedItem;
      })
    );
    setSelectId(
      id.filter((d) => {
        return d != removedItem._id;
      })
    );
  };
  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  const consultingFields = async () => {
    const response = await allConsultation();
    setConsultData(response.data.result);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // store the states in the form data
    if (validate()) {
      setButtonActive(true);
      let doctordata = {
        email: formValue.email,
        phone: formValue.mobile,
        id: formValue.id_number,
        first_name: formValue.first_name,
        last_name: formValue.last_name,
        consultationSpeciality: id,
        doctorRegistrantNumber: formValue.Doctor_Id,
        title: "Doctor",
        patientsCategory: typevalue,
        licenceNumber: formValue.Doctor_Id,
        recaptcha: catpchaKey,
      };
      const isEmailCorrect = checkEmailFormate(doctordata?.email);
      if (!isEmailCorrect) {
        toast.error("Please enter valid email");
        setButtonActive(false);
        return;
      }
  
      try {
        await doctorSignUp(doctordata);
        toast.success(t("An OTP has been sent to your mobile number"), {
          toastId: "otpSent",
        });
        setOpen(true);
      } catch (error) {
        console.error("Error during sign-up:", error);
      } finally {
        setButtonActive(false);
      }
    }
  };
  
  const OtpValidation = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    // store the states in the form data
    let data = {
      phone: formValue.mobile,
      otp: otp,
      role: "doctor",
      id: formValue.id_number,
    };

    if (validate()) {
      try {
        const response = await verifyOtp(data);
        const { status } = response;
        if (status === 202) {
          toast.success(t(response.data.message), {
            toastId: "doctorOtpVerify",
          });
          setModalShow(true);
          setOpen(false);
        }
      } catch (error) {
        console.error("OTP verification failed:", error);
      } finally {
        setDisableButton(false);
      }
    } else {
      setDisableButton(false);
    }
  };
  
  const validate = () => {
    let isValid = true;

    if (!formValue.first_name) {
      isValid = false;
      setfnameError(t("Please enter first name"));
    } else {
      setfnameError("");
    }
    if (!formValue.last_name) {
      isValid = false;
      setlnameError(t("Please enter last name"));
    } else {
      setlnameError("");
    }

    if (!formValue.id_number) {
      isValid = false;
      setIdError(t("Please enter id number"));
    } else {
      setIdError("");
    }
    if (!formValue.email) {
      isValid = false;
      setEmailError(t("Please enter email address"));
    }

    if (typeof formValue.email !== "undefined") {
      const pattern = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!pattern.test(formValue.email)) {
        isValid = false;
        setEmailError(t("Please enter valid email"));
      }
    }
    if (!formValue.mobile) {
      isValid = false;

      setmobileError(t("Please enter mobile number"));
    }
    if (formValue.mobile) {
      if (formValue.mobile.length > 20) {
        isValid = false;

        setmobileError(t("Mobile number max length 20"));
      }
    }

    if (selectedValue.length === 0) {
      isValid = false;
      setConsultError(t("Please select"));
    } else {
      setConsultError("");
    }

    if (!formValue.Doctor_Id) {
      isValid = false;
      setDoctorError(t("Please enter doctor Id"));
    } else {
      setDoctorError("");
    }
    if (typevalue.length === 0) {
      isValid = false;
      setCategoryError(t("Please select patient category"));
    } else {
      setCategoryError("");
    }

    return isValid;
  };

  const onSelectType = (selectedList, selectedItem) => {
    setSelectedType([...selectedtype, selectedItem]);
    setTypeValue([...typevalue, selectedItem.eng]);
  };

  const onRemoveType = (selectedList, removedItem) => {
    setSelectedType(
      selectedtype.filter((d) => {
        return d != removedItem;
      })
    );
    setTypeValue(
      typevalue.filter((d) => {
        return d != removedItem.eng;
      })
    );
  };
  function onChange(value) {
    setCaptchaKey(value);
    if (value) {
      // setButtonActive(true);
    }
  }
  return (
    <div className="doctor-signup-page">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Modal
                open={modalShow}
                // onClose={() => setModalShow(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modalContent"
              >
                <MDBCard className="modal_card">
                  <MDBCardBody>
                    <h3>
                      {t(
                        "Your application is now going to be inspected by the team"
                      )}
                    </h3>
                    <Button
                      className="iframeclose"
                      onClick={() => (setModalShow(false), navigate("/login"))}
                    >
                      {t("Close")}
                    </Button>
                  </MDBCardBody>
                </MDBCard>
              </Modal>
              <div className="contact-form ">
                <div className="doctor-signup-content">
                  <div>
                    <h3> {t("Let patient find you")}</h3>
                    <h4> {t("How It Works ?")}</h4>
                    <p>
                      {t(
                        "Define the type of service and set times that are usually convenient for you to provide the consultation, and we will take care of setting a consultation time with the patients."
                      )}
                    </p>
                    <h4>{t("Joining is not mandatory")}</h4>
                    <p>
                      {t(
                        "After confirming your registration, you can enter your profile settings and set the dates that are convenient for you."
                      )}
                    </p>
                    <p>{t("From here, we will take care of everything")}</p>
                    <h4>{t("Interested in joining?")}</h4>
                    <p>
                      {t("Joining therapists to hidoc is by invitation only!")}{" "}
                    </p>
                    <Button
                      variant="info"
                      size="lg"
                      className="regbutton"
                      onClick={() => setShow(true)}
                    >
                      {t("to register")}
                    </Button>
                  </div>
                  {show ? (
                    <div className="doctor-signup-form">
                      <Form className="form">
                        <Form.Group
                          className={`firstname-form ${
                            fnameError ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>{t("First Name")} *</Form.Label>
                          <Form.Control
                            name="first_name"
                            type="text"
                            onChange={handleChange}
                            id="first_name"
                          />
                          {fnameError && (
                            <span className="errorsmg">
                              {t(`${fnameError}`)}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`lastname-form ${
                            lnameError ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>{t("Last Name")} *</Form.Label>
                          <Form.Control
                            name="last_name"
                            type="text"
                            onChange={handleChange}
                            id="last_name"
                          />
                          {lnameError && (
                            <span className="errorsmg">
                              {t(`${lnameError}`)}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`firstname-form ${
                            emailError ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>{t("Email")} *</Form.Label>
                          <Form.Control
                            name="email"
                            type="text"
                            onChange={handleChange}
                            id="email"
                          />
                          {emailError && (
                            <span className="errorsmg">
                              {t(`${emailError}`)}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`lastname-form ${
                            idError ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>{t("ID Number")} *</Form.Label>
                          <Form.Control
                            name="id_number"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onChange={handleChange}
                            id="id_input"
                            maxlength="14"
                            onKeyPress={handleKeyPress}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 14))
                            }
                          />
                          {idError && (
                            <span className="errorsmg">{t(`${idError}`)}</span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`firstname-form ${
                            ConsultError !== "" ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>{t("Specialisation")} *</Form.Label>
                          <Multiselect
                            className="form-control selectData"
                            options={consultData}
                            selectedValues={selectedValue}
                            onSelect={onSelect}
                            onRemove={onRemove}
                            displayValue={lang === "EN" ? "eng" : "heb"}
                            placeholder=""
                            id="consult"
                          />
                          {ConsultError && (
                            <span className="errorsmg">
                              {t(`${ConsultError}`)}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`firstname-form ${
                            categoryError !== "" ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>{t("Patient Type")} *</Form.Label>
                          <Multiselect
                            className="form-control selectData"
                            options={patienttype}
                            selectedValues={selectedtype}
                            onSelect={onSelectType}
                            onRemove={onRemoveType}
                            displayValue={lang === "EN" ? "eng" : "heb"}
                            placeholder=""
                          />
                          {categoryError && (
                            <span className="errorsmg">
                              {t(`${categoryError}`)}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`lastname-form ${
                            mobileError ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>{t("Phone Number")}*</Form.Label>
                          <Form.Control
                            name="mobile"
                            type="text"
                            onChange={handleChange}
                            id="mobile_input"
                            className="phone_number"
                          />
                          {mobileError && (
                            <span className="errorsmg">
                              {t(`${mobileError}`)}
                            </span>
                          )}
                        </Form.Group>

                        <Form.Group
                          className={`firstname-form ${
                            DoctorIdError !== "" ? "error-input" : ""
                          }`}
                        >
                          <Form.Label>
                            {t("Doctor License Number")} *
                          </Form.Label>
                          <Form.Control
                            name="Doctor_Id"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onChange={handleChange}
                            id="Doc_id_input"
                            onKeyPress={handleKeyPress}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 14))
                            }
                          />
                          {DoctorIdError && (
                            <span className="errorsmg">
                              {t(`${DoctorIdError}`)}
                            </span>
                          )}
                        </Form.Group>
                      </Form>
                      <ReCAPTCHA
                        sitekey="6LdVdkQlAAAAACmcnomBpNHmuCazz-OIhchp6B32"
                        onChange={onChange}
                        className="captchaContainer"
                      />
                      <Button
                        disabled={buttonActive}
                        variant="info"
                        size="lg"
                        className="submit_button"
                        onClick={handleSubmit}
                      >
                        {t("Continue")}
                      </Button>
                    </div>
                  ) : null}

                  <div className="hlink">
                    <Link to="/login">{t("already have an account")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="modalDiv"
            >
              <Grid container direction="column" alignItems="center">
                <Grid item xs>
                  <Paper className="paper" square>
                    <Grid container direction="column" alignItems="center">
                      <Grid item lg>
                        <Typography
                          variant="h4"
                          gutterBottom
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            color: "gray",
                          }}
                        >
                          {t("Please Enter the OTP to Verify Your Account")}
                        </Typography>
                      </Grid>
                      <Grid item sm>
                        <Typography variant="h6" gutterBottom>
                          <span>{formValue.mobile} </span>
                          {t("An OTP(one time password) has been sent to")}
                        </Typography>
                      </Grid>

                      <Grid item xs>
                        <OtpInput
                          value={otp}
                          onChange={(otp) => {
                            setOtp(otp);
                          }}
                          numInputs={6}
                          inputStyle={{
                            fontSize: "24px",
                            width: "36px",
                            height: "36px",
                            margin: "4px",
                            borderTop: "0px",
                            borderLeft: "0px",
                            borderRight: "0px",
                            outline: "none",
                            borderColor: "#000a46",
                          }}
                          containerStyle={{
                            margin: "20px auto",
                            padding: "10px",
                          }}
                          isInputNum
                        />
                      </Grid>
                      <Grid container direction="column" alignItems="center">
                        <Grid
                          item
                          xs
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "10px",
                          }}
                        >
                          <Button
                            onClick={OtpValidation}
                            disabled={bisablebutton}
                            style={{
                              padding: "10px 45px 10px 45px",
                            }}
                          >
                            {t("Validate OTP")}
                          </Button>
                          {/* <Button variant="caption" display="block" gutterBottom
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "gray",
                                                            marginTop: "10px"

                                                        }}>
                                                    {t("Resend OTP")}
                                                </Button> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Modal>
          </div>
        </div>
      </div>
      <NewFooter />
    </div>
  );
};
export default DoctorRegistration;
