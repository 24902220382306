import React, { useState } from "react";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import moment from "moment";
import { Modal } from "@material-ui/core";
import { documentView } from "../../config/service";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../Dashborad/dashboard.css";
import pdfIcon from "../../assets/images/icons/pdf.png";
import imageIcon from "../../assets/images/icons/image-icon.png";
import AllDocumentModel from "./AllDocumentModel";

const ViewDocument = (props) => {
  const { pacseeDocument } = props;
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docIndex, setDocIndex] = useState();
  const [docURL, setDocURL] = useState("");
  const { t } = useTranslation();
  const handleDocModel = (index) => {
    setOpenModel(!openModel);
    setDocIndex(index);
    if (!openModel) {
      viewPdf(
        props?.allPdfDocument[index]?.path,
        props?.allPdfDocument[index]?.fileType
      );
    } else {
      setDocURL("");
    }
  };
  const handlePreDoc = () => {
    viewPdf(
      props?.allPdfDocument[docIndex - 1].path,
      props.allPdfDocument[docIndex - 1]?.fileType
    );
    setDocIndex(docIndex - 1);
  };
  const handleNextDoc = () => {
    viewPdf(
      props?.allPdfDocument[docIndex + 1].path,
      props.allPdfDocument[docIndex + 1]?.fileType
    );
    setDocIndex(docIndex + 1);
  };
  const viewPdf = async (path, type) => {
    setLoading(true);
    const data = {
      url: path,
    };

    const response = await documentView(data);
    setDocURL({ view: response.data, original: path, type: type });
    setLoading(false);
  };

  return (
    <div>
      {openModel ? (
        <AllDocumentModel
          openModel={openModel}
          handleClose={handleDocModel}
          docURL={docURL}
          handlePreDoc={handlePreDoc}
          handleNextDoc={handleNextDoc}
          documentLength={props.allPdfDocument?.length}
          docIndex={docIndex}
          loading={loading}
        />
      ) : null}
      <Modal
        open={props.documentModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1> {t("All Documents")}</h1>
            {pacseeDocument?.length == 0 && props.documentList?.length == 0 ? (
              <h3>{t("No document uploaded")}</h3>
            ) : null}
            <ul className="listdocument">
              {pacseeDocument?.length != 0 ? (
                <>
                  {pacseeDocument.map((d, i) => {
                    return (
                      <div className="fileList" key={i}>
                        <li>
                          {t("File")}-{d.study_descr}
                        </li>
                        <div>
                          <Button
                            onClick={() =>
                              window.open(d.view_url_options, "_blank")
                            }
                          >
                            {" "}
                            {t("View")}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
              {props.allPdfDocument
                ? props.allPdfDocument.map((fileName, i) => {
                    return (
                      <li key={i}>
                        <div className="row fileList">
                          <div className="col-md-6">
                            <p className="file-name">
                              {fileName?.type === "Summary"
                                ? `${
                                    t("Meeting summary from") +
                                    moment(fileName?.createdAt).format(
                                      "DD.MM.YYYY"
                                    )
                                  }`
                                : fileName.name}
                            </p>
                          </div>
                          <div className="col-md-3">
                            <div className="file-thumbnail">
                              <img
                                src={
                                  fileName?.fileType === "pdf" ||
                                  fileName?.fileType === "application/pdf"
                                    ? pdfIcon
                                    : imageIcon
                                }
                                alt="thumbnail"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <Button
                              className="view-doc-pdf"
                              onClick={() => handleDocModel(i)}
                            >
                              {t("View")}
                            </Button>
                          </div>
                        </div>
                      </li>
                    );
                  })
                : null}
              <Button
                sm="lg"
                className="buttton_content close-doc-btn"
                onClick={props.handleClose}
              >
                {" "}
                {t("Close")}
              </Button>
            </ul>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default ViewDocument;
