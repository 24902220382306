import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RxContractModal = ({ show, setContract }) => {
  const { t } = useTranslation();
  const [submit, setSubmit] = useState(false);

  const handleSignContract = () => {
    const url = "https://app.hellosign.com/s/LWOYDV9l";
    // eslint-disable-next-line no-undef
    window.open(url, "_blank");
    setSubmit(true);
  };
  const handleCloseSignContract = () => {
    setContract(false);
  };

  return (
    <Modal
      onHide={() => setContract(false)}
      show={show}
      centered
      className="prescription-alert"
    >
      <Modal.Body>
        <div className="contract-content rescription-alert">
          {!submit ? (
            <>
              <h6>{t("Hello dear doctor")} </h6>
              <h6>
                {t(
                  "In order to use the prescription system, you must go to the following link and sign."
                )}{" "}
              </h6>
              <h6>{t("Best regards")} </h6>
              <h6>{t("Hidoc team")} </h6>
            </>
          ) : (
            <>
              <h6>
                {t(
                  "The document has been submitted for review, upon its approval you will receive a notification that the system has been opened for your use."
                )}{" "}
              </h6>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!submit ? (
          <Button className="border-btn" onClick={handleSignContract}>
            {" "}
            {t("Sign Contract")}{" "}
          </Button>
        ) : (
          <Button className="border-btn" onClick={handleCloseSignContract}>
            {t("Close")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RxContractModal;
