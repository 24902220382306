import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashborad/dashboard.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const ViewSurvey = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={props.medicalq}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody className="textchange">
            <h1> {t("Medical questionnaire")}</h1>
            <h3>
              {" "}
              {t(
                "Filling out the questionnaire will allow the expert to prepare for the conversation and ensure quality and purposeful advice"
              )}
            </h3>
            <h2> {t("Description of the main complaint")}</h2>
            <textarea
              rows={2}
              className="form-control textDiv survey"
              readOnly={true}
              name="mainComplaint"
              value={props.qData ? props.qData.mainComplaint : ""}
            />
            <h2> {t("Description of the disease and medical history")}</h2>
            <textarea
              rows={2}
              className="form-control textDiv survey"
              readOnly={true}
              name="medicalHistory"
              value={props.qData ? props.qData.medicalHistory : ""}
            />
            <h2>{t("Regular medications")}</h2>
            <textarea
              rows={2}
              className="form-control textDiv survey"
              readOnly={true}
              name="medications"
              value={props.qData ? props.qData.medications : ""}
            />

            <Button
              size="lg"
              onClick={props.handleClose}
              className="buttton_content"
            >
              {t("Close")}
            </Button>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default ViewSurvey;
