import React from 'react';
import Layout from '../layout/layout';
import './index.css';
import thank from '../../assets/images/icons/gift-card.png';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
const FailedPage = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams()
  const msg = searchParams.get("msg")
  console.log(setSearchParams)
  return (
    <Layout>
      <div className='thank-you-page'>
        <div className='thank-you-bg'></div>

        <div className='thank-you-grid'>
          <div className='thank-you-content'>
            {/* {ThankYouIconSVG} */}
            <img src={thank} alt='icon' />
            <h2>{t("Sorry")}</h2>
            <p> {t("Your payment has been declined, please try again")}</p>
            <p>{msg}</p>
          </div>

          <div className='home-btn'>
            <a href='/'>{t("Try Again")}</a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FailedPage;
