import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { Modal } from "@material-ui/core";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Network } from "../../../config/axios";
import DyteMeetingLink from "../../common/DyteMeetLink";

const AddParticipantModal = (props) => {
  const { refetchAllmeetings, updatedData, setUpdateData, allMeetingData } =
    props;
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [formValue, setformValue] = useState({
    mobile: "",
  });

  const filterScheduledMeetings = (allMeetingData, updatedDataId) => {
    const scheduledData = allMeetingData.find(
      (data) => data.type === "Scheduled"
    );
    if (!scheduledData) return [];

    const filteredMeetings = scheduledData?.meetings.filter(
      (meeting) => meeting?.data?._id === updatedDataId
    );
    if (filteredMeetings.length > 0) {
      setUpdateData(filteredMeetings[0].data);
    }

    return filteredMeetings || [];
  };

  const filterFutureMeetings = (allMeetingData, updatedDataId) => {
    const filteredMeetings = allMeetingData.filter(
      (meeting) => meeting?._id === updatedDataId
    );
    if (filteredMeetings.length > 0) {
      setUpdateData(filteredMeetings[0]);
    }

    return filteredMeetings || [];
  };

  const handleUserConfirmationPhone = (e) => {
    const { name, value } = e.target;
    if (
      value.startsWith("+") &&
      !isNaN(value.substring(1, value.length)) &&
      !value.endsWith(".")
    ) {
      setformValue({
        ...formValue,
        [name]: value.trim(),
      });
    } else if (!isNaN(value) && !value.endsWith(".")) {
      setformValue({
        ...formValue,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formValue?.mobile) {
      toast.error(t("A mobile phone number must be entered"), {
        toastId: "mobile",
      });
      return;
    }
    if (props.meetingId) {
      addParticipant();
    }
  };

  const addParticipant = async () => {
    const { mobile } = formValue;
    const data = { phone: mobile };

    try {
      setLoading(true);

      const response = await Network().post(
        `meeting/addAdditionalParticipant/${props.meetingId}`,
        data,
        {
          headers: {
            authorization: token,
          },
        }
      );

      if (response?.status === 201) {
        if (role === "admin") {
          await refetchAllmeetings();
          filterScheduledMeetings(allMeetingData, updatedData?._id);
        }
        if (role === "client") {
          await refetchAllmeetings({ val: "future" });
          filterFutureMeetings(allMeetingData, updatedData?._id);
        }
        toast.success(t("Guest link generated successfully"));
        resetForm();
      }
    } catch (error) {
      console.error("Error adding participant:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role === "admin") {
      filterScheduledMeetings(allMeetingData, updatedData?._id);
    }
    if (role === "client") {
      filterFutureMeetings(allMeetingData, updatedData?._id);
    }
  }, [allMeetingData]);

  const resetForm = () => {
    setformValue({
      mobile: "",
    });
  };

  return (
    <div>
      <Modal
        open={props.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
        onClose={props.handleClose}
      >
        <MDBCard className="modal_card add-participant">
          <MDBCardBody>
            <h1>{t("Add Additional Participant")}</h1>
            {role === "client" &&
              (updatedData?.dyteMeetingResponse?.additionalParticipant ? (
                <h6 className={`guest-number-${lang === "HE" ? "heb" : "eng"}`}>
                  {t("A connection link for the call was sent to:")}{" "}
                  <span>
                    {updatedData?.additionalParticipantDetails?.phone}
                  </span>{" "}
                  {t(
                    "For your convenience, you can also copy the link in the next button if necessary"
                  )}{" "}
                </h6>
              ) : (
                <>
                  <h6>
                    {t(
                      "You can add an additional participant on your behalf to the counseling session."
                    )}
                  </h6>
                  <h6>
                    {t(
                      "By typing the phone number of the participant you want to add, a message will be sent to him with the date of the meeting and a link to connect to the conversation."
                    )}
                  </h6>
                </>
              ))}

            <Form onSubmit={handleSubmit}>
              {!updatedData?.dyteMeetingResponse?.additionalParticipant ? (
                <Form.Group className="mb-3">
                  <h3> {t("Mobile Number")}</h3>
                  <Form.Control
                    type="text"
                    name="mobile"
                    id="mobile_input"
                    placeholder={t("Mobile Number")}
                    onChange={handleUserConfirmationPhone}
                    value={formValue?.mobile ? formValue?.mobile : ""}
                    autocomplete="off"
                    dir="ltr"
                    style={{ direction: "ltr" }}
                  />
                </Form.Group>
              ) : null}
              {updatedData && updatedData?.dyteMeetingResponse && (
                <DyteMeetingLink updateData={updatedData} />
              )}
              {!updatedData?.dyteMeetingResponse?.additionalParticipant ? (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttton_content cancel-b"
                    type="submit"
                    disabled={loading}
                  >
                    {t("Submit")}
                  </Button>
                </div>
              ) : null}
            </Form>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default AddParticipantModal;
