import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../components/Dashborad/dashboard.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import {
  doctorCreatePrescription,
  doctorGeneratePdf,
  doctorSummary,
} from "../config/service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";

const GeneratePdf = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [usernameerror, setUsernameerror] = useState("");
  const [passworderror, setPassworderror] = useState("");
  const [isloader, setIsLoader] = useState(false);

  const validate = () => {
    let isValid = true;

    if (!userName) {
      isValid = false;
      setUsernameerror(t("Please Enter User Name"));
    } else {
      setUsernameerror("");
    }
    if (!password) {
      isValid = false;
      setPassworderror(t("Please Enter Password"));
    } else {
      setPassworderror("");
    }
    return isValid;
  };

  const updateSummary = async () => {
    if (props.typing === false) {
      return;
    }
    const data = {
      meetingId: props.rxMeetingId,
      background_details: props.payloadData.background_details,
      continuingCare: props.payloadData.continuingCare,
      diagnsis: props.payloadData.diagnsis,
      referral_reason: props.payloadData.referral_reason,
      treatment_program: props.payloadData.treatment_program,
      prescription: props.payloadData.prescription,
    };
    if (
      props.payloadData.prescriptionFields &&
      props.payloadData.prescriptionFields.name
    ) {
      data.prescriptionFields = {
        name: props.payloadData.prescriptionFields.name,
        position: props.payloadData.prescriptionFields.position,
        data: props.payloadData.prescriptionFields.data
          ? props.payloadData.prescriptionFields.data
          : "",
      };
    }

    await doctorSummary(data);
    props.typing = false;
    // toast.success(t(response.data.msg));
  };

  const Save = async () => {
    const data = {
      userName: userName,
      password: password,
    };
    if (validate()) {
      setIsLoader(true);
      try {
        let response = null;
        let dataPayload = {};

        if (props.rxMeetingId) {
          // addSummary API Calls
          updateSummary();

          // generatePDF API Calls
          data.meetingId = props.rxMeetingId;
          response = await doctorGeneratePdf(data);
          toast.success(t(response.data.msg));
          localStorage.setItem("status", "Rx");
          navigate("/Queues");
          props.setRxMeetingId("");
        } else if (props.payloadData) {
          dataPayload = { ...data, ...props.payloadData };
          response = await doctorCreatePrescription(dataPayload);
          toast.success(t(response.data.message));
          localStorage.setItem("status", "Rx");
          navigate("/Queues");
        } else {
          data.meetingId = props.meetingId;
          response = await doctorGeneratePdf(data);
          toast.success(t(response.data.msg));
        }
        setIsLoader(false);
        props.handleClose4();
        props.handleClose();
      } catch (error) {
        props.handleClose4();
        setIsLoader(false);
        if (error.response?.data?.statusCode === 403) {
          const messageParts = error.response.data.message.split(" ");
          const reqMeetingId = messageParts[messageParts.length - 1];
          toast.error(t("Please use correct comsign credentials"));
          props.setRxMeetingId(reqMeetingId);
        }
      }
    }
  };

  return (
    <div>
      <div>
        <Modal
          open={props.generateUpdateModal}
          onClose={props.handleClose4}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalContent"
        >
          <MDBCard className="modal_card">
            <MDBCardBody>
              <div>
                <h1> {t("Enter Comsign credentials")}</h1>
                {isloader ? (
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={isloader}
                  />
                ) : (
                  <Form className="comsign_form">
                    <Form.Group className="form-group ">
                      <input
                        className="form-control"
                        name="userName"
                        type="text"
                        id="userName"
                        style={{ direction: "ltr" }}
                        placeholder={t("User Name")}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </Form.Group>
                    <span style={{ color: "red", textAlign: "center" }}>
                      {usernameerror}
                    </span>
                    <br></br>
                    <Form.Group className="form-group ">
                      <input
                        className="form-control"
                        name="password"
                        type="text"
                        id="password"
                        style={{ direction: "ltr" }}
                        placeholder={t("Password")}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <span style={{ color: "red", textAlign: "center" }}>
                      {passworderror}
                    </span>
                  </Form>
                )}
              </div>
              <br></br>
              <Button
                size="lg"
                disabled={isloader}
                className="updatesent"
                onClick={Save}
              >
                {t("Save")}
              </Button>
            </MDBCardBody>
          </MDBCard>
        </Modal>
      </div>
    </div>
  );
};
export default GeneratePdf;
