import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  adminAllConsultation,
  uploadMeetingFile,
} from "../../../config/service";
import { Network } from "../../../config/axios";

const IDFUpload = ({ handleIdfUpload, idfModal, getallmeetings }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const lang = localStorage.getItem("lang");
  const token = localStorage.getItem("token");
  // const [loader, setLoader] = useState(false);

  //   const [fileType, setFileType] = useState("");
  const [categoriesData, setcategoriesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchResult, setSearchResult] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [doctorId, setDoctorId] = useState();
  // const [meetingType, setMeetingType] = useState("false");
  const [file, setFile] = useState(null);
  const [resModal, setResModal] = useState(false);
  const [apiRes, setApiRes] = useState();

  const handleResModal = () => {
    setResModal(false);
    getallmeetings();
  };

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchResult(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const consultingFields = async () => {
    const response = await adminAllConsultation();
    setcategoriesData(response.data.result);
    setFilteredData(response.data.result);
  };
  const getDoctorList = async (data) => {
    try {
      const response = await Network().get(
        "/admin/getDoctorsByConsultationId/" + data,
        {
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setDoctorList(response.data.doctors);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (idfModal) {
      consultingFields();
    }
  }, [idfModal]);

  const boldQuery = (item) => {
    const text = lang === "EN" ? item?.eng : item?.heb;
    const termSp = searchText.split("");
    const textSp = text.split("");
    const result = textSp.map((el) => {
      if (
        termSp.includes(el.toLowerCase()) ||
        termSp.includes(el.toUpperCase())
      ) {
        return `<b>${el}</b>`;
      }
      return el;
    });
    return result.join("");
  };

  //   const handleFileType = (e) => {
  //     setFileType(e.target.value);
  //   };

  const handleSearch = (e) => {
    setSearchResult(true);
    const searchWord = e.target.value;
    setSearchText(searchWord);
    const newFilter = categoriesData.filter((value) => {
      return lang === "EN"
        ? value.eng.toLowerCase().includes(searchWord.toLowerCase())
        : value.heb.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (
      categoriesData.find((el) =>
        lang === "EN"
          ? el.eng.toLowerCase() === searchWord?.toLowerCase()
          : el.heb.toLowerCase() === searchWord?.toLowerCase()
      )
    ) {
      // setLoader(false);
    } else {
      // setLoader(true);
    }
    if (searchWord === "") {
      setFilteredData(categoriesData);
      setDoctorList([]);
      setSelectedData("");
    } else {
      setFilteredData(newFilter);
    }
  };

  const handleSelectResult = (data) => {
    setSearchText(lang === "EN" ? data?.eng : data?.heb);
    setSelectedData(data);
    getDoctorList(data?._id);
    setSearchResult(false);
    // setLoader(false);
  };
  // const handleMeetingType = () => {
  //   setMeetingType(!meetingType);
  // };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const validation = () => {
    let isValid = true;
    if (!file) {
      toast.error(t("Please upload a file"), { toastId: "upload" });
      return (isValid = false);
    }
    // if (!fileType) {
    //   toast.error(t("Please choose file type"));
    //   return (isValid = false);
    // }
    if (!selectedData?._id) {
      toast.error(t("Please choose consultation"), { toastId: "consultation" });
      return (isValid = false);
    }
    if (!doctorId) {
      toast.error(t("Please choose doctor"), { toastId: "doctor" });
      return (isValid = false);
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validation()) {
      return;
    }
    const formData = new FormData();
    let fileType = "CSV";
    if (file) {
      if (file.type === 'text/csv') {
        fileType = 'CSV';
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        fileType = 'XLSX';
      }
    }
    formData.append("consultationId", selectedData?._id);
    formData.append("meetingFile", file);
    formData.append("doctorId", doctorId);
    formData.append("fileType", fileType);
    // formData.append("action", meetingType ? "Add" : "Update");

    try {
      const response = await uploadMeetingFile(formData);
      if ([200, 201, 203].includes(response.status)) {
        setApiRes(response.data);
        handleIdfUpload();

        setResModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <ResponseModal
        t={t}
        handleResModal={handleResModal}
        resModal={resModal}
        apiRes={apiRes}
      />
      <Modal
        open={idfModal}
        onClose={handleIdfUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card collective_content">
          <MDBCardBody>
            <h1 style={{ marginBottom: "40px" }}>{t("IDF Upload")}</h1>

            <Form
              className={`idf-upload-form ${lang === "HE" ? "rtl-css" : ""}`}
              dir={lang === "HE" ? "rtl" : "ltr"}
              onSubmit={handleSubmit}
            >
              <Form.Group className="form-group">
                <Form.Label>{t("Upload File")}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="file"
                  placeholder="Upload your file"
                  id="uploadFile"
                  autoComplete="off"
                  onChange={handleFileChange}
                  accept=".csv,.xlsx"
                />
              </Form.Group>
              {/* <Form.Group className="form-group">
                <Form.Label>{t("Choose File Type")}</Form.Label>
                <select
                  name="fileType"
                  value={fileType}
                  onChange={(e) => handleFileType(e)}
                  className="form-control"
                >
                  <option value="">Select File Type</option>
                  <option value="xlsx">XLSX</option>
                  <option value="csv" disabled>
                    CSV
                  </option>
                </select>
              </Form.Group> */}

              <Form.Group className="form-group search-input-form">
                <div className="search-input" ref={wrapperRef}>
                  <Form.Label>{t("Choose consultation")}</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder={t("You can type the desired domain")}
                    value={searchText}
                    id="consult"
                    onChange={handleSearch}
                    autoComplete="off"
                  />

                  {searchResult && searchText !== "" ? (
                    <SearchResultComponent
                      filteredData={filteredData}
                      boldQuery={boldQuery}
                      searchText={searchText}
                      handleSelectResult={handleSelectResult}
                      lang={lang}
                      t={t}
                    />
                  ) : null}
                </div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>{t("Choose doctor ")}</Form.Label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setDoctorId(e.target.value);
                  }}
                  id="doctor_list"
                  value={doctorId}
                >
                  <option value="">
                    {selectedData?._id
                      ? t("Select doctor")
                      : t("Select consultation first")}
                  </option>
                  {doctorList.map((d, i) => {
                    return (
                      <option
                        key={i}
                        value={`${d?.doctorId}`}
                        selected={doctorId === d?.doctorId}
                      >{`${d?.doctorData?.first_name} ${d?.doctorData?.last_name}`}</option>
                    );
                  })}
                </select>
              </Form.Group>

              {/* Toggale button is start from here for the action change create or Update  */}

              {/* <Form.Group className="form-group">
                <div className="meeting-type-toggle">
                  <Form.Label>Action type</Form.Label>
                  <input type="checkbox" onClick={handleMeetingType} />
                  <Form.Label className="label-value">
                    {meetingType ? t("Create") : t("Update")}
                  </Form.Label>
                </div>
              </Form.Group> */}

              {/* Toggle Button is end from here for the action change create or update  */}

              <div className="submit-btn-grid">
                <Button className="download_report" type="submit">
                  {t("Submit")}
                </Button>
              </div>
            </Form>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default IDFUpload;

const SearchResultComponent = ({
  filteredData,
  boldQuery,
  handleSelectResult,
  t,
}) => {
  return (
    <div className="search-result-section">
      <ul>
        {filteredData && filteredData?.length > 0 ? (
          filteredData.map((data, i) => {
            return (
              <li
                dangerouslySetInnerHTML={{
                  __html: `${boldQuery(data)}`,
                }}
                key={i}
                onClick={() => handleSelectResult(data)}
              />
            );
          })
        ) : (
          <li className="no-result-match">{t("No result found")}</li>
        )}
      </ul>
    </div>
  );
};

const ResponseModal = ({ t, resModal, handleResModal, apiRes }) => {
  return (
    <div>
      <Modal
        open={resModal}
        onClose={handleResModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent response-modal"
      >
        <MDBCard className="modal_card collective_content">
          <MDBCardBody>
            <h1 style={{ marginBottom: "40px", marginTop: "40px" }}>
              {t("IDF Upload Response")}
            </h1>
            {apiRes ? (
              <div className="response-grid">
                <div className="success-grid">
                  <h4>
                    Number of meetings created&nbsp;&nbsp;:&nbsp;&nbsp;
                    {apiRes?.success?.NumberOfMeetingsCreated}
                  </h4>
                </div>
                {apiRes.log.length > 0 ? (
                  <div className="error-grid">
                    <div className="error-subgrid">
                      <h5 className="error-head w-25"> {t("Row Number")} </h5>
                      <h5 className="error-head w-50"> {t("Message")} </h5>
                    </div>
                    <div className="error-body">
                      {apiRes.log.map((el) => (
                        <div className="error-subgrid">
                          <h5 className="error-data w-25"> {el?.rowNumber}</h5>
                          <h5
                            className="error-data w-75"
                            style={{ textAlign: "left" }}
                          >
                            {" "}
                            {el?.msg}
                          </h5>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              ""
            )}

            <div className="submit-btn-grid">
              <Button
                className="download_report"
                type="submit"
                onClick={() => {
                  handleResModal();
                }}
              >
                {t("Close")}
              </Button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
