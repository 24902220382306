import React, { useState } from "react";
import { Rnd } from "react-rnd";
import { RotatingLines } from "react-loader-spinner";
import { documentView } from "../../config/service";
import pdfIcon from "../../assets/images/icons/pdf.png";
import imageIcon from "../../assets/images/icons/image-icon.png";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../My Queues/queues.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import "react-quill/dist/quill.snow.css";

import { useTranslation } from "react-i18next";
import InnerSummaryComponent from "./InnerSummaryComponent";
import DyteMeetingConatiner from "../Dyte/dyteMeetingContainer";

const DocWithSummaryModel = (props) => {
  const { t } = useTranslation();
  const [scrDoc, setSrcDoc] = useState({});
  const [docIndex, setDocIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [meetingStarted, setMeetingStarted] = useState(false);

  const { pacseeDocument } = props;

  const handleDoc = (index) => {
    setDocIndex(index);
    viewPdf(
      props?.allPdfDocument[index]?.path,
      props?.allPdfDocument[index]?.fileType
    );
  };

  const handlePreDoc = () => {
    viewPdf(
      props?.allPdfDocument[docIndex - 1].path,
      props?.allPdfDocument[docIndex - 1]?.fileType
    );
    setDocIndex(docIndex - 1);
  };
  const handleNextDoc = () => {
    viewPdf(
      props?.allPdfDocument[docIndex + 1].path,
      props?.allPdfDocument[docIndex + 1]?.fileType
    );
    setDocIndex(docIndex + 1);
  };
  const viewPdf = async (path, type) => {
    setLoading(true);
    const data = {
      url: path,
    };
    const response = await documentView(data);
    setSrcDoc({ view: response.data, type: type });
    setLoading(false);
  };

  const styles = `text-align: center;`;

  const handleModal = () => {
    props.handleClose();
    setSrcDoc("");
    setDocIndex();
  };

  const meetingScheduledTime = new Date(props.meetingData?.scheduledDate);
  const meetingStartTime = new Date(
    meetingScheduledTime.getTime() - 10 * 60000
  );
  const canStartMeeting = meetingStartTime <= props.remaintime;

  const handleStartMeeting = () => {
    if (canStartMeeting) {
      setMeetingStarted(true);
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => handleModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="summaryModal doctor-summary"
      >
        <MDBCard style={{ width: "100%", height: "100%", overflowY: "auto" }}>
          <MDBCardBody style={{ padding: 0 }}>
            <div
              className={`doc-with-sumary ${
                !props.checkIDF ? "video-sec" : ""
              }`}
            >
              <div className="doc-with-right">
                <Rnd
                  default={{
                    x: 0,
                    y: 0,
                    width: `${props.checkIDF ? "42vw" : "31vw"}`,
                    height: "88vh",
                  }}
                  minWidth={200}
                  minHeight={230}
                  bounds="window"
                  className="box-style"
                  cancel="button, textarea, select, li, span, input, .card, .modal_card, .modalContent"
                >
                  <InnerSummaryComponent
                    handleClose={props.handleClose}
                    meetingId={props.meetingId}
                    updateSummaryResponce={props.updateSummaryResponce}
                    prescriptionResponce={props.prescriptionResponce}
                    setUpdateSummaryResponse={props.setUpdateSummaryResponse}
                    userName={props.userName}
                    checkIDF={props.checkIDF}
                  />
                </Rnd>
              </div>
              <div className="doc-with-mid">
                <Rnd
                  default={{
                    x: 0,
                    y: 0,
                    width: `${props.checkIDF ? "42vw" : "31vw"}`,
                    height: "88vh",
                  }}
                  minWidth={250}
                  minHeight={230}
                  bounds="window"
                  className="box-style"
                  cancel="button, textarea, select, li, span, input, .card, .modal_card, .modalContent"
                >
                  <h1> {t("All Documents")}</h1>
                  {pacseeDocument?.length == 0 &&
                  props.allPdfDocument?.length == 0 ? (
                    <h3>{t("No document uploaded")}</h3>
                  ) : (
                    <>
                      <ul className="listdocument">
                        {pacseeDocument && pacseeDocument?.length != 0 ? (
                          <>
                            {pacseeDocument?.map((d, i) => {
                              return (
                                <div className="fileList" key={i}>
                                  <li>
                                    {t("File")}-{d.study_descr}
                                  </li>
                                  <div>
                                    <Button
                                      onClick={() =>
                                        window.open(
                                          d.view_url_options,
                                          "_blank"
                                        )
                                      }
                                    >
                                      {" "}
                                      {t("View")}
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}

                        {props?.allPdfDocument &&
                          props?.allPdfDocument?.map((fileName, i) => {
                            return (
                              <li key={i}>
                                <div className="row fileList">
                                  <div className="col-md-6">
                                    <p className="file-name">{fileName.name}</p>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="file-thumbnail">
                                      <img
                                        src={
                                          fileName?.fileType === "pdf" ||
                                          fileName?.fileType ===
                                            "application/pdf"
                                            ? pdfIcon
                                            : imageIcon
                                        }
                                        alt="thumbnail"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <Button onClick={() => handleDoc(i)}>
                                      {t("View")}
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                      {props?.allPdfDocument &&
                      props?.allPdfDocument.length !== 0 ? (
                        <>
                          <div className="doc-grid">
                            {scrDoc?.view ? (
                              <Button
                                disabled={docIndex === 0 || loading}
                                onClick={() => handlePreDoc()}
                                title={t("Previous")}
                                className="navigation-btn"
                              >
                                <i class="las la-angle-right"></i>
                              </Button>
                            ) : null}
                            <div className="document-iframe-grid">
                              {loading ? (
                                <RotatingLines
                                  strokeColor="grey"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="48"
                                  visible={loading}
                                />
                              ) : scrDoc?.type === "pdf" ||
                                scrDoc?.type === "application/pdf" ? (
                                <iframe
                                  src={scrDoc?.view}
                                  width="100%"
                                  class="doc-view-iframe"
                                  title="Document preview section"
                                  srcDoc={
                                    !scrDoc
                                      ? `<p style="${styles}">${t(
                                          "Please click on document for preview here"
                                        )}</p>`
                                      : null
                                  }
                                />
                              ) : (
                                <iframe
                                  srcDoc={
                                    scrDoc && scrDoc?.view
                                      ? `<html><body><img  style="max-width: 100%; height: 100%; max-height: 97vh;  margin: 0px auto;display: block;" src=${scrDoc.view} alt="doc" /></body></html>`
                                      : `<p style="${styles}">${t(
                                          "Please click on document for preview here"
                                        )}</p>`
                                  }
                                  width="100%"
                                  class="doc-view-iframe"
                                  title="Document preview section"
                                />
                              )}
                            </div>
                            {scrDoc?.view ? (
                              <Button
                                disabled={
                                  props?.allPdfDocument.length - 1 ===
                                    docIndex || loading
                                }
                                onClick={() => handleNextDoc()}
                                title={t("Next")}
                                className="navigation-btn"
                              >
                                <i class="las la-angle-left"></i>
                              </Button>
                            ) : null}
                          </div>
                          {scrDoc?.view ? (
                            <div className="doc-count">
                              <h6>{`${docIndex + 1} מתוך ${
                                props?.allPdfDocument.length
                              }`}</h6>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )}
                </Rnd>
              </div>

              {/* ******** Embeded Dyte Section ********* */}
              {!props.checkIDF && (
                <div className="doc-with-left">
                  <Rnd
                    default={{
                      x: 0,
                      y: 0,
                      width: "31vw",
                      height: "88vh",
                    }}
                    minWidth={250}
                    minHeight={350}
                    bounds="window"
                    className="box-style"
                    cancel="button, textarea, select, li, span, input, .card, .modal_card, .modalContent"
                  >
                    <h1>{t("Video")} </h1>
                    {meetingStarted ? (
                      <DyteMeetingConatiner mId={props.meetingId} />
                    ) : (
                      <div className="start-meeting-grid">
                        <Button
                          onClick={handleStartMeeting}
                          disabled={!canStartMeeting}
                          className={`buttton_content`}
                        >
                          {t("Start meeting")}
                        </Button>
                      </div>
                    )}
                  </Rnd>
                </div>
              )}
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};

export default DocWithSummaryModel;
