import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

import { serverPath } from "../../config/key";
import axios from "axios";

const DownloadRxSummary = () => {
  const { t } = useTranslation();
  const [isloader, setIsLoader] = useState(false);
  const downloadRxSummary = async () => {
    setIsLoader(true);
    const headers = {
      "Content-Type": "Application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const config = {
      method: "GET",
      url: `${serverPath}/doctor/reports/getRxSummaryReport`,
      responseType: "arraybuffer",
      headers,
    };
    try {
      const response = await axios(config);
      const outputFilename = "RxSummary.xlsx";
      if (response?.data?.message) {
        setIsLoader(false);
        return toast.info(response?.data?.message);
      }
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = url;
      fileLink.setAttribute("download", outputFilename);
      fileLink.setAttribute("target", "_blank");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      toast.error("Some error occurd.Please try again");
      console.log("error==>", error);
      throw Error(error);
    }
  };

  return (
    <div className="summaries-btn">
      <Button onClick={downloadRxSummary}>
        {isloader ? (
          <Spinner animation="border" />
        ) : (
          t("Download all Rx summaries")
        )}
      </Button>
    </div>
  );
};
export default DownloadRxSummary;
