import React from "react";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import { Modal } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { useState } from "react";
import moment from "moment";
import { doctorOfferDate } from "../../config/service";
import { toast } from "react-toastify";
// import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const OfferDate = (props) => {
  const { t } = useTranslation();
  const [shiftdata, setShiftData] = useState();
  const [shiftdata1, setShiftData1] = useState();
  const [shifttime, setShifttime] = useState();
  const [shifttime1, setShifttime1] = useState();

  let uniqueArray = [];

  for (let i = 0; i < props.newOfferDate.length; i++) {
    let date = props.newOfferDate[i].split("T")[0];
    if (!uniqueArray.includes(date)) {
      uniqueArray.push(date);
    }
  }

  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  let nextDay = new Date(tomorrow);
  nextDay.setDate(tomorrow.getDate() + 1);
  console.log("shiftdata", shiftdata, shiftdata1);
  const shift = (d, i) => {
    if (d.length) {
      let shiftArray = [];
      d.map((el) => {
        let shifttime = "";
        if (["8", "9", "10", "11"].includes(el)) {
          shifttime = {
            start: 8,
            end: 11,
          };
        }
        if (["12", "13", "14", "15"].includes(el)) {
          shifttime = {
            start: 12,
            end: 15,
          };
        }
        if (["16", "17", "18", "19", "20", "21"].includes(el)) {
          shifttime = {
            start: 16,
            end: 21,
          };
        }
        shiftArray.push(shifttime);
      });
      const timeArray = [
        { value: "08:00", label: "08:00" },
        { value: "08:15", label: "08:15" },
        { value: "08:30", label: "08:30" },
        { value: "08:45", label: "08:45" },
        { value: "09:00", label: "09:00" },
        { value: "09:15", label: "09:15" },
        { value: "09:30", label: "09:30" },
        { value: "09:45", label: "09:45" },
        { value: "10:00", label: "10:00" },
        { value: "10:15", label: "10:15" },
        { value: "10:30", label: "10:30" },
        { value: "10:45", label: "10:45" },
        { value: "11:00", label: "11:00" },
        { value: "11:15", label: "11:15" },
        { value: "11:30", label: "11:30" },
        { value: "11:45", label: "11:45" },
        { value: "12:00", label: "12:00" },
        { value: "12:15", label: "12:15" },
        { value: "12:30", label: "12:30" },
        { value: "12:45", label: "12:45" },
        { value: "13:00", label: "13:00" },
        { value: "13:15", label: "13:15" },
        { value: "13:30", label: "13:30" },
        { value: "13:45", label: "13:45" },
        { value: "14:00", label: "14:00" },
        { value: "14:15", label: "14:15" },
        { value: "14:30", label: "14:30" },
        { value: "14:45", label: "14:45" },
        { value: "15:00", label: "15:00" },
        { value: "15:15", label: "15:15" },
        { value: "15:30", label: "15:30" },
        { value: "15:45", label: "15:45" },
        { value: "16:00", label: "16:00" },
        { value: "16:15", label: "16:15" },
        { value: "16:30", label: "16:30" },
        { value: "16:45", label: "16:45" },
        { value: "17:00", label: "17:00" },
        { value: "17:15", label: "17:15" },
        { value: "17:30", label: "17:30" },
        { value: "17:45", label: "17:45" },
        { value: "18:00", label: "18:00" },
        { value: "18:15", label: "18:15" },
        { value: "18:30", label: "18:30" },
        { value: "18:45", label: "18:45" },
        { value: "19:00", label: "19:00" },
        { value: "19:15", label: "19:15" },
        { value: "19:30", label: "19:30" },
        { value: "19:45", label: "19:45" },
        { value: "20:00", label: "20:00" },
        { value: "20:15", label: "20:15" },
        { value: "20:30", label: "20:30" },
        { value: "20:45", label: "20:45" },
        { value: "21:00", label: "21:00" },
        { value: "21:15", label: "21:15" },
        { value: "21:30", label: "21:30" },
      ];

      const filteredTimes = timeArray.filter((time) => {
        for (let i = 0; i < shiftArray.length; i++) {
          const startTime = `${shiftArray[i].start
            .toString()
            .padStart(2, "0")}:00`;
          const endTime = `${shiftArray[i].end.toString().padStart(2, "0")}:30`;
          if (time.value >= startTime && time.value <= endTime) {
            return true;
          }
        }
        return false;
      });
      setShifttime(filteredTimes);
      return shiftArray;
    } else {
      const timeArray = [
        { value: "08:00", label: "08:00" },
        { value: "08:15", label: "08:15" },
        { value: "08:30", label: "08:30" },
        { value: "08:45", label: "08:45" },
        { value: "09:00", label: "09:00" },
        { value: "09:15", label: "09:15" },
        { value: "09:30", label: "09:30" },
        { value: "09:45", label: "09:45" },
        { value: "10:00", label: "10:00" },
        { value: "10:15", label: "10:15" },
        { value: "10:30", label: "10:30" },
        { value: "10:45", label: "10:45" },
        { value: "11:00", label: "11:00" },
        { value: "11:15", label: "11:15" },
        { value: "11:30", label: "11:30" },
        { value: "11:45", label: "11:45" },
        { value: "12:00", label: "12:00" },
        { value: "12:15", label: "12:15" },
        { value: "12:30", label: "12:30" },
        { value: "12:45", label: "12:45" },
        { value: "13:00", label: "13:00" },
        { value: "13:15", label: "13:15" },
        { value: "13:30", label: "13:30" },
        { value: "13:45", label: "13:45" },
        { value: "14:00", label: "14:00" },
        { value: "14:15", label: "14:15" },
        { value: "14:30", label: "14:30" },
        { value: "14:45", label: "14:45" },
        { value: "15:00", label: "15:00" },
        { value: "15:15", label: "15:15" },
        { value: "15:30", label: "15:30" },
        { value: "15:45", label: "15:45" },
        { value: "16:00", label: "16:00" },
        { value: "16:15", label: "16:15" },
        { value: "16:30", label: "16:30" },
        { value: "16:45", label: "16:45" },
        { value: "17:00", label: "17:00" },
        { value: "17:15", label: "17:15" },
        { value: "17:30", label: "17:30" },
        { value: "17:45", label: "17:45" },
        { value: "18:00", label: "18:00" },
        { value: "18:15", label: "18:15" },
        { value: "18:30", label: "18:30" },
        { value: "18:45", label: "18:45" },
        { value: "19:00", label: "19:00" },
        { value: "19:15", label: "19:15" },
        { value: "19:30", label: "19:30" },
        { value: "19:45", label: "19:45" },
        { value: "20:00", label: "20:00" },
        { value: "20:15", label: "20:15" },
        { value: "20:30", label: "20:30" },
        { value: "20:45", label: "20:45" },
        { value: "21:00", label: "21:00" },
        { value: "21:15", label: "21:15" },
        { value: "21:30", label: "21:30" },
      ];
      if (i == moment(today).format("YYYY-MM-DD")) {
        const currentTime = new Date().toLocaleTimeString([], {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        });
        const timeParts = currentTime.split(":");
        const currentHours = parseInt(timeParts[0]);
        const currentMinutes = parseInt(timeParts[1]);

        const updatedTime = new Date();
        updatedTime.setHours(currentHours, currentMinutes + 15);

        const updatedTimeString = updatedTime.toLocaleTimeString([], {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        });
        const currentIndex = timeArray.findIndex(
          (time) => time.value > updatedTimeString
        );
        const newArray =
          currentIndex !== -1 ? timeArray.slice(currentIndex) : [];
        setShifttime(newArray);
        return newArray;
      } else {
        setShifttime(timeArray);
        return timeArray;
      }
    }
  };
  const shift1 = (d, i) => {
    if (d.length) {
      let shiftArray = [];
      d.map((el) => {
        let shifttime = "";
        if (["8", "9", "10", "11"].includes(el)) {
          shifttime = {
            start: 8,
            end: 11,
          };
        }
        if (["12", "13", "14", "15"].includes(el)) {
          shifttime = {
            start: 12,
            end: 15,
          };
        }
        if (["16", "17", "18", "19", "20", "21"].includes(el)) {
          shifttime = {
            start: 16,
            end: 21,
          };
        }
        shiftArray.push(shifttime);
      });
      const timeArray = [
        { value: "08:00", label: "08:00" },
        { value: "08:15", label: "08:15" },
        { value: "08:30", label: "08:30" },
        { value: "08:45", label: "08:45" },
        { value: "09:00", label: "09:00" },
        { value: "09:15", label: "09:15" },
        { value: "09:30", label: "09:30" },
        { value: "09:45", label: "09:45" },
        { value: "10:00", label: "10:00" },
        { value: "10:15", label: "10:15" },
        { value: "10:30", label: "10:30" },
        { value: "10:45", label: "10:45" },
        { value: "11:00", label: "11:00" },
        { value: "11:15", label: "11:15" },
        { value: "11:30", label: "11:30" },
        { value: "11:45", label: "11:45" },
        { value: "12:00", label: "12:00" },
        { value: "12:15", label: "12:15" },
        { value: "12:30", label: "12:30" },
        { value: "12:45", label: "12:45" },
        { value: "13:00", label: "13:00" },
        { value: "13:15", label: "13:15" },
        { value: "13:30", label: "13:30" },
        { value: "13:45", label: "13:45" },
        { value: "14:00", label: "14:00" },
        { value: "14:15", label: "14:15" },
        { value: "14:30", label: "14:30" },
        { value: "14:45", label: "14:45" },
        { value: "15:00", label: "15:00" },
        { value: "15:15", label: "15:15" },
        { value: "15:30", label: "15:30" },
        { value: "15:45", label: "15:45" },
        { value: "16:00", label: "16:00" },
        { value: "16:15", label: "16:15" },
        { value: "16:30", label: "16:30" },
        { value: "16:45", label: "16:45" },
        { value: "17:00", label: "17:00" },
        { value: "17:15", label: "17:15" },
        { value: "17:30", label: "17:30" },
        { value: "17:45", label: "17:45" },
        { value: "18:00", label: "18:00" },
        { value: "18:15", label: "18:15" },
        { value: "18:30", label: "18:30" },
        { value: "18:45", label: "18:45" },
        { value: "19:00", label: "19:00" },
        { value: "19:15", label: "19:15" },
        { value: "19:30", label: "19:30" },
        { value: "19:45", label: "19:45" },
        { value: "20:00", label: "20:00" },
        { value: "20:15", label: "20:15" },
        { value: "20:30", label: "20:30" },
        { value: "20:45", label: "20:45" },
        { value: "21:00", label: "21:00" },
        { value: "21:15", label: "21:15" },
        { value: "21:30", label: "21:30" },
      ];

      const filteredTimes = timeArray.filter((time) => {
        for (let i = 0; i < shiftArray.length; i++) {
          const startTime = `${shiftArray[i].start
            .toString()
            .padStart(2, "0")}:00`;
          const endTime = `${shiftArray[i].end.toString().padStart(2, "0")}:30`;
          if (time.value >= startTime && time.value <= endTime) {
            return true;
          }
        }
        return false;
      });
      setShifttime1(filteredTimes);
      return shiftArray;
    } else {
      const timeArray = [
        { value: "08:00", label: "08:00" },
        { value: "08:15", label: "08:15" },
        { value: "08:30", label: "08:30" },
        { value: "08:45", label: "08:45" },
        { value: "09:00", label: "09:00" },
        { value: "09:15", label: "09:15" },
        { value: "09:30", label: "09:30" },
        { value: "09:45", label: "09:45" },
        { value: "10:00", label: "10:00" },
        { value: "10:15", label: "10:15" },
        { value: "10:30", label: "10:30" },
        { value: "10:45", label: "10:45" },
        { value: "11:00", label: "11:00" },
        { value: "11:15", label: "11:15" },
        { value: "11:30", label: "11:30" },
        { value: "11:45", label: "11:45" },
        { value: "12:00", label: "12:00" },
        { value: "12:15", label: "12:15" },
        { value: "12:30", label: "12:30" },
        { value: "12:45", label: "12:45" },
        { value: "13:00", label: "13:00" },
        { value: "13:15", label: "13:15" },
        { value: "13:30", label: "13:30" },
        { value: "13:45", label: "13:45" },
        { value: "14:00", label: "14:00" },
        { value: "14:15", label: "14:15" },
        { value: "14:30", label: "14:30" },
        { value: "14:45", label: "14:45" },
        { value: "15:00", label: "15:00" },
        { value: "15:15", label: "15:15" },
        { value: "15:30", label: "15:30" },
        { value: "15:45", label: "15:45" },
        { value: "16:00", label: "16:00" },
        { value: "16:15", label: "16:15" },
        { value: "16:30", label: "16:30" },
        { value: "16:45", label: "16:45" },
        { value: "17:00", label: "17:00" },
        { value: "17:15", label: "17:15" },
        { value: "17:30", label: "17:30" },
        { value: "17:45", label: "17:45" },
        { value: "18:00", label: "18:00" },
        { value: "18:15", label: "18:15" },
        { value: "18:30", label: "18:30" },
        { value: "18:45", label: "18:45" },
        { value: "19:00", label: "19:00" },
        { value: "19:15", label: "19:15" },
        { value: "19:30", label: "19:30" },
        { value: "19:45", label: "19:45" },
        { value: "20:00", label: "20:00" },
        { value: "20:15", label: "20:15" },
        { value: "20:30", label: "20:30" },
        { value: "20:45", label: "20:45" },
        { value: "21:00", label: "21:00" },
        { value: "21:15", label: "21:15" },
        { value: "21:30", label: "21:30" },
      ];
      if (i == moment(today).format("YYYY-MM-DD")) {
        const currentTime = new Date().toLocaleTimeString([], {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        });

        const timeParts = currentTime.split(":");
        const currentHours = parseInt(timeParts[0]);
        const currentMinutes = parseInt(timeParts[1]);

        const updatedTime = new Date();
        updatedTime.setHours(currentHours, currentMinutes + 15);

        const updatedTimeString = updatedTime.toLocaleTimeString([], {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        });
        const currentIndex = timeArray.findIndex(
          (time) => time.value > updatedTimeString
        );
        const newArray =
          currentIndex !== -1 ? timeArray.slice(currentIndex) : [];
        setShifttime1(newArray);
        return newArray;
      } else {
        setShifttime1(timeArray);
        return timeArray;
      }
    }
  };
  const Offer = async () => {
    if (props.fdate && props.sdate) {
      const DateTime = [
        moment(`${props.fdate} ${props.ftime}:00`).toDate(),
        moment(`${props.sdate} ${props.stime}:00`).toDate(),
      ];
      const data = {
        id: props.id,
        offeredDates: DateTime,
      };
      const response = await doctorOfferDate(data);

      response.data.message
        ? toast.success(t(response.data.message))
        : toast.success(t(response.data.msg));
      props.handleClose();
    } else {
      toast.error(t("Please select two dates"));
    }
  };

  const handletime = (date) => {
    props.setFTime(date.value);
  };

  const handletime1 = (date) => {
    props.setSTime(date.value);
  };

  const handlefulltime = (i) => {
    let filter = props.newOfferDate.filter((item) => item.startsWith(i));
    let array = filter.map((el) => moment(el).format("H"));
    setShiftData(shift(array, i));
  };

  const handlefulltime1 = (i) => {
    let filter = props.newOfferDate.filter((item) => item.startsWith(i));
    let array = filter.map((el) => moment(el).format("H"));
    setShiftData1(shift1(array, i));
  };

  return (
    <div>
      <Modal
        open={props.offerModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card" style={{ minHeight: "500px" }}>
          <MDBCardBody>
            <h1>{t("Please choose/provide a date for consult")}</h1>
            <div className="dateSelect time-grid row">
              <select
                className="col-6 inputDiv"
                onChange={(e) => (
                  props.setFDate(moment(e.target.value).format("YYYY-MM-DD")),
                  handlefulltime(e.target.value)
                )}
              >
                <option value="">{t("Select Date")}</option>

                {uniqueArray?.length != 0 ? (
                  uniqueArray?.map((d, i) => {
                    return (
                      <option value={d} key={i}>
                        {moment(d).format("DD-MM-YYYY")}
                      </option>
                    );
                  })
                ) : (
                  <>
                    <option value={moment(today).format("YYYY-MM-DD")}>
                      {moment(today).format("DD-MM-YYYY")}
                    </option>
                    <option value={moment(tomorrow).format("YYYY-MM-DD")}>
                      {moment(tomorrow).format("DD-MM-YYYY")}
                    </option>
                    <option value={moment(nextDay).format("YYYY-MM-DD")}>
                      {moment(nextDay).format("DD-MM-YYYY")}
                    </option>
                  </>
                )}
              </select>
              <Select
                onChange={(date) => {
                  props.setStartDate(date), handletime(date);
                }}
                isDisabled={props.fdate ? false : true}
                isSearchable={false}
                options={shifttime}
                placeholder={t("Time")}
                DropdownIndicator={null}
                className="col-5  select-time time-react-select-container"
                classNamePrefix="time-react-select"
              />
            </div>
            <div className="dateSelect time-grid row">
              <select
                className="col-6 inputDiv"
                onChange={(e) => (
                  props.setSDate(moment(e.target.value).format("YYYY-MM-DD")),
                  handlefulltime1(e.target.value)
                )}
              >
                <option value="">{t("Select Date")}</option>

                {uniqueArray?.length != 0 ? (
                  uniqueArray?.map((d, i) => {
                    return (
                      <option value={d} key={i}>
                        {moment(d).format("DD-MM-YYYY")}
                      </option>
                    );
                  })
                ) : (
                  <>
                    <option value={moment(today).format("YYYY-MM-DD")}>
                      {moment(today).format("DD-MM-YYYY")}
                    </option>
                    <option value={moment(tomorrow).format("YYYY-MM-DD")}>
                      {moment(tomorrow).format("DD-MM-YYYY")}
                    </option>
                    <option value={moment(nextDay).format("YYYY-MM-DD")}>
                      {moment(nextDay).format("DD-MM-YYYY")}
                    </option>
                  </>
                )}
              </select>
              <Select
                className="col-5  select-time time-react-select-container"
                classNamePrefix="time-react-select"
                onChange={(date) => {
                  props.setStartDate1(date), handletime1(date);
                }}
                isDisabled={props.sdate ? false : true}
                isSearchable={false}
                options={shifttime1}
                DropdownIndicator={false}
                placeholder={t("Time")}
              />
            </div>
            <MDBCardText>
              <Button
                variant="primary"
                size="lg"
                className="buttton_content"
                onClick={Offer}
                disabled={!props.ftime || !props.stime}
              >
                {t("Offer Date")}
              </Button>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default OfferDate;
