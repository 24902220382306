import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./common.css";

const MadnessAlertModal = ({
  madnessAlert,
  setMadnessAlert,
  handleMadnessAlertModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={madnessAlert}
      onHide={() => {
        setMadnessAlert(false);
      }}
      className="modal-alert"
      size="xl"
      backdrop="static"
    >
      <Modal.Body>
        <div className="modal-alert-content">
          <h6>
            {t(
              "Please note that at the end of the consultation, you will be sent a link to fill out the details of the claim"
            )}
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="border-btn" onClick={handleMadnessAlertModal}>
          {t("Continue")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MadnessAlertModal;
