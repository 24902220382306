import React, { useState } from "react";
import { useLocation } from 'react-router-dom'
import "./login.css"
import OtpInput from "react-otp-input";
import { Grid, Paper, Typography, Button, Modal } from "@material-ui/core";







const LoginOTP = () => {
    const { state } = useLocation();
    const [otp, setOtp] = useState("")


   
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    console.log("state", state)


    return (
        <div className="newRegd">
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Grid container direction="column" alignItems="center">
                    <Grid item xs>
                        <Paper className="paper" square>
                            <Grid container direction="column" alignItems="center">
                                <Grid item lg>
                                    <Typography variant="h4" gutterBottom style={{
                                        padding: "12px",
                                        fontWeight: "bold",
                                        color: "gray"
                                    }}>
                                        Please Enter the OTP to Verify Your Account
                                    </Typography>
                                </Grid>
                                <Grid item sm>
                                    <Typography variant="h6" gutterBottom>
                                        An OTP(one time password) has been sent to 767990**77
                                    </Typography>
                                </Grid>

                                <Grid item xs>
                                    <OtpInput
                                        value={otp}
                                        onChange={(otp) => {
                                            setOtp(otp);
                                        }}
                                        placeholder="X"
                                        numInputs={6}
                                        inputStyle={{
                                            fontSize: "24px",
                                            width: "36px",
                                            height: "36px",
                                            margin: "4px",
                                            borderTop: "0px",
                                            borderLeft: "0px",
                                            borderRight: "0px",
                                            outline: "none",
                                            borderColor: "#000a46"
                                        }}
                                        containerStyle={{
                                            margin: "20px auto",
                                            padding: "10px"
                                        }}
                                        isInputNum
                                    />
                                </Grid>
                                <Grid container direction="column" alignItems="center" >
                                    <Grid item xs >
                                        <Button variant="caption" display="block" gutterBottom style={{background:"#18457d"}}>
                                            Resend OTP
                                        </Button>
                                        <Button>
                                            Validate OTP
                                        </Button>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </div>

    )
}
export default LoginOTP;

