import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./queues.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-quill/dist/quill.snow.css";
import PatientDashboard from "../components/Dashborad/PatientDashboard";
import DoctorDashboard from "../components/DoctorDashboard/DoctorDashboard";

const Queues = (props) => {
  return (
    <div className="queuesContainer">
      {localStorage.getItem("role") ? (
        localStorage.getItem("role") !== "doctor" ? (
          <>
            <PatientDashboard />
          </>
        ) : (
          <DoctorDashboard subscriptionsList={props.subscriptionsList} />
        )
      ) : null}
    </div>
  );
};
export default Queues;
