import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const DeleteTemplateModal = ({
  deleteTempModal,
  handleDeleteTemplate,
  handleCloseDeleteTemplate,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={deleteTempModal}
      onHide={() => handleCloseDeleteTemplate()}
      className="prescription-alert delete-template-modal"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <h6>{t("Are you sure?")} </h6>
      </Modal.Header>
      <Modal.Body>
        <div className="yes-no-grid" style={{ paddingTop: "25px" }}>
          <Button
            size="sm"
            className="border-btn"
            onClick={() => handleCloseDeleteTemplate()}
          >
            {" "}
            {t("No")}
          </Button>
          <Button
            size="sm"
            className="border-btn"
            onClick={handleDeleteTemplate}
          >
            {" "}
            {t("Yes")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteTemplateModal;
