import React, { useState } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import Layout from "../layout/layout";
import ReCAPTCHA from "react-google-recaptcha";
import "./contact.css";

const Contact = () => {
  const [successmsg, setSuccessMsg] = useState(false);
  const [errormsg, setErrorMsg] = useState(false);
  const [formValue, setformValue] = React.useState({
    email: "",
    name: "",
    phoneNumber: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // store the states in the form data
    let payload = {
      ...formValue,
    };

    try {
      // make axios post request
      const response = await axios.post(
        "http://localhost:3000/forms/contact",

        payload
      );

      if (response.status) {
        setSuccessMsg(true);
      }
    } catch (error) {
      console.log(error);
      setErrorMsg(true);
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const recaptchaRef = React.createRef();

  return (
    <Layout>
      <div className="contact-page">
        <div className="container">
          <div className="contact-grid">
            <div className="contact-form">
              <div className="contact-form-content">
                <h2>צור קשר</h2>
                <p>אנחנו כאן בשביל לעזור.</p>
                <p>מוזמנים להשאיר הודעה ונחזור אליכם בהקדם האפשרי :-)</p>

                <Form onSubmit={handleSubmit}>
                  <Form.Group className="firstname-form">
                    <Form.Label>שם פרטי *</Form.Label>
                    <Form.Control
                      name="firstname"
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="lastname-form">
                    <Form.Label>שם משפחה *</Form.Label>
                    <Form.Control
                      name="lastname"
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>אימייל *</Form.Label>
                    <Form.Control
                      name="email"
                      type="email"
                      value={formValue.phoneNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>טלפון</Form.Label>
                    <Form.Control
                      name="phoneNumber"
                      type="tel"
                      value={formValue.phoneNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 form-group"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>תוכן ההודעה *</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </Form>
                <div className="google-recptcha">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LdVdkQlAAAAACmcnomBpNHmuCazz-OIhchp6B32"
                  />
                </div>
                <div className="send-btn">
                  <Button variant="primary" type="submit">
                    שליחה
                  </Button>
                </div>

                {successmsg && (
                  <div className="success-msg">Form submitted successFully</div>
                )}

                {errormsg && (
                  <div className="error-msg">
                    Please fill all the fields correctly
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
