import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { uploadMyDoctorFile } from "../../../config/service";

const MyDoctorUpload = ({
  handleMyDoctorUpload,
  myDoctorModal,
  setMyDoctorModal,
}) => {
  const { t } = useTranslation();

  const lang = localStorage.getItem("lang");

  const [file, setFile] = useState(null);

  const handleFileChange = async (e) => {
    setFile(e.target.files[0]);
  };

  const validation = () => {
    let isValid = true;
    if (!file) {
      toast.error(t("Please upload a file"), { toastId: "file" });
      return (isValid = false);
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.preventDefault();
    if (!validation()) {
      return;
    }
    const formData = new FormData();

    formData.append("userFile", file);

    try {
      const response = await uploadMyDoctorFile(formData);
      const { status, message } = response.data;
      if (status === 200) {
        toast.success(message, { toastId: "success" });
        setMyDoctorModal(false);
      }
      if (status === 404) {
        toast.error(message, { toastId: "not found" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        open={myDoctorModal}
        onClose={handleMyDoctorUpload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card collective_content">
          <MDBCardBody>
            <h1 style={{ marginBottom: "40px" }}>{t("myDoctor Upload")}</h1>

            <Form
              className={`idf-upload-form ${lang === "HE" ? "rtl-css" : ""}`}
              dir={lang === "HE" ? "rtl" : "ltr"}
              onSubmit={handleSubmit}
            >
              <Form.Group className="form-group w-100">
                <Form.Label>{t("Upload File")}</Form.Label>
                <Form.Control
                  className="form-control"
                  type="file"
                  placeholder={t("Upload your file")}
                  id="uploadFile"
                  autoComplete="off"
                  accept=".xlsx"
                  onChange={handleFileChange}
                />
              </Form.Group>

              <div className="submit-btn-grid">
                <Button className="download_report" type="submit">
                  {t("Submit")}
                </Button>
              </div>
            </Form>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default MyDoctorUpload;
