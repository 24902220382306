import React, { useState, useEffect } from "react";

import { Form, Button, FormControl } from "react-bootstrap";
import "animate.css";
import { CloseIconSVG, SearchIconSVG } from "../header/HeaderIcon";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import NewFooter from "../footer/newFooter";
const Layout = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [show, setshow] = useState(true);
  const [seachpopup, setSeachPopup] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (location.pathname == "/thankyou") {
      setshow(false);
    }
  }, [location]);

  const handleSearch = () => {
    setSeachPopup(!seachpopup);
  };

  return (
    <div className={`main-layout rtl-layout`}>
      {/* <Header seachpopup={seachpopup} handleSearch={handleSearch} scrollToElement={scrollToElement} scrollToElementVisa={scrollToElementVisa} /> */}

      <div className="child-content">{children}</div>
      {show ? <NewFooter /> : null}

      {/* {seachpopup ?  */}
      <div className={`searc-container ${seachpopup ? "" : "close-search"}`}>
        <div className="search-content">
          <div
            className="search-close-btn"
            onClick={handleSearch}
            role="presentation"
          >
            {CloseIconSVG}
          </div>
          <Form>
            <FormControl
              type="search"
              placeholder={t("Type Word then Press Enter")}
              aria-label="Search"
            />
            <Button>{SearchIconSVG}</Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Layout;
