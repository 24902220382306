import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./registration.css";
// import crs from "../../assets/images/cross/crs.png"
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { Grid, Paper, Typography, Button, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import Footer from "../footer/footer";
import { userInstance } from "../../config/axios";
import { useSearchParams } from "react-router-dom";
import { allConsultation } from "../../config/service";
import jwt_decode from "jwt-decode";

const Registration = () => {
  let [setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  const [formValue, setformValue] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    id_number: "",
    mobile: "",
    title: "",
    Doctor_Id: "",
  });
  const [emailError, setEmailError] = useState("");
  const [fnameError, setfnameError] = useState("");
  const [lnameError, setlnameError] = useState("");
  const [idError, setIdError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [otp, setOtp] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [consultData, setConsultData] = useState([]);
  console.log(consultData);

  useEffect(() => {
    consultingFields();
  }, []);
  const consultingFields = async () => {
    console.log("localhost");
    try {
      const response = await allConsultation();

      console.log("consulting fields response", response);
      setConsultData(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // store the states in the form data
    let data = {
      email: formValue.email,
      phone: formValue.mobile,
      id: formValue.id_number,
      first_name: formValue.first_name,
      last_name: formValue.last_name,
      refferalId: localStorage.getItem("refferalId"),
      UTM: localStorage.getItem("utm"),
      collective: localStorage.getItem("collective"),
    };
    if (validate()) {
      try {
        // make axios post request
        const response = await userInstance().post(
          "/users/signupuser",

          data
        );

        console.log("Form Data", response);
        if ([200, 201, 202, 203].includes(response.data.code)) {
          toast.success(t("An OTP sent to your mobile number"));
          setOpen(true);
          // setSuccessMsg(true);
        } else {
          toast.error(t(response.data.msg));
        }
      } catch (error) {
        console.log(error);
        //   setErrorMsg(true);
      }
    }
  };

  const OtpValidation = async (e) => {
    e.preventDefault();
    // store the states in the form data
    let data = {
      phone: formValue.mobile,
      otp: otp,
      role: "client",
      id: formValue.id_number,
    };

    if (validate()) {
      console.log(" otp data", data);
      try {
        // make axios post request
        const response = await userInstance().post("/users/verify", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("OTP Data", response);
        if (response.data.token) {
          toast.success(t(response.data.msg));
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          const decodedToken = jwt_decode(response.data.token);
          localStorage.setItem("userName", decodedToken.name);
          localStorage.setItem("userId", decodedToken.id);
          localStorage.setItem("role", decodedToken.role);
          navigate("/Queues");
          // setSuccessMsg(true);
        } else {
          toast.error(t(response.data.msg));
        }
      } catch (error) {
        console.log(error);
        //   setErrorMsg(true);
      }
    }
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const validate = () => {
    let isValid = true;

    if (!formValue.first_name) {
      isValid = false;
      setfnameError(t("Please enter first name"));
    } else {
      setfnameError("");
    }
    if (!formValue.last_name) {
      isValid = false;
      setlnameError(t("Please enter last name"));
    } else {
      setlnameError("");
    }

    if (!formValue.id_number) {
      isValid = false;
      setIdError(t("Please Enter Id Number"));
    } else {
      setIdError("");
    }
    if (!formValue.email) {
      isValid = false;

      setEmailError(t("Please enter your email Address."));
    }

    if (typeof formValue.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(formValue.email)) {
        isValid = false;

        setEmailError(t("Please enter valid email"));
      }
    }
    if (!formValue.mobile) {
      isValid = false;

      setmobileError(t("Please enter your phone number."));
    }

    if (typeof formValue.mobile !== "undefined") {
      var pattern1 = new RegExp(/^[0-9\b]+$/);

      if (!pattern1.test(formValue.mobile)) {
        isValid = false;

        setmobileError(t("Please enter only number"));
      } else if (formValue.mobile.length != 10) {
        isValid = false;

        setmobileError(t("Please enter valid phone number"));
      }
    }

    return isValid;
  };
  console.log(setSearchParams);

  return (
    <div>
      <div className="newRegd" style={{ marginTop: "9%" }}>
        <div className="signTop">
          {/* <img src={crs} alt="" onClick={() => navigate("/")} /> */}
          <h2>{t("Enrollment")}</h2>
        </div>
        <form style={{ textAlign: "center" }}>
          <input
            type="text"
            id="first_name"
            placeholder={t("First Name")}
            name="first_name"
            onChange={handleChange}
          />
          <span style={{ color: "red", textAlign: "center" }}>
            {t(`${fnameError}`)}
          </span>
          <input
            type="text"
            id="last_name"
            placeholder={t("Last Name")}
            name="last_name"
            onChange={handleChange}
          />
          <span style={{ color: "red", textAlign: "center" }}>
            {t(`${lnameError}`)}
          </span>
          <input
            type="text"
            id="mobile"
            placeholder={t("Mobile Number")}
            name="mobile"
            onChange={handleChange}
          />
          <span style={{ color: "red", textAlign: "center" }}>
            {t(`${mobileError}`)}
          </span>
          <input
            type="text"
            id="email"
            placeholder={t("Enter Email")}
            name="email"
            onChange={handleChange}
          />
          <span style={{ color: "red", textAlign: "center" }}>
            {t(`${emailError}`)}
          </span>
          <input
            type="text"
            id="id_number"
            placeholder={t("ID Number")}
            name="id_number"
            onChange={handleChange}
          />
          <span style={{ color: "red", textAlign: "center" }}>
            {t(`${idError}`)}
          </span>
          <div className="google-recptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdVdkQlAAAAACmcnomBpNHmuCazz-OIhchp6B32"
            />
          </div>
          <button type="submit" onClick={handleSubmit}>
            {t("Register")}
          </button>
        </form>
        <div className="hlink" onClick={() => navigate("/login")}>
          <a href="">{t("I am already a registered user")}</a>
        </div>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modalDiv"
          >
            <Grid container direction="column" alignItems="center">
              <Grid item xs>
                <Paper className="paper" square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item lg>
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                          padding: "12px",
                          fontWeight: "bold",
                          color: "gray",
                        }}
                      >
                        {t("Please Enter the OTP to Verify Your Account")}
                      </Typography>
                    </Grid>
                    <Grid item sm>
                      <Typography variant="h6" gutterBottom>
                        <span>{formValue.mobile} </span>
                        {t("An OTP(one time password) has been sent to")}
                      </Typography>
                    </Grid>

                    <Grid item xs>
                      <OtpInput
                        value={otp}
                        onChange={(otp) => {
                          setOtp(otp);
                        }}
                        numInputs={6}
                        inputStyle={{
                          fontSize: "24px",
                          width: "36px",
                          height: "36px",
                          margin: "4px",
                          borderTop: "0px",
                          borderLeft: "0px",
                          borderRight: "0px",
                          outline: "none",
                          borderColor: "#000a46",
                        }}
                        containerStyle={{
                          margin: "20px auto",
                          padding: "10px",
                        }}
                        isInputNum
                      />
                    </Grid>
                    <Grid container direction="column" alignItems="center">
                      <Grid
                        item
                        xs
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px",
                        }}
                      >
                        <Button
                          onClick={OtpValidation}
                          style={{
                            background: "#e12c4b",
                            color: "white",
                            padding: "10px 45px 10px 45px",
                            borderRadius: "10px",
                          }}
                        >
                          {t("Validate OTP")}
                        </Button>
                        {/* <Button variant="caption" display="block" gutterBottom
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "gray",
                                                            marginTop: "10px"

                                                        }}>
                                                    {t("Resend OTP")}
                                                </Button> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Modal>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Registration;
