import React from "react";

const Terms = () => {
  return (
    <>
      <div className="mainConsent">
        <div className="mainConsentItems">
          <h1 className="header_text">
            <strong>Hidoc</strong>
          </h1>
          <h1 className="header_text">
            <strong>תנאי שימוש כלליים</strong>
          </h1>

          <div className="consent_text">
            <p>עדכון אחרון: 21 לאפריל, 2021</p>
            <p>
              אנו שמחים שבחרת לעשות שימוש בפלטפורמה של Hidoc! פלטפורמה המיועדת
              לקבלת שירותים דיגיטליים ממטפלים לצורך אבחון וקבלת הנחיות לטיפול,
              באופן מקוון נגיש ונוח, בבית המשתמש, באמצעות שיחת וידאו או אמצעי
              תקשורת אחרים שהחברה תאפשר.<br></br>
              הצפייה והשימוש באתר, כהגדרתו להלן, ובשירותים המוצעים באמצעותו
              (שירותי החברה והשירותים הטיפוליים) במהותם), כפופים לתנאי שימוש
              אלו, אשר מהווים את הבסיס המשפטי לזכויות וחובות שלך, של יתר
              המשתמשים ושל החברה. <br></br>
              החברה מקדישה חשיבות מיוחדת לשמירה על פרטיות המשתמשים ולהגנה על
              המידע בקשר אליהם, אשר חלקו רפואי ומבהירה כי השימוש במידע שתמסור
              לחברה או למטפלים במסגרת פעילותך בפלטפורמה יהא כפוף למדיניות
              הפרטיות של החברה הזמינה באתר [
              <a href="https://hidoc.co.il/privacy">
                https://hidoc.co.il/privacy
              </a>{" "}
              ]. לכן, חשוב שתעיין בתשומת לב בתנאי השימוש ובמדיניות הפרטיות ,
              לפני תחילת השימוש באתר וצריכת השירותים והשירותים הטיפוליים וכן
              שתתעדכן מעת לעת על כל שינוי בהם. אם אתה לא מסכים לתנאי כלשהו מתנאי
              השימוש או מדיניות הפרטיות אל תשתמש באתר או בשירותי החברה.<br></br>
              תנאי השימוש כתובים בלשון זכר מטעמי נוחות בלבד אך מתייחסים לשני
              המינים.
              <br></br>
              <p></p>
              1.{" "}
              <u>
                <strong>הגדרות</strong>
              </u>{" "}
              <br></br>
              בתנאי השימוש יהיו למונחים שלהלן המשמעות המפורטת בצידם:<br></br>
              1.1 <strong>"אמצעי קצה"</strong>: האמצעים של כל אחד מבין המשתמש
              והמטפל (ולא של החברה), הנדרשים לצורך ביצוע פגישה בין מטפל למשתמש
              באמצעות הפלטפורמה. למשל, מחשב או טלפון נייד חכם, מצלמת וידאו,
              מיקרופון ורמקול או אזניות.<br></br>
              1.2 <strong>"האתר"</strong>: המונח כולל את אתר האינטרנט הזמין
              בכתובת <a href="http://www.hidoc.co.il">www.hidoc.co.il</a> ,
              המופעל והמנוהל על ידי החברה, על כל חלקיו ורכיביו, הפלטפורמה וכן כל
              יישומון (אפליקציה) למכשירים ניידים, שהחברה מציעה בחניות היישומונים
              Apple Store או Google Play וכל אמצעי תקשורת עם החברה שהחברה מספקת.
              השימוש במונח זה כולל גם את התכנים הנכללים או המוצגים מעת לעת באתר
              וכן כל תוכנה או קוד הכלולים בו או משמשים להפעלתו ולגישה אליו.
              <br></br>
              1.3 <strong>"החברה"</strong>: ואלור מוצרים חכמים בע"מ וכן כל גורם
              קשור הפועל מטעמה, בקשר לשירותים למעט המטפלים.<br></br>
              1.4 "הפלטפורמה": מערכת דיגיטלית מקוונת הזמינה באמצעות האתר ומיועדת
              לחיפוש ומציאת מטפלים ומפגש וירטואלי עימם בשיחת וידאו או באמצעים
              שתאפשר החברה, כך שמשתמשים רשומים יוכלו לקבל שירותים טיפוליים
              באמצעותה מהמטפלים וכן שירותים נלווים שונים.<br></br>
              1.5 <strong>"מטפל"</strong>: מי שמספק טיפול למשתמש ואשר הקשר עימו
              נוצר באמצעות הפלטפורמה, בין אם הוא פועל כעוסק מורשה, בין אם הוא
              מאוגד ופועל כתאגיד ו/או פועל בכל יישות אחרת, אשר התקשר עם החברה
              לצורך הצעת שירותים טיפוליים בפלטפורמה, וכל גורם הפועל מטעמו.{" "}
              <br></br>
              1.6 <strong>"משתמש"</strong>: אתה, בעת שאתה גולש, צופה ו/או עושה
              כל שימוש שהוא באתר. משתמש שסיים בהצלחה הליך הרשמה באתר וקיבל שם
              משתמש וסיסמא הינו "משתמש רשום".<br></br>
              1.7 <strong>"שירות טיפולי"</strong>: כל שירות המסופק על ידי מטפל
              באמצעות הפלטפורמה, המיועד לאיבחון, למניעת החמרה או לשיפור מצבו
              הגופני ו/או הנפשי של משתמש רשום, לרבות הנחיות לטיפול.<br></br>
              1.8 <strong>"שירות"</strong>: השירות הניתן על ידי החברה. דהיינו
              העמדת הפלטפורמה והאתר לשימוש המשתמשים לרבות יצירת האפשרות לקבלת
              שירותים טיפוליים מהמטפלים וכל אחד מבין השירותים שהחברה מספקת
              ותספק, מעת לעת, באמצעות האתר.
              <br></br>
              1.9 <strong>"תנאי השימוש"</strong>: התנאים המפורטים במסמך תנאי
              השימוש ובמדיניות הפרטיות [
              <a href="https://hidoc.co.il/privacy]">
                https://hidoc.co.il/privacy]
              </a>
              , המופיעים באתר, כפי שיעודכנו מעת לעת.<br></br>
              1.10 <strong>"תכנים"</strong>: מידע מכל מין וסוג, לרבות כל תוכן
              מילולי, חזותי, קולי, אור-קולי, או כל שילוב שלהם, בכתב, בתמונות,
              בקבצים, קישורים או אחר.<br></br>
              <p></p>
              2.{" "}
              <u>
                <strong>הבהרות חשובות</strong>
              </u>{" "}
              <br></br>
              2.1 השימוש באתר, בשירותים ובשירותים הטיפוליים יעשה על דעתך ועל פי
              החלטתך בלבד בכפוף לתנאי השימוש. קבלת השירותים הטיפוליים תותנה בכך
              שתיתן{" "}
              <strong>
                הסכמה מדעת לקבלת השירות הטיפולי בנוסח שיוצג לאישורך
              </strong>{" "}
              לפני קבלת שירות טיפולי (בעת הזמנת תור או עם ההתחברות לצורך קבלתו).
              <br></br>
              2.2 הפלטפורמה מיועדת לשימוש אישי, עצמי, עבור יחידים בלבד, ואין
              לעשות בה כל שימוש אחר (כגון שימוש מסחרי, שימוש עבור אחר וכד') אלא
              באישור מפורש בכתב מהחברה. אין לעשות בפלטפורמה כל שימוש שאיננו
              בהתאם לתנאי השימוש או הוראות הדין.
              <br></br>
              2.3 החברה מפעילה את הפלטפורמה ומספקת את השירותים אך היא אינה מספקת
              בעצמה כל שירות טיפולי וכל שירות רפואי אחר. המטפלים אינם עובדי
              החברה או שלוחיה. החברה התקשרה עם המטפלים בהסכם המסדיר את תנאי
              הענקת השירותים הטיפוליים אך אין לחברה שליטה או יכולת פיקוח על
              השירותים הטיפוליים למעט כמפורט בתנאי השימוש במפורש והמטפלים אחראים
              לשירותים הטיפוליים המסופקים על ידם, לרבות להתאמתם למטופל, ההכרח
              שבהם, טיבם, איכותם, חוקיותם או לכל הקשור אליהם והחברה לא אחראית
              לכך, בכל אופן שהוא.<br></br>
              2.4 השימוש באתר ובפלטפורמה, הזמנת תורים וקבלת שירות טיפולי,
              באמצעותה מותר למי שכשיר לבצע פעולות משפטיות. ככלל השימוש באתר
              ובפלטפורמה למי שטרם מלאו לו 18 שנה או שאינו כשיר משפטית – אסור. עם
              זאת, קטינים ופסולי דין יהיו רשאים לעשות שימוש כאמור רק אם הורה או
              אפוטרופוס חוקי, לפי העניין, יהיה צד להתקשרות עם החברה ויתן את
              הסכמתו לתנאי השימוש, להזמנת תור ולקבלת השירות הטיפולי ויוכח להנחת
              דעתה של החברה כי הוא ההורה/האפוטרופוס של המשתמש הקטין/ פסול הדין.
              החברה רשאית לבצע בדיקות ופעולות בקרה לצורך האמור אולם לא תהיה
              מחויבת בכך.<br></br>
              2.5 בנוסף לתנאי השימוש, ייתכן שיחולו בקשר לשירות טיפולי גם תנאים
              והוראות שייקבעו על ידי המטפל, ככל שיהיו כאלה הם יפורסמו, ישלחו
              אליך לפני השירות הטיפולי או ימסרו לך בעל פה בתחילת השירות הטיפולי.
              <br></br>
              <p></p>
              3.{" "}
              <u>
                <strong>השירותים</strong>
              </u>{" "}
              <br></br>
              3.1 החברה מספקת פתרון דיגיטלי לרפואה מרחוק (טלה-רפואה) באמצעות
              הפלטפורמה, ומאפשרת למשתמשים להיחשף למידע אודות מטפלים שונים,
              השירותים הטיפוליים שהם מציעים והמועדים שבהם ניתן לקבלם, וכן לקבוע
              תור לשירות טיפולי. כמו כן יוצרת החברה באמצעות הפלטפורמה את האפשרות
              לבצע את המפגש עצמו עם המטפל באופן וירטואלי מרחוק, בשיחת וידאו
              באמצעות אמצעי קצה של המטפל והמשתמש, או באמצעי אחר שתאפשר החברה
              (למשל טלפון). בנוסף, החברה עשויה לספק באמצעות הפלטפורמה שירותים
              נלווים כפי שיעודכנו מעת לעת באתר.
              <br></br>
              3.2 אתה יכול להיעזר במנוע החיפוש בפלטפורמה לאיתור מטפלים או
              שירותים טיפוליים לפי העדפותיך וצרכיך, לרבות לפי שם המטפל, סוג
              השירות הטיפולי, מועדים פנויים וכד'. ולבצע הזמנת תור לשירות טיפולי,
              במפגש וירטואלי עם מטפל (בשיחת וידאו או אמצעי אחר שתאפשר החברה),
              בהתאם לזמינות המופיעה בפלטפורמה. מובהר כי המטפלים והשירותים
              הטיפוליים המופיעים בפלטפורמה עשויים להשתנות מעת לעת, והחברה אינה
              מתחייבת להיקף או זמינות של שירותים טיפוליים מסוגים שונים או של
              מטפלים שונים.
              <br></br>
              3.3 ט.ל.ח – טעות כלשהי באתר או בפלטפורמה עלולה לקרות, לרבות טעות
              סופר בתיאור כלשהו הנוגע למטפל, שירות טיפולי או מועד זמין. במקרה של
              טעות נשתדל לתקן במועד הסביר האפשרי. טעויות כאלה לא תחייבנה את
              החברה.<br></br>
              <p></p>
              4.{" "}
              <u>
                <strong>הרשמה לפלטפורמה</strong>
              </u>{" "}
              <br></br>
              4.1 ככלל, ביצוע חיפוש בפלטפורמה אינו כרוך בהרשמה לפלטפורמה, עם זאת
              הזמנת תור לשירות טיפולי כרוכה בהרשמה כמפורט להלן. ייתכן שבעתיד גם
              האפשרות לבצע חיפוש בפלטפורמה תהיה כפופה לרישום.<br></br>
              4.2 הרישום לפלטפורמה הוא פשוט, כל שעליך לעשות זה להזין פרטים
              אישיים הדרושים לביצוע פעולות, כמפורט להלן. בכל מקרה של שינוי
              בפרטים האישיים שהזנת בעת ההרשמה, עליך לעדכן את הפרטים באזור האישי
              באתר.<br></br>
              4.3 כתובת הדוא"ל שתזין תהווה את שם המשתמש שלך בפלטפורמה. בנוסף
              עליך לבחור סיסמא אישית. לחילופין תוכל להתחבר באמצעות חשבונך
              בFacebook או באמצעות חשבון הGoogle- שלך.<br></br>
              4.4 עליך לשמור את פרטי ההזדהות שלך בסודיות מוחלטת. שים לב, הזנת שם
              משתמש והסיסמא מאפשרות גישה לאזור האישי שלך באתר בו יוצגו פרטים
              אישיים שמסרת בהרשמה ופרטים נוספים כגון תורים קודמים, שירותים
              טיפוליים שרכשת וכד' וניתן לבצע פעולות שונות בפלטפורמה. אתה אחראי
              לכל שימוש שיעשה על ידך, או על ידי אחר, בפלטפורמה, תוך שימוש בשם
              משתמש וסיסמא שלך או החשבון שלך, וכל פעולה שתבוצע באמצעותם תחייב
              אותך (לרבות בתשלום, אם תבוצע פעולה הכרוכה בכך).<br></br>
              4.5 עליך להודיע לחברה מיידית על כל שימוש בלתי מורשה בחשבון שלך או
              בסיסמא שלך. אם יש לך חשש שסיסמתך נחשפה לאחר עליך להחליפה באופן
              מיידי. מובהר כי החברה לא תישא באחריות בגין כל הפסד או נזק הנובע
              משימוש בלתי מורשה כאמור, וכל אחריותה תתמצה בסגירת החשבון תוך זמן
              סביר ממועד שתקבל הודעה, ובלבד שההודעה כללה פרטים מספיקים וככל
              שנדרש אימות שלה - אומתה כיאות.<br></br>
              4.6 אסור לך לגלות את הסיסמא שלך לאחר. אנחנו לא נבקש ממך לגלות את
              הסיסמא. אם תשכח את הסיסמא, תוכל לדרוש לבקש שסיסמא חדשה תישלח
              לכתובת האימייל או לטלפון שלך. החשבון שלך נתון בסכנה אם תרשה למאן
              דהוא להשתמש בו בצורה שאינה נאותה. החשבון ניתן לסגירה אם אתה או
              מישהו אחר שמשתמש בחשבון שלך מפר את תנאי השימוש האלה. במידה וחשבונך
              נסגר, לא תורשה לפתוח חשבון שוב ללא הסכמה מפורשת מהחברה.
              <br></br>
              4.7 אתה אחראית בצורה בלעדית ומלאה על ביטחון המחשב שלך ועל כל
              הפעילות בחשבון שלך, גם במקרה בו הפעילות לא בוצעה על ידיך. החברה לא
              תישא באחריות בגין כל הפסד או נזק הנובע משימוש בלתי מורשה בחשבון או
              בסיסמא שלך. החברה איננה משגיחה על הנדון.<br></br>
              <p></p>
              5.{" "}
              <u>
                <strong>הזמנת שירות טיפולי; תמורה</strong>
              </u>{" "}
              <br></br>
              5.1 כדי לקבוע פגישה וירטואלית עם מטפל ולהזמין תור לשירות טיפולי
              עליך להיות משתמש רשום. תוכל להזמין תור לשירות טיפולי שבחרת, למועד
              מסוים לבחירתך (בכפוף לזמינות) בדרכים שהחברה תציע בפלטפורמה.
              <br></br>
              5.2 המפגש מתבצע על בסיס אמצעי הקצה שלך ושל המטפל (ולא של החברה).
              לפני הזמנת תור אנחנו ממליצים לבדוק כי אמצעי הקצה וחיבור האינטרנט
              שיעמדו לרשותך במועד התור עומדים בדרישות הטכניות המומלצות (דהיינו:
              אינטרנט במהירות 15 מגה לפחות, טלפון חכם/מחשב/טאבלט עם מצלמה
              ומיקרופון ואפליקציית ZOOM מותקנת על המכשיר) וכן כי השירות הטיפולי,
              המטפל שבחרת, מועד התור וכל יתר פרטי ההזמנה ואת התאמתם לצרכיך
              ולמאפיינך, ולא תהא לך טענה כנגד החברה במקרה של אי התאמה ביחס
              לשירות הטיפולי שנרכש באמצעות האתר.<br></br>
              5.3 בעת הזמנת התור או לאחר מכן יתכן שתצטרך להזין פרטים משלימים
              לבקשת המטפל או החברה כפי שיוצג בפניך. עליך למסור רק פרטים מלאים,
              מדויקים ונכונים, בין היתר על מנת למנוע טיפול שאיננו מיטבי או מתאים
              ו/או באפשרות הכניסה וההשתתפות במפגש עם המטפל. למען הסר ספק, אינך
              חייב על פי דין למסור לחברה פרטים אישיים כלשהם והדבר נתון לרצונך
              והסכמתך, אולם אי מסירתם לא תאפשר לך לבצע פעולות בפלטפורמה.
              <br></br>
              5.4 מחירי השירות נקבעים על בסיס עלות השירות הטיפולי, כפי שהחברה
              נדרשת לשלם למטפלים בתוספת התשלום לחברה בגין השירותים שהיא מספקת
              במישרין כמפורט בתנאי שימוש אלה, והם יוצגו בפניך בעת בחירת התור
              ולפני ביצוע ההזמנה. המחירים המוצגים בפלטפורמה כוללים מע"מ. בנוסף,
              שים לב כי התשלום עשוי להיות כרוך בעמלות ו/או חיובים שונים לחברת
              האשראי, בהתאם לתנאי השירות וההתקשרות שלך עימם.<br></br>
              5.5 התשלום של מחיר השירות, על רכיביו השונים (כולל האפשרות לקבל את
              השירות הטיפולי באמצעות הפלטפורמה), יבוצע מראש, בעת הזמנת התור על
              ידך.<br></br>
              5.6 התשלום יבוצע בכרטיס חיוב ו/או אמצעי תשלום אחר שתאפשר החברה.
              בהזנת פרטי אמצעי התשלום אתה מאשר כי הוא מוחזק בידך כדין ואתה רשאי
              להשתמש בו לצורך תשלום עבור השירותים. החברה רשאית לשנות את סוגי
              אמצעי התשלום שבהם ניתן לשלם באתר וכן את המותגים הבינלאומיים של
              חברות האשראי שאותן היא מכבדת (ויזה, מאסטרקארד, דיינרס וכו').
              <br></br>
              5.7 השלמת פעולת הזמנת התור מהווה אישור מצדך לחיוב אמצעי התשלום
              בהתאם לפרטי ההזמנה. עליך לוודא בטרם אישור הפעולה וביצוע התשלום כי
              פרטי העסקה נכונים ומתאימים לצרכיך.<br></br>
              5.8 שמירת התור לשירות הטיפולי נעשית רק לאחר קבלת התשלום על ידי
              החברה או אישור חברת האשראי, לפי העניין.<br></br>
              5.9 לאחר הזנת הפרטים הנדרשים ואישור השלמת ההזמנה לאחר ביצוע
              התשלום, יישלח לך אישור לדוא"ל ו/או הודעתSMS לנייד, לפי הפרטים
              שמסרת. כמו כן תישלח לך הודעת דוא"ל/SMS נוספת הכוללת חשבונית
              מס/קבלה.<br></br>
              5.10 למען הסר ספק, במקרה בו ההזמנה אושרה אך חברת האשראי לא כיבדה
              את החיוב, מכל סיבה שהיא, תהיה החברה רשאית לבטל את העסקה, והודעה על
              כך תישלח לך באמצעות הדוא"ל או בכל דרך אחרת שתבחר החברה.<br></br>
              5.11 בעת קבלת אישור כאמור באחריותך לבדוק את תקינות ההזמנה והתאמת
              התור, השירות הטיפולי ופרטיך לפרטים שציינת. במקרה של תקלה כלשהי
              עליך לבטל את התור, בהתאם להוראות תנאי השימוש או לפנות ללא דיחוי
              לחברה באמצעות לשונית ״צור קשר״. מובהר כי אין בפניה כאמור כדי להטיל
              אחריות כלשהי על החברה או לחייב את החברה בהחלפת התור/ביטול העסקה
              וכל פנייה תיבחן ותטופל לגופה.
              <br></br>
              5.12 ככל שלא קיבלת אישור הזמנה בתוך 48 שעות מביצוע ההזמנה,
              באחריותך לפנות לשירות הלקוחות של החברה בלשונית ״צור קשר״, לבירור
              העניין.<br></br>
              5.13 למען הסר ספק, החברה רשאית שלא לאשר הזמנת תור לשירות טיפולי
              במקרה בו נתקבל סירוב מחברת האשראי, התור נתפס, אינך עומד בתנאי
              השימוש או אם המטפל החליט שלא להעניק את השירות הטיפולי מכל סיבה
              שהיא. כמו כן החברה רשאית להפסיק להפעיל את הפלטפורמה או להפסיק
              לאפשר הזמנת תור לשירות טיפולי אחד או יותר (או כולם), לפי שיקול
              דעתה המלא.<br></br>
              5.14 החברה אינה מעסיקה מטפלים ואינה נותנת בעצמה שירותים טיפוליים,
              אלא היא מנגישה למשתמשים שירותים טיפוליים מטעם מטפלים מסוימים. על
              מנת לאפשר את הספקת השירות הטיפולי, החברה רוכשת או תרכוש מהמטפל את
              הזכות לשירות הטיפולי, ומוכרת לך את הזכות האמורה לשירות הטיפולי,
              בכפוף לאותם תנאים שבהם החברה רכשה את הזכות. לכן, למרות שהנך משלם
              לחברה את התמורה בגין מלוא מחיר הטיפול, החברה מספקת בעצמה רק את
              שירותי הפלטפורמה ולא את השירות הטיפולי, וגביית התמורה בגין השירות
              הטיפולי נעשית רק כמפיצה (reseller) של הזכות לקבל שירות טיפולי.
              <br></br>
              5.15 אם אתה בעל מוגבלות כלשהי אשר נדרש לאמצעי מיוחדים או שירות
              מיוחד עליך לבדוק את הייתכנות עם החברה או המטפל לפני שתזמין את התור
              לשירות הטיפולי.<br></br>
              <p></p>
              6.{" "}
              <u>
                <strong>התור לשירות הטיפולי; מפגש עם המטפל</strong>
              </u>{" "}
              <br></br>
              6.1 באחריותך לוודא כי במועד התור שנקבע ניתן יהיה להשיגך באמצעות
              הפרטים שמסרת בזמן ההרשמה לאתר. אל תאחר. במידה ובמועד המפגש
              הווירטואלי שנקבע לא ניתן היה להשיג אותך בדרך שנקבעה, ומועד התור
              חלף ולא ניתן לך השירות הטיפולי, או שניתן באופן חלקי, לא תהיה זכאי
              לכל החזר כספי או פיצוי.
              <br></br>
              6.2 השירות הטיפולי ינתן רק לך - המשתמש הרשום, שהזמין אותו ואסור לך
              לאפשר לאחר להתחבר בשמך או במקומך לשירות ולקבל את השירות הטיפולי
              במקומך. בכניסה למפגש עם המטפל תעבור הליך זיהוי באמצעות שם המשתמש
              והסיסמא וייתכן שתצטרך להציג תעודה מזהה לפי שיקול דעת החברה ו/או
              המטפל. ככל שלא תבצע את הליך הזיהוי, מכל סיבה לרבות בשל אבדן
              הסיסמא, לא תוכל לקבל את השירות הטיפולי ולא תהיה זכאי לכל החזר כספי
              או פיצוי.<br></br>
              6.3 לפני קבלת השירות יהיה עליך לאשר את נוסח{" "}
              <strong>
                הסכמה מדעת לקבלת השירות הטיפולי בנוסח המופיע באתר
              </strong>{" "}
              [
              <a href="http://www.hidoc.co.il/consent">
                www.hidoc.co.il/consent
              </a>
              ].
              <br></br>
              6.4 בתחילת המפגש עם המטפל עליך לבקש מהמטפל להזדהות ולוודא כי המטפל
              ותחום מומחיותו מתאימים לשירות הטיפולי שאתה מבקש לקבל.<br></br>
              6.5 המידע ו/או ההכוונה שיינתנו לך, יתבססו על המידע שיימסר על ידך,
              לפני או במהלך המפגש עם המטפל. משכך, הסתרת מידע רלוונטי עשוי לגרום
              לך לנזק.
              <br></br>
              6.6 הנחיות לטיפול או כל מידע שיימסר לך במהלך המפגש עם המטפל בעת
              קבלת השירות הטיפולי, אינו מהווה תחליף לבדיקה ו/או לטיפול ו/או
              לייעוץ רפואי פרונטלי על ידי רופא, בהתאם לצורך. כמו כן, ההמלצה או
              המידע שיינתנו על ידי המטפל, ככל שיינתנו, בהתאם להכשרתו המקצועית,
              אינם מהווים תחליף לביצוע פעולות נוספות על ידך, שמטרתן לשפר את
              בריאותך ו/או למנוע הדרדרות במצבך.{" "}
              <strong>
                בכל מקרה של בעיה דחופה, מקרה חירום, מצב מצוקה, הידרדרות פתאומית
                במצב בריאותי, יש לפנות באופן מיידי לקבלת טיפול רפואי, במחלקות
                לרפואה דחופה בבתי החולים או במוקדי רפואה דחופה.
              </strong>
              <br></br>
              6.7 המידע שיינתן לך באמצעות השירות הטיפולי יכול שיתייחס לתחומים
              מסוימים, ואינו כולל, בהכרח, התייחסות לכל תחומי הרפואה שעשויים
              להיות רלוונטיים לפנייה. בנוסף, ייתכנו מקרים בהם המטפל יפנה אותך
              לרופא מומחה, רופא יועץ וכד'. החברה לא תממן הפניות או טיפולים אלה
              ואינה אחראית להם.<br></br>
              6.8 בתום כל מפגש עם המטפל, שיתקיים במסגרת השירות, יישלח לך, באופן
              שייבחר על ידי החברה, סיכום פניה. אם סיכום הפניה אינו מדויק פנה
              אלינו באופן מיידי לצורך בירור מול המטפל ותיקון במידת הצורך. מומלץ
              לדאוג ולוודא כי סיכום הפניה יעודכן בתיקך הרפואי המנוהל על ידי קופת
              החולים שלך.<br></br>
              6.9 בכניסה למפגש עם המטפל אתה מתחייב לפעול בהתאם לכללי ונהלי המטפל
              אם פורסמו או נמסרו לך. לחברה אין כל אחריות בקשר לכך, והכללים
              נתונים לשיקול דעתו של כל מטפל בכפוף לכל דין. לא תהיה לך טענה או
              דרישה כלשהי נגד החברה בקשר לכך, ולא תקבל החזר כספי או פיצוי, לרבות
              במקרה בו נמנעה השתתפותך במפגש עם המטפל או שהשירות הטיפולי לא הושלם
              והורחקת מפגישה עם המטפל עקב אי עמידה בכללים האמורים.<br></br>
              <p></p>
              7.{" "}
              <u>
                <strong>ביטול עסקה ביוזמתך</strong>
              </u>{" "}
              <br></br>
              7.1 החברה מספקת לך שירות, לאור הזמנתך ובהסתמך עליה, בעסקה שאינה
              מתמשכת. אתה רשאי לבטל את הזמנת השירות הטיפולי רק ככל שעומדת זכות
              לעשות כן על פי דין.
              <br></br>
              7.2 לידיעתך, בהתאם לחוק הגנת הצרכן התשמ"א-1981, ככלל, אתה רשאי
              לבטל עסקה לרכישת שירות בתוך 14 ימים מעת ביצוע העסקה או מיום קבלת
              המסמך המכיל את הפרטים אודותיה, ובתנאי שהביטול ייעשה לפחות שני
              ימים, שאינם ימי מנוחה, קודם למועד שבו אמור השירות להינתן.<br></br>
              7.3 בנוסף, לידיעתך, אם אתה אדם עם מוגבלות (לפי ההגדרה בחוק שוויון
              זכויות לאנשים עם מוגבלות, התשנ"ח-1998), עולה חדש (מי שטרם חלפו חמש
              שנים מיום שניתנה לו תעודה עולה או תעודת זכאות כעולה ממשרד העלייה
              והקליטה) או אזרח ותיק, (מי שמלאו לו 65 שנה), וההזמנה כללה שיחה
              טלפונית או צ'אט עם נציג מטעם החברה, אתה רשאי לבטל עסקה בתוך 4
              חודשים ממועד ביצועה או מיום קבלת המסמך המכיל את הפרטים אודותיה,
              ובתנאי שהביטול ייעשה לפחות שני ימים, שאינם ימי מנוחה, קודם למועד
              שבו אמור השירות להינתן. לצורך ביטול בנסיבות אלו החברה רשאית לדרוש
              הצגת תעודה המעידה כי הנך אדם עם מוגבלות, עולה חדש או אזרח ותיק
              כאמור.<br></br>
              7.4 לביטול עסקה בהתאם לאמור עליך לפנות לשירות הלקוחות של החברה,
              באמצעות הלשונית ״צור קשר״ וכל בקשה תיבחן לגופה.<br></br>
              7.5 תוצאות ביטול עסקה: על פי החוק, במקרה של ביטול העסקה בתנאים
              הקבועים לכך בדין, תהיה זכאי להחזר כספי בתוך 14 ימים ממועד קבלת
              הודעת הביטול, באמצעות זיכוי כרטיס האשראי עימו שילמת בעת ביצוע
              העסקה. ההחזר יהיה בגובה המחיר ששילמת בפועל בגין העסקה, בניכוי דמי
              ביטול בסך 5% ממחיר העסקה או 100 ₪ - לפי הנמוך מבניהם.
              <br></br>
              7.6 לא תהיה זכאי לבטל תשלום או הוראת תשלום, אלא בנסיבות שנקבעו
              במפורש בדין. ככל שתבטל תשלום או הוראת תשלום בניגוד לאמור, תישא בכל
              העלויות וההוצאות שיגרמו לחברה או למטפל בשל כך.<br></br>
              <p></p>
              8.{" "}
              <u>
                <strong>
                  ביטול או שינוי מועד לתור על ידי המטפל ו/או החברה; אי התאמה
                </strong>
              </u>{" "}
              <br></br>
              8.1 לידיעתך, המטפל עשוי לבטל או לשנות מועד השירות הטיפולי שהזמנת.
              במקרה כאמור תישלח לך הודעה לדוא"ל/SMS בדבר הביטול/שינוי.<br></br>
              8.2 במקרה של ביטול על ידי המטפל, יושב לך מלוא הסכום ששילמת בגין
              השירות הטיפולי וזאת באמצעות זיכוי אמצעי התשלום עימו בוצעה העסקה.
              <br></br>
              8.3 במקרה של שינוי מועד התור על ידי המטפל, באפשרותך להודיע לחברה
              בהודעה לדוא"ל בתוך 2 ימי עסקים (ובלבד שאם התור חל בתוך פרק זמן זה-
              אז לפחות 3 שעות לפני מועד התור) כי ברצונך לבטל את התור. במקרה
              כאמור, יושב לך מלוא הסכום ששילמת בגין הזמנת השירות הטיפולי, וזאת
              באמצעות זיכוי אמצעי התשלום עמו בוצעה העסקה.
              <br></br>
              8.4 ביטול על ידי המטפל בשל אי-התאמה: כאמור בתחילת המפגש עם המטפל
              עליך לוודא כי המטפל ותחום מומחיותו מתאימים לשירות הטיפולי שאתה
              מבקש לקבל. אם זהותו של המטפל אינה תואמת את המטפל שאליו נקבע לך
              תור, או שתחום מומחיותו של המטפל אינם מתאימים לטיפול הנדרש לך לרבות
              במקרה שלפי שיקול דעתו של המטפל לא ניתן להעניק לך את השירות הטיפולי
              או שאתה נדרש לקבל טיפול פנים אל פנים במפגש פיזי, אז לפי בקשתך
              יעדכן המטפל בכך את החברה והחברה תשיב לך את התשלום ששולם על ידך
              בגין קביעת התור בכפוף לתוצאות בירור שתערוך החברה לפי שיקול דעתה.{" "}
              <br></br>
              8.5 למען הסר כל ספק, החברה לא תישא באחריות מכל סוג שהוא במקרה של
              ביטול או שינוי מועד כאמור, למעט השבת הסכום ששולם בנסיבות המפורטות
              לעיל.
              <br></br>
              <p></p>
              9.{" "}
              <u>
                <strong>פרטיות, אבטחה ומאגרי מידע</strong>
              </u>{" "}
              <br></br>
              9.1 בשימושך באתר ובפלטפורמה אתה מאשר כי המידע אשר נמסר על ידך
              לחברה או למטפל, נמסר מרצונך ובהסכמתך החופשיים, ומבלי שחלה עליך
              חובה חוקית למסור אותם ויישמרו במאגרי המידע של החברה בהתאם להוראות
              הדין, והשימוש בהם כמו גם ביתר המידע שייאסף במסגרת שימושך באתר,
              ייעשה למטרות המפורטות בתנאי השימוש, לרבות במדיניות הפרטיות של
              החברה [
              <a href="https://hidoc.co.il/privacy">
                https://hidoc.co.il/privacy
              </a>
              ]. למען הסר ספק, פרטי האשראי אינם נשמרים במאגרי החברה.<br></br>
              9.2 מבלי לגרוע מכלליות האמור, לידיעתך – חלק מהמידע ימסר למטפל שאת
              השירות הטיפולי שלו הזמנת. המידע ישמש בנוסף למטרות משלוח הודעות
              שיווקיות על ידי החברה אודות שירותים המפורסמים על ידה, לרבות הודעות
              המותאמות לך על בסיס שימושך באתר, ובעצם ההרשמה לאתר או ביצוע פעולה
              באמצעותו הנך מאשר קבלת הודעות כאמור בכל אמצעי תקשורת לרבות בדואר
              אלקטרוני, SMS וטלפון. יובהר, כי הנך רשאי להסיר עצמך מקבלת הודעות
              כאמור בכל עת על ידי פנייה בעמוד ה״צור קשר״ שבאתר.<br></br>
              9.3 החברה רשאית לעשות שימוש בטכנולוגיית "עוגיות" ("cookies")
              ובטכנולוגיות דומות במהותן לצורך תפעולו השוטף והתקין של האתר (להלן
              ביחד "cookies"), ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש
              באתר, לאימות פרטים, להתאמת האתר להעדפותיך האישיות ולצרכי אבטחת
              מידע.<br></br>
              9.4 הגלישה באתר החברה והשימוש בשירותים הכלולים בו מהווה הסכמה מצדך
              לכך שהחברה תוכל לבצע פיקוח ממוחשב על דפוסי הגלישה והשימוש שהנך
              עושה בשירותים באמצעות "cookies" ואמצעים דומים אחרים. החברה רשאית
              להשתמש בכל מידע כאמור, לרבות מידע אישי, לצורך בקרה ו/או פיקוח ו/או
              פיתוח ו/או שמירה על איכות השירותים, רמתם ותקינותם ו/או לצורך קיום
              הוראות כל דין.<br></br>
              9.5 הצהרת המשתמש: אתה מאשר כי אתה מודע לכך שפנייתך, המידע שמסרת
              והמפגש עם המטפל מתועדים באופן מלא על ידי החברה, וכי בעצם ההרשמה
              לקבלת השירות אתה מסכים לכך ומצהיר בזאת, כי הפרטים האישיים
              ונתוני־הזיהוי, שאתה מוסר אודותיך או אודות מי שאתה משמש אפוטרופוס
              שלו, הם מלאים ומדויקים. אתה מאשר בזאת, כי ידוע לך, שעשיית שימוש
              בפרטים אישיים ובנתוני־זיהוי, אשר אינם שלך ו/או של מי שאתה משמש
              אפוטרופוס שלו, או מסירה של פרטים אישיים ונתוני־זיהוי, אשר אינם שלך
              ו/או של מי שאתה משמש אפוטרופוס שלו ו/או ללא הרשאה הינה אסורה,
              מהווה פגיעה על־פי חוק הגנת הפרטיות, התשמ"א-1981 ועלולה לגרור ענישה
              פלילית. אתה מתחייבת בזאת, כי תפצה את החברה ו/או המטפל על כל הוצאה
              ו/או נזק, שעלולים להיגרם עקב עשיית שימוש בפרטים אישיים
              ובנתוני־זיהוי, אשר אינם שלך או של המשתמש שאתה משמש כאפוטרופוס שלו,
              ו/או עקב שימוש במערכת תוך התחזות לאחר.<br></br>
              9.6 החברה מפעילה, בעצמה או באמצעות צדדים שלישיים, מערכות ונהלים
              לאבטחת מידע כמקובל בתחום, במטרה לצמצם סיכונים לחדירה בלתי מורשית
              ולאפשר הגנה על המידע המוזן לפלטפורמה. העברת המידע, לרבות פרטי
              כרטיס האשראי, מתבצעת בתקשורת מוצפנת, העוברת בפרוטוקול מקודד (SSL).
              יחד עם זאת, מערכות ונהלים אלו לא מעניקים בטחון מוחלט, ויתכנו כשלים
              באבטחת המידע (כגון יירוט, גישה בלתי מורשית וכד'). בשימושך באתר הנך
              מאשר כי אתה מודע לכך שמדובר בפעולות מקוונות, וכי אין ביכולת החברה
              להבטיח חסינות מוחלטת מפני חדירה למערכותיה או חשיפת המידע ואתה
              מוותר על כל טענה כנגד החברה בקשר לכך. בנוסף, הנך מודע למגבלת רשת
              האינטרנט לגבי אבטחת מידע ברשת ומשחרר את החברה מכל אחריות בעניין
              זה.<br></br>
              9.7 החברה אינה אחראית לטעות הנעשית על ידך בעת הזנת פרטים לאתר, ולא
              אחראית בכל מקרה של תקלה או בעיה המונעת ביצוע פעולות באתר או המבצעת
              אותן באופן שגוי.<br></br>
              <p></p>
              10.{" "}
              <u>
                <strong>הפסקת או חסימת פעילות באתר</strong>
              </u>{" "}
              <br></br>
              10.1החברה רשאית למנוע ממך מלפעול כמשתמש רשום באתר ו/או לבצע פעולות
              באתר באמצעות חסימת גישתך או בכל דרך אחרת, לזמן קצוב או לזמן שאינו
              קצוב, וזאת בכל מקרה בו החברה תחשוד כי הנך משתמש באתר ו/או עושה
              פעולה בניגוד להוראות תנאי שימוש אלו ו/או בניגוד לכל דין, ובכל מקרה
              בו תחשוד החברה כי הנך עושה שימוש לרעה באתר או בשירותיה בכל צורה
              שהיא, לרבות שימוש בלתי חוקי או שימוש לצרכים מסחריים. בנוסף, במקרים
              כאמור החברה תהיה רשאית לנקוט בכל פעולה הנראית לה מתאימה, ובמסגרת
              כך החברה שומרת את הזכות לחקור כל חשד כאמור. החברה תשתף פעולה עם כל
              חקירה של זרועות אכיפת החוק או עם כל צו בית משפט שדורש מהברה או
              מנחה אותה לגלות את הזהות, ההתנהגות או כל תוכן של כל משתמש שישנו
              חשד שנקט בפעולות הנחזות למנוגדות לדין. כל סגירה או השעיה של חשבונך
              לא תשפיע על החובות שלך כלפי החברה תחת תנאי השימוש הללו שלפי מהותם
              ולפי העניין מיועדים להוסיף ולהתקיים לאחר סגירה או השעיה כאמור.
              <br></br>
              10.2 מבלי לגרוע מהאמור לעיל, החברה תהא רשאית לנקוט בצעדים האמורים
              במקרים הבאים, וזאת לפי שיקול דעתה הבלעדי וללא מתן הודעה מראש: מסרת
              לחברה פרטים שגויים במתכוון או שאין לחברה יכולת לאמת ו/או לאשש ו/או
              לאשר כל מידע שנמסר על ידך; הפרת תנאי השימוש או את מהותם; עשית מעשה
              או מחדל שהחברה חושדת כי מהווה זיוף, התחזות, הטעיה או הונאה, או
              ניסיון לאחד מאלו; תקופה ארוכה של חוסר פעילות; הכחשה שלא כדין של
              ביצוע עסקאות על ידך; אמצעי התשלום בהם נעשה השימוש לצורך ביצוע
              פעולות באתר אינו תקין, נחסם או הוגבל לשימוש בדרך כלשהי; המטפל מסר
              כי התנהלת באופן לא ראוי, ובכלל זה לא עמדת בנהלי ותנאי ההשתתפות
              במפגש עם המטפל; ביצוע או ניסיון לביצוע של מעשה או מחדל שיש בו כדי
              לפגוע בחברה או בפעילות התקינה של האתר, או שימוש בשירותי החברה
              ובאתר תוך מעורבות בפעילות מרמה או פעילות בלתי חוקית אחרת כלשהי, או
              תוך פגיעה בכל דרך שהיא בזכויות יוצרים, סימני מסחר, או כל זכות
              קניינית אחרת של החברה; התנהגות שמהווה הונאה או שהינה פוגענית או
              מטרידה; או התנהגות המזיקה למשתמשים אחרים או לצדדים שלישיים.
              <br></br>
              10.3 כמו כן, החברה שומרת לעצמה את הזכות להפסיק בכל עת את הפעילות
              באתר, כולה או חלקה, למשך התקופה הנדרשת, על פי שיקול דעתה הבלעדי,
              אם אירעה תקלה טכנית ו/או אחרת אשר מנעה או עלולה הייתה למנוע המשך
              תקין של ביצוע פעולות ומתן השירותים באמצעות האתר. במידת האפשר תודיע
              החברה על השבתה צפויה וידועה מראש בפלטפורמה.<br></br>
              <p></p>
              11.{" "}
              <u>
                <strong>אחריות</strong>
              </u>{" "}
              <br></br>
              11.1 האתר בכללותו, לרבות השירותים המוצעים בו, התכנים המופיעים בו
              והתוכנה העומדת בבסיסו, ניתנים לשימוש כמות שהם (AS IS/ AS
              AVAILABLE) וללא כל התחייבות או אחריות מכל סוג שהוא. החברה איננה
              מספקת כל מצג מכל סוג שהוא, מפורש או משתמע, בנוגע לאתר ולשירותים
              המוצעים בו, והגלישה והשימוש באתר, בתכנים המוצגים באתר ובשירותי
              האתר ייעשו על אחריותך הבלעדית והמלאה, והחברה לא תישא באחריות לכל
              נזק, הוצאה או אובדן שייגרמו לך עקב השימוש באתר.<br></br>
              11.2 חברה נוקטת באמצעים שונים בכדי להבטיח את תקינות פעולת האתר
              והפלטפורמה, אולם לא ניתן למנוע באופן מוחלט תקלות מסוגים שונים
              בפלטפורמה, זמינותה ותקינותה, לרבות תקלות תקשורת, תקלות מיחשוב
              ("באגים") וכד'. לאור האמור, החברה אינה מתחייבת ואינה מציגה כל מצג
              כי הפלטפורמה תהא זמינה לגישה כל העת ללא הפרעות וכי תהא נקייה מכל
              שגיאות או פגמים. הנך מוותר מראש על כך טענה בקשר לכך, לרבות במקרה
              בו נמנעה ממנך האפשרות לרכוש שירות טיפולי.<br></br>
              11.3 מסיבות שונות, הקשורות בחברה או שאינן קשורות בחברה, ייתכן
              שמועדי השירותים הטיפוליים, השירותים הטיפוליים ו/או המטפלים ישתנו,
              לפני הזמנת תור או לאחריה, ייתכן שהדבר יקרה אף ללא הודעה מוקדמת.
              <br></br>
              11.4 כפי שצוין לעיל, החברה מוכרת לך זכות לשירות טיפולי שמסופק על
              ידי המטפלים (כ- reseller של זכות זו), והיא מאפשרת את מתן השירות
              הטיפולי, מסייעת בקביעת התור ומספקת חלק מהתשתית הטכנולוגית, בעצמה
              או באמצעות מי מטעמה והיא רוכשת את השירות הטיפולי עבורך אך אינה זו
              שמספקת את השירות הטיפולי בפועל. החברה לא תישא באחריות מכל סוג שהוא
              במקרה שייגרם לך נזק בגין קבלת השירות הטיפולי או הימנעות מפניה
              לקבלת טיפול רפואי, לרבות כל תקלת תקשורת, אי-נוחות, הפרת דין או
              בעיה אחרת הנוגעת למטפל או לצד שלישי כלשהו. אתה מוותר מראש על כל
              טענה או דרישה כלפי החברה בקשר לכך ותימנע מלהסתמך על רציפות וזמינות
              השירותים, זהות המטפלים, השירותים הטיפולים והמועדים באופן שיגרום לך
              נזק במקרה של ביטול, הפסקה או שינוי כאמור.
              <br></br>
              11.5 החברה לא מתחייבת כי היא בודקת את פעילותם של המטפלים לפני שהם
              מתקשרים עימה לצורך הצעת שירותים טיפוליים בפלטפורמה. אין בעצם הופעת
              מטפל בפלטפורמה כדי להטיל על החברה אחריות מכל מן וסוג שהוא בקשר
              אליו. בהתאם, גם הפרסומים אודות המטפלים נעשים על ידי המטפלים עצמם,
              ובאחריותם, והחברה לא בודקת אותם ואינה יכולה להבטיח כי המידע
              המפורסם הינו מלא, מדויק ומעודכן. <br></br>
              11.6 הפעילות באתר ובפלטפורמה, הפרסומים בהם והשירותים אינם מהווים
              המלצה, חוות דעת ו/או ייעוץ על ידי החברה בקשר לשירותים הטיפוליים או
              המטפלים. והחברה לא נותנת כל מצג בנוגע למטפלים או השירותים
              הטיפוליים ובנוגע להתאמתם למטופל, ההכרח שבהם, טיבם, איכותם, חוקיותם
              או לכל הקשור אליהם. החברה לא תהא אחראית בגין נזקים או הוצאות או
              הפסדים כלשהם מכל מן וסוג שהוא שייגרמו או שעלולים להיגרם להם מחמת
              מעשה או מחדל של המטפל או של כל מטפל אחר שאליו הפנה המטפל.<br></br>
              11.7 החברה דוחה במפורש כל אחריות, לרבות, ללא הגבלה, אחריות מפורשת
              או משתמעת לשימושיות האתר, אחריות מפורשת או משתמעת לסחירות, התאמה
              למטרה מסוימת, בעלות או אי-הפרה. כל הסתמכות על האתר הינה באחריותך
              הבלעדית והחברה דוחה כל אחריות, מפורשת או משתמעת, (א) ביחס לדיוק,
              האמינות, העמידה בזמנים, האבטחה או ביצועיהם של האתר; (ב) שהאתר יהיה
              ללא טעויות או שהטעויות, ככל שישנן, תתוקנה; (ג) ביחס לזמינות,
              הדיוק, האיכות, העדכניות, השלמות או השימושיות של כל מידע או תוכן
              המוגשים באתר.<br></br>
              11.8 החברה אינה מקבלת על עצמה כל אחריות עבור כל שגיאה, השמטה,
              הפסקה, מחיקה, פגם, עיכוב בפעולה או שידור, כשל בקו תקשורת, גניבה או
              הרס או גישה בלתי מורשית או שינוי של תוכן כלשהו. החברה אינה אחראית
              לכל בעיה או תקלה טכנית של כל רשת או קו טלפון ו/או אינטרנט, מערכות
              מחשב מקוונות, שרתים או ספקים, ציוד מחשב, תוכנה, כשל בכל אימייל
              הנובעים מבעיות טכניות או עומס ברשת האינטרנט או באתר או בשירותים
              שלנו, לרבות כל פגיעה או נזק לך או לכל מחשב של אדם הקשור או הנובע
              משיתוף או הורדת חומרים מהאתר או ביחס לשירותים המוצעים. החברה לא
              תהיה אחראית לכל אובדן או נזק, לרבות פגיעה אישית או מוות, כתוצאה
              משימוש באתר או בגין שימוש מפר, מכל תוכן שפורסם או מהתנהגות של כל
              משתמשי האתר ו/או ספקים ו/או ספקי שירותים ואו מטפלים, בין אם בצורה
              מקוונת ובין אם לאו.<br></br>
              11.9 החברה, מנהליה, נושאי המשרה בה, עובדיה, שליחיה או בעלי מניותיה
              בשום אופן לא יהיו אחראים כלפיך או כלפי כל צד שלישי לכל נזק או
              פיצוי שהוא, לרבות, ללא הגבלה, נזק או פיצוי כללי, אגבי, תוצאתי,
              עקיף, ישיר או עונשי הנובע מהשימוש באתר ו/או בגין שימוש מפר או בכל
              הקשור אליהם. בכל מקרה, החבות הכוללת של החברה במסגרת כל תנאי מתנאי
              השימוש הללו לא תעלה על הסכום ששילמת לחברה בכדי להשתמש באתר ו/או
              הסכומים ששילמת עבור השירותים בתקופה של שלושה חודשים לפני התביעה
              הרלוונטית. אי לכך, אם לא שילמת כל סכום לחברה כדי להשתמש באתר ו/או
              בגין השירותים, החברה לא תהיה חבה לך בגין השימוש כאמור. בשום אופן
              החברה לא תהיה אחראית להפסדים או לנזקים העולים מהשימוש באתר ו/או
              בשירותים או מכל תוכן המועלה לאתר או מכל התנהגות של כל צד שלישי,
              באופן מקוון או לאו. מבלי לגרוע מהאמור לעיל, אחריות החברה בהתאם
              לתנאי שימוש אלה, תהא כפופה לכל דין.<br></br>
              <p></p>
              12.{" "}
              <u>
                <strong>כללי</strong>
              </u>{" "}
              <br></br>
              12.1 פרסומות: החברה רשאית לפרסם באתר (לרבות בכל חלק שלו המוצג
              במחשב או במכשיר סלולארי) פרסומים אודות או מטעם גורמים שלישיים,
              לרבות המטפלים. מובהר, כי החברה אינה אחראית על פרסומים של צדדים
              שלישיים כאמור או על השירותים המסופקים על ידם, לרבות השירותים
              הטיפוליים שמציעים במסגרת הפלטפורמה.<br></br>
              12.2 קישורים: האתר עשוי לכלול קישורים לאתרים של צדדים שלישיים,
              לרבות דפי נחיתה לאתרים של מטפלים או של שירותים טיפוליים. מובהר
              בזאת כי הקישורים מוצגים לנוחות המשתמשים בלבד וכין אין לחברה כל
              שליטה עליהם. בשימושך באתר הנך מאשר כי מוסכם עליך שהחברה אינה
              אחראית או חבה בשל כל האמור והמוצג באותם אתרים, לרבות בקשר לזמינות
              או לדיוק של אתרים כאמור ואינה נחשבת למי שמקדמת אתרים אלו או את
              תכניהם, מוצריהם, או שירותיהם, וכי אתה לוקח אחריות בלעדית על כל
              סיכון העולה משימוש באתרים כאמור. ייתכן שהשימוש באתרים הללו כפוף
              לתנאי שימוש שהגדירו בעליהם.
              <br></br>
              12.3 ככל שיימסר לחברה מידע מהימן אשר לפיו מטפל, מפרסם או צד שלישי
              מפרסם באתר מידע שאינו נכון או אשר מפר כל דין, לרבות איסור לשון
              הרע, החברה רשאית להסיר את המידע או את הקישור אליו, ובכך תתמצה
              אחריות החברה.
              <br></br>
              12.4 החברה איננה מספקת כל מצג מכל סוג שהוא, מפורש או משתמע, ואיננה
              אחראית: (א) בנוגע לאבטחת האתר, דיוקו, מהימנותו וביצועיו של האתר;
              או (ב) שהאתר יהיה נטול שגיאות, השמטות, הפרעות, פגמים, עיכובים
              בתפעול או שידור, כשלים בקווי תקשורת, גניבה או גישה אסורה למידע
              באתר, או ששגיאות או פגמים כאמור יתוקנו; או (ג) בנוגע לדיוקו,
              איכותו, תדירותו, שלמותו, או תועלתו של המידע המסופק על ידי האתר; או
              (ד) לכל בעיה טכנית של קווי טלפון או קווי רשת, מערכות מחשב מקוונות,
              שרתים או ספקים, ציוד מחשבים, תוכנה, כשל מכל סוג של דואר אלקטרוני
              בשל בעיות טכניות או עומסים באינטרנט או באתר או בשרת האתר או השילוב
              ביניהם, כולל נזק או פגיעה בלקוחות כתוצאה מהאמור; או (ה) בנוגע לאתר
              ולשירותים המסופקים על-ידי האתר.<br></br>
              12.5 הגישה לחלקים מן האתר עשויה להיות מותנית ו/או מושפעת מהתקנת
              רכיבי חומרה/תוכנה במחשב, לרבות דפדפנים מסוימים ותוספים לאותם
              דפדפנים. באחריותך לברר ולהתקין כל רכיב תוכנה שיידרש לצורך השימוש
              באתר, על חשבונך ואחריותך. החברה אינה אחראית לרכיבי תוכנה אלה ולכל
              פגם בהם, ולכל טעות או אי התאמה בהצגת התכנים הנובעת מרכיבי התוכנה,
              מהתקנתם או מאי התקנתם או מכל אי התאמה או אי תאימות של כל תוכנה,
              רכיב תוכנה או גרסה מסוימת שלהם לאתר, לתכני האתר או לחלק מהם. מבלי
              לגרוע מכלליות האמור לעיל, הגישה לאתר ולתכנים והצגתם עשויה להשתנות
              בהתאם לתכונות החומרה, מערכת ההפעלה והתוכנה שבמחשב שבו אתה המשתמש
              ולהגדרותיהם. החברה אינה מתחייבת כי התכנים יוצגו בצורה מלאה ו/או
              זהה ו/או תקינה במחשבים שונים.<br></br>
              <p></p>
              13.{" "}
              <u>
                <strong>זכויות החברה באתר</strong>
              </u>{" "}
              <br></br>
              13.2 האתר בכללותו והמידע המופיע בו, לרבות כל התכנים המופיעים בו
              והתוכנה העומדת בבסיסו, הנם קניינה הבלעדי של החברה או של צדדים
              שלישיים שהתירו לחברה לעשות בהם שימוש, לרבות מטפלים. מבלי לגרוע
              מכלליות האמור, מלוא זכויות הקניין הרוחני, לרבות זכויות היוצרים,
              השיטות, הסימנים המסחריים והסודות המסחריים, באתר, ובכל תוכנה,
              יישום, קוד מחשב, קובץ גרפי, טקסט, בסיסי הנתונים בו וכל פרט אחר
              הנוגע לאתר - הינן של החברה בלבד (או של צדדים שלישיים כאמור).
              זכויות אלו חלות בין היתר על שם המותג "Hidoc", סימני המסחר והסמלים
              המסחריים המופיעים באתר, רשימות, וכיוב', שהינם בבעלות החברה. אינך
              רשאי להסיר, לשנות או להסתיר כל סימן של זכויות יוצרים, סימן מסחרי,
              או סימן שירות או כל סימון אחר המסמן זכויות קניין ששולב באתר. כל
              סימני המסחר הינם סימני המסחר או סימני המסחר הרשומים של בעליהם. אין
              דבר בתנאי שימוש הללו המעניק לך כל זכות לשימוש בסימן מסחרי, סימן
              שירות, סמל מסחרי או שם מסחרי שלנו או של כל צד שלישי.<br></br>
              13.3 אלא אם ייקבע בתנאי שימוש אלו במפורש, המשתמשים, ואו מי מטעמם,
              אינם רשאים לנקוט בפעולות הבאות: (א) הנדסה חוזרת, פירוק או כל
              ניסיון לגלות את קוד המקור של התוכנה שבבסיס האתר; (ב) שינוי של
              האתר, עשיית מניפולציה בו, העתקה או יצירת יצירות נגזרות ממנו, כמו
              גם הפצה, השכרה, שידור, הצגה פומבית, פרסום, שידור, חלוקה, העברה או
              כל שימוש אחר שלא על פי תנאי השימוש הללו; (ג) הפעלה או אפשרות
              להפעיל כל יישום מחשב, רובוט, תוכנת ריגול או כל אמצעי אחר, לרבות
              תוכנות מסוג Crawlers, Robots וכדומה, לשם חיפוש, סריקה, העתקה או
              אחזור אוטומטי של תכנים מתוך האתר. בכלל זה, אין ליצור ואין להשתמש
              באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו תכנים מהאתר או
              הקוד שלו; (ד) ניסיון להשבית או לעקוף כל מנגנון אבטחה של האתר; (ה)
              הפרה של זכויות הפרט וזכויות משפטיות של צדדים שלישיים לרבות השמצה,
              הוצאת דיבה, הפחדה, הטרדה או מעקב אחר משתמשים אחרים; או (ו) הפרה של
              זכויות יוצרים, זכויות לפרטיות, או זכויות מוסריות של כל צד שלישי.
              <br></br>
              13.4 יש לבצע שימוש חוקי בלבד באתר. מבלי לגרוע מכלליות האמור, אין
              לבצע את הפעולות המפורטות להלן, ללא קבלת הסכמתה המפורשת של החברה
              מראש ובכתב:
              <br></br>
              13.4.1 להעתיק, לפתח, לשעתק, לשכפל, להפיץ, להציג בפומבי, לשדר,
              לפרסם מחדש, לשנות, להוריד או למסור לצד שלישי או לעשות כל שימוש אחר
              ביחס לכל חלק מהאמור לעיל;<br></br>
              13.4.2 לעשות כל שימוש מסחרי בשירותי האתר, בנתונים המתפרסמים באתר
              ובמידע המוצג בו;<br></br>
              13.4.3 להציג את האתר בעיצוב או ממשק גרפי שונים מאלה שעיצבה לו
              החברה;
              <br></br>
              13.4.4 לעשות כל שימוש בשם "Hidoc" ו/או בסימני המסחר המופיעים באתר,
              אשר הם קניינה של החברה בלבד.<br></br>
              <p></p>
              14.{" "}
              <u>
                <strong>שונות</strong>
              </u>{" "}
              <br></br>
              14.1 רישומיה של החברה יהוו ראיה לכאורה לנכונות המידע המוצג בהם.
              <br></br>
              14.2 כתובת הדואר האלקטרוני ו/או מספר הטלפון הנייד שתמסור בתהליך
              ההרשמה או ביצוע פעולה באתר ישמשו את החברה, לפי שיקול דעתה, בין
              היתר למשלוח הודעות בקשר עם פעילותך באתר, לרבות שליחת פרטי התור
              שהוזמן וחשבוניות מס/קבלה, ולכן עליך להזין כתובת דוא"ל ומספר טלפון
              נייד תקינים, פעילים ובבעלותך.<br></br>
              14.3 החברה רשאית לשנות ולעדכן את תנאי השימוש מזמן לזמן, על פי
              שיקול דעתה, ומבלי שתידרש לתת על כך הודעה. עליך לבדוק את תנאי
              השימוש לפני כל שימוש באתר. תנאי השימוש שיחולו לגבי ביצוע פעולות
              יהיו אלו התקפים במועד השלמת הפעולה.
              <br></br>
              14.4 הנך מתחייב לשפות את החברה ומי מטעמה, מיד עם קבלת דרישה לכך,
              בגין כל נזק, הפסד, אבדן-רווח, תשלום או הוצאה שייגרמו להם עקב כל
              שימוש לרעה באתר, עקב כל הפרה של תנאי מתנאי השימוש, עקב כל הצהרה
              שמסרת לחברה שאינה נכונה, ועקב כל תביעה ו/או דרישה שתוגש כנגד החברה
              הנוגעת או נובעת מפעולותיך או משימושך באתר.
              <br></br>
              14.5 אין באי שימוש בזכות או סמכות המוקנית לחברה, לרבות על פי תנאי
              השימוש, כדי להוות ויתור על אותה זכות או סמכות. אם מסיבה כלשהי יקבע
              על ידי בית משפט מוסמך כי הוראה כלשהי מתנאי שימוש אלו הנה בלתי תקפה
              או ניתנת לאכיפה, הוראה זו תיאכף במידה האפשרית המותרת, ולא יהיה בכך
              כדי לפגוע מיתר התנאים מפורטים בתנאי השימוש.
              <br></br>
              14.6 החברה רשאית בכל עת וללא צורך בהסכמה כלשהי להעביר את זכויותיה
              ו/או התחייבויותיה על פי תנאי שימוש אלו ו/או הדין לצד שלישי, ובלבד
              שלא יהיה בכך כדי לפגוע בזכויות המשתמש.<br></br>
              14.7 האמור בתנאי שימוש אלו בלשון זכר ייקרא גם בלשון נקבה, ולהיפך.
              האמור בלשון יחיד ייקרא גם בלשון רבים, ולהיפך. כמו כן, תנאי השימוש
              חלים על יחידים ותאגידים מכל סוג.<br></br>
              14.8 החברה רשאית למסור לך הודעות באמצעות כל אמצעי תקשורת שמסרת לה,
              לרבות דואר אלקטרוני, הודעות למכשיר הטלפון הנייד בו נרשמת או ביצע
              פעולות בפלטפורמה או בדואר לכתובת שמסרת לחברה. בנוסף, החברה רשאית
              למסור הודעות באמצעות האתר או האפליקציה, לרבות הודעות כלליות לכלל
              המשתמשים או הודעות אישיות אליך (וזאת באמצעות הודעה קופצת או חלונית
              או באמצעות איזור אישי באתר).<br></br>
              14.9 השימוש באתר וכן תנאי השימוש כפופים לדיני מדינת ישראל. מקום
              השיפוט הבלעדי לכל דבר ועניין הנוגע לאתר ו/או לתנאי השימוש הינו
              בבית המשפט המוסמך בתל אביב-יפו.<br></br>
              אפריל 2021
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Terms;
