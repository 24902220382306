import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Button, Spinner } from "react-bootstrap";
import Layout from "../layout/layout";
import "./index.css";
import thank from "../../assets/images/icons/gift-card.png";
// import sent_email from "../../assets/images/dashboard/sent_email.png";
import { userCalendarNotification } from "../../config/service";
import { useState } from "react";
const ThankYouPage = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const mId = localStorage.getItem("mId");
  const [loading, setLoading] = useState(false);

  const handleCalenderNotification = async () => {
    let data = {
      meetingId: mId,
      isCalendarInvite: true,
    };
    try {
      setLoading(true);
      // make axios post request
      const response = await userCalendarNotification(data, {
        headers: {
          authorization: token,
        },
      });
      if (response.status === 201) {
        localStorage.removeItem("mId");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let noscript = document.createElement("noscript");
    noscript.setAttribute("id", "gtmK99");
    let iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-K99ZPCJ";
    iframe.height = "0";
    iframe.width = "0";
    iframe.style = "display:none;visibility:hidden";
    noscript.append(iframe);
    let isAlreadyAdded = document.getElementById("gtmK99");
    if (!isAlreadyAdded) document.body.prepend(noscript);
  }, []);
  return (
    <>
      <Helmet
        base="head"
        script={[
          {
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-K99ZPCJ');window.dataLayer && window.dataLayer.push({'event':'meeting-payment-suceess'});`,
          },
        ]}
      />

      <Layout>
        <div className="thank-you-page">
          <div className="thank-you-grid">
            <div className="thank-you-content" id="meeting-payment-suceess">
              <img src={thank} alt="icon" />
              <h2>{t("Congratulation")}</h2>
              <p> {t("Your payment is successfull")}</p>
            </div>
            <div className="successText">
              <h5
                dangerouslySetInnerHTML={{
                  __html: t(
                    "It is recommended to upload relevant documents right now for the doctor to see before your next appointment.When the appointment time arrives, you will have to login to the Hidoc system again, using your phone and ID number. Open the appointment page, and click on “Open zoom meeting”, the doctor will admit you into Zoom.Wish you Good Health,Hidoc team"
                  ),
                }}
              />
              {/* <h5>
                {t(
                  "It is recommended to upload relevant documents right now for the doctor to see before your next appointment.When the appointment time arrives, you will have to login to the Hidoc system again, using your phone and ID number. Open the appointment page, and click on “Open zoom meeting”, the doctor will admit you into Zoom.Wish you Good Health,Hidoc team"
                )}
              </h5> */}
            </div>
            <div className="sent_invite">
              <Button onClick={handleCalenderNotification} disabled={loading}>
                {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <>
                    <i class="las la-envelope-open-text"></i>
                    {t("Send an invite to my email")}
                  </>
                )}
              </Button>
            </div>

            {/* <div className="home-btn">
            <a href="/">{t("Done")}</a>
          </div> */}
            {/* <div className="contac-section">
           <p>For any questions, <Link to="/contact">contact us</Link></p>
          </div> */}

            {/* <div className="thank-you-social">
            <p>Let&#39;s Connect!</p>
            <ul>
              <li className='facebook'>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/hidocisrael"
                >
                  {FacebookIconSVG}
                </a>
              </li>
              <li className='linkedin'>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/37465712"
                >
                  {LinkedinIconSVG}
                </a>
              </li>
              <li className='instagram'>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/israelhidoc"
                >
                  {InstagramIconSVG}
                </a>
              </li>
            </ul>
          </div> */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ThankYouPage;
