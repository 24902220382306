import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import { Button } from "react-bootstrap";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-react-ui-kit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Network } from "../../../config/axios";
import { documentView } from "../../../config/service";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import "../../Dashborad/dashboard.css";
import Iframe from "react-iframe";
import pdfIcon from "../../../assets/images/icons/pdf.png";
import imageIcon from "../../../assets/images/icons/image-icon.png";

const ViewDocument = (props) => {
  const { pacseeDocument, meetingid } = props;
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [fileDeleteId, setFileDeleteId] = useState();
  const [confirm, setConfirm] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [iframe, setIframe] = useState(false);
  const [iframeData, setIframeData] = useState();
  const [document, setDocument] = useState([]);
  const [allDocLoader, setAllDocLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const handleDrop = (acceptedFiles) => {
    setFileNames(fileNames.concat(acceptedFiles));
    Upload(acceptedFiles);
  };

  useEffect(() => {
    if (meetingid) {
      allDocument();
    }
  }, [meetingid, uploadModal]);
  const remove = (data) => {
    setFileNames(
      fileNames.filter((d) => {
        return d != data;
      })
    );
  };
  const allDocument = async () => {
    if (meetingid) {
      try {
        setAllDocLoader(true);
        const response = await Network().get(
          `/meeting/meetingDocumentsByMeetingId/${meetingid}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        if ([200, 201, 202].includes(response.data.code)) {
          setDocument(response.data.meetingDocuments);
          setAllDocLoader(false);
        }
      } catch (error) {
        setAllDocLoader(false);
        console.log(error);
      }
    }
  };
  function formatFileSize(bytes) {
    if (bytes == 0) return "0 Bytes";
    var k = 1000,
      dm = 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const Upload = async (fileNames) => {
    let formData = new FormData();
    fileNames.forEach((file) => {
      formData.append("document", file, file.name);
    });
    formData.append("meetingId", props.meetingid);
    formData.append("clientId", props.clientId);

    try {
      setUploadLoader(true);
      const response = await Network().post(
        "/users/uploadDocuments",
        formData,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 202].includes(response.data.code)) {
        toast.success(t(response.data.msg));
        setFileNames([]);
        // props.allDocument(props.id);
      }
      setUploadLoader(false);
    } catch (error) {
      console.log(error);
      setUploadLoader(false);
    }
  };
  const viewPdf = async (path) => {
    const data = {
      url: path,
    };

    try {
      const response = await documentView(data);
      window.open(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteFile = async () => {
    try {
      const response = await Network().delete(
        "/admin/deleteDocumentsByDocumentId/" + fileDeleteId,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 203].includes(response.status)) {
        toast.success(t(response.data.msg));
        setDocument(
          document.filter((d) => {
            return d._id != fileDeleteId;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadMRI = async () => {
    const userId = props.clientId;

    try {
      setUploadLoader(true);
      const response = await Network().get(
        `/users/uploadPacssee?userId=${userId}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if (response.data.url) {
        pacseeIframe(response.data.url);
        setUploadLoader(false);
      } else {
        setUploadLoader(false);
        toast.error(t(response.data.msg));
      }
    } catch (error) {
      console.log(error);
      setUploadLoader(false);
    }
  };
  const viewfile = (data) => {
    window.open(data);
  };

  const pacseeIframe = (data) => {
    setIframe(true);
    setIframeData(data);
  };
  return (
    <>
      <div>
        <Modal
          open={iframe}
          onClose={() => setIframe(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalContent"
        >
          <>
            <Iframe
              url={iframeData}
              width="100%"
              id="myId"
              height="100%"
              styles={{ height: "90%" }}
            />
          </>
        </Modal>
      </div>
      <div>
        <Modal
          open={confirm}
          onClose={() => setConfirm(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalDiv"
          style={{
            backgroundColor: "#FFFFFF",
            width: "30%",
            margin: "20% auto",
            height: "30%",
          }}
        >
          <MDBCard className="documentBody">
            <MDBCardBody>
              <MDBCardText> {t("Are you sure")}</MDBCardText>
              <div className="yes_no">
                <Button
                  size="sm"
                  className="document1"
                  onClick={() => setConfirm(false)}
                >
                  {" "}
                  {t("No")}
                </Button>
                <Button
                  size="sm"
                  className="document1"
                  onClick={() => (setConfirm(false), deleteFile())}
                >
                  {" "}
                  {t("Yes")}
                </Button>
              </div>
            </MDBCardBody>
          </MDBCard>
        </Modal>
      </div>
      <div>
        <Modal
          open={props.documentModal}
          onClose={() => (
            props.setDocumentModal(false), props.setPacseeDocument([])
          )}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalContent"
        >
          <MDBCard className="documentBody">
            <MDBCardTitle className="headerText">
              {t("All Documents")}
            </MDBCardTitle>
            <MDBCardBody>
              {pacseeDocument.length != 0 ? (
                <>
                  <ul>
                    {pacseeDocument.map((d, i) => {
                      return (
                        <div className="row fileList " key={i}>
                          <li className="col-md-6">
                            {t("File")}-{d.study_descr}
                          </li>
                          <div className="col-md-3">
                            <RemoveRedEyeIcon
                              onClick={() => viewfile(d.view_url_options)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </>
              ) : null}
              <ul>
                {allDocLoader ? (
                  <RotatingLines
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="48"
                    visible={allDocLoader}
                  />
                ) : document && document.length != 0 ? (
                  document.map((fileName, i) => {
                    return (
                      <li>
                        <div className="row fileList alllist" key={i}>
                          <div key={fileName} className="col-md-5">
                            <p className="file-name">{fileName.name}</p>
                          </div>
                          <div className="col-md-3">
                            <div className="file-thumbnail">
                              <img
                                src={
                                  fileName?.fileType === "pdf" ||
                                  fileName?.fileType === "application/pdf"
                                    ? pdfIcon
                                    : imageIcon
                                }
                                alt="thumbnail"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="file-btn">
                              <RemoveRedEyeIcon
                                onClick={() => viewPdf(fileName.path)}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="file-btn">
                              <DeleteIcon
                                onClick={() => (
                                  setFileDeleteId(fileName._id),
                                  setConfirm(true)
                                )}
                                sx={{ color: "#e60000" }}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : null}

                {!allDocLoader &&
                pacseeDocument.length === 0 &&
                props.documentModal &&
                props.document.length === 0 ? (
                  <>
                    <h2> {t("No Ducument Uploaded")}</h2>
                  </>
                ) : null}
                <div className="uploadclose">
                  <Button
                    size="lg"
                    style={{ minWidth: "100px", marginTop: "20px" }}
                    onClick={() => setUploadModal(true)}
                  >
                    {" "}
                    {t("Upload")}
                  </Button>
                  <Button
                    size="lg"
                    style={{ minWidth: "100px", marginTop: "20px" }}
                    onClick={() => (
                      props.setDocumentModal(false), props.setPacseeDocument([])
                    )}
                  >
                    {" "}
                    {t("Close")}
                  </Button>
                </div>
              </ul>
            </MDBCardBody>
          </MDBCard>
        </Modal>
      </div>
      <div>
        <Modal
          open={uploadModal}
          onClose={() => setUploadModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalDiv"
          style={{
            backgroundColor: "#FFFFFF",
            width: "60%",
            margin: "30px auto",
            height: "auto",
            borderRadius: "35px",
          }}
        >
          <MDBCard
            style={{ width: "100%", height: "100%", textAlign: "center" }}
          >
            <MDBCardBody>
              <MDBCardTitle className="headerText">
                {t("Upload Some Documents")}
              </MDBCardTitle>
              <div>
                {uploadLoader ? (
                  <div className="admin-upload-loader">
                    <RotatingLines
                      strokeColor="black"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="48"
                      visible={uploadLoader}
                    />
                  </div>
                ) : (
                  <>
                    <Dropzone onDrop={handleDrop}>
                      {({ getRootProps, isDragAccept, isDragReject }) => {
                        const additionalClass = isDragAccept
                          ? "accept"
                          : isDragReject
                          ? "reject"
                          : "";

                        return (
                          <div
                            {...getRootProps({
                              className: `dropzone ${additionalClass}`,
                            })}
                          >
                            <Button
                              variant="primary"
                              size="lg"
                              className="buttton_content mri_ct_xray"
                            >
                              {t("Upload Document")}
                            </Button>
                          </div>
                        );
                      }}
                    </Dropzone>
                    <Button
                      variant="primary"
                      size="lg"
                      className="buttton_content mri_ct_xray"
                      onClick={uploadMRI}
                    >
                      {t("Upload MRI / X-RAY / CT")}
                    </Button>
                  </>
                )}

                <div>
                  <ul>
                    {fileNames.map((fileName, i) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                          key={i}
                        >
                          <li>{fileName.name}</li>
                          <li key={fileName}>{`(${formatFileSize(
                            fileName.size
                          )})`}</li>

                          <DeleteIcon
                            onClick={() => remove(fileName)}
                            sx={{ color: "#e60000" }}
                          />
                        </div>
                      );
                    })}
                  </ul>
                </div>
                <Button
                  variant="primary"
                  size="lg"
                  className="buttton_content"
                  onClick={() => (setUploadModal(false), allDocument())}
                  disabled={uploadLoader}
                >
                  {t("Close")}
                </Button>
              </div>
            </MDBCardBody>
          </MDBCard>
        </Modal>
      </div>
    </>
  );
};
export default ViewDocument;
