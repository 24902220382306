import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./newHeader.css";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { siteLogoutTime, monoLanguage } from "../../config/key";
// doubleLanguage
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PrimaryLogo, WhiteLogo } from "./newLogo";
import AlertModal from "../common/AlertModal";
import PrescriptionAlertModal from "../CreatePrescription/PrescriptionAlaertModal";
localStorage.setItem("monoLanguage", monoLanguage);

const NewHeader = (props) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const path = window.location.pathname;
  const mode = localStorage.getItem("mode");
  const alertValue = localStorage.getItem("alert");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  if (localStorage.getItem("monoLanguage") === "true") {
    document.body.dir = "rtl";
  } else {
    document.body.dir = "ltr";
  }
  let timer;

  const handleAlertModal = () => {
    if (alertValue === "close") {
      setShowAlertModal(false);
    }
    if (token && role === "client") {
      setShowAlertModal(false); // Replace this false value with true value for open this modal
    }
  };

  useEffect(() => {
    handleAlertModal();
  }, [token]);

  useEffect(() => {
    const keyToClear = "alert";
    if (alertValue === "close") {
      const clearLocalStorageKey = () => {
        localStorage.removeItem(keyToClear);
      };
      window.addEventListener("beforeunload", clearLocalStorageKey);
      return () => {
        window.removeEventListener("beforeunload", clearLocalStorageKey);
      };
    }
  }, [alertValue]);

  const handleCloseAlertModal = () => {
    localStorage.setItem("alert", "close");
    setShowAlertModal(false);
  };

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "dblclick",
    "scroll",
    "keypress",
    "mousewheel",
    "DOMMouseScroll",
    "mouseout",
    "mouseenter",
    "mouseleave",
    "mouseup",
    "keydown",
    "keyup",
  ];

  document.body.dir = "rtl";
  let selected;
  const languageMap = {
    EN: { dir: "ltr", active: false },
    HE: { dir: "rtl", active: true },
  };
  if (["EN", "HE"].includes(localStorage.getItem("i18nextLng"))) {
    selected = localStorage.getItem("i18nextLng");
  } else {
    if (localStorage.getItem("monoLanguage") === "true") {
      selected = "HE";
    } else {
      selected = "EN";
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (localStorage.getItem("monoLanguage") === "true") {
      changeLanguageHandler("HE");
    } else {
      null;
    }
  }, [location]);

  const changeLanguageHandler = (e) => {
    const languageValue = e;
    i18n.changeLanguage(e);
    localStorage.setItem("lang", languageValue);
  };

  document.body.dir = languageMap[selected].dir
    ? languageMap[selected].dir
    : localStorage.getItem("monoLanguage") === "true"
    ? "rtl"
    : "ltr";

  // monoLanguage
  let index;

  let formclass = document.getElementsByClassName("form-control");
  for (index = 0; index < formclass.length; ++index) {
    formclass[index].dir = languageMap[selected]?.dir;
    if (
      document.getElementById(formclass[index].id) &&
      formclass[index].type == "text"
    ) {
      document.getElementById(formclass[index].id).style.direction =
        languageMap[selected]?.dir;
      document.getElementById(formclass[index].id).style.textAlign =
        languageMap[selected]?.dir;
    }
  }

  const logOut = () => {
    localStorage.removeItem("utm");
    localStorage.removeItem("collective");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    localStorage.removeItem("alert");
    localStorage.removeItem("status");
    if (mode === "IDF") {
      localStorage.setItem("uploadModal", "open");
      localStorage.removeItem("mode");
    }
    location.reload();
  };

  // doubleLanguage
  const language = (e) => {
    if (localStorage.getItem("monoLanguage") === "true") {
      null;
    } else {
      if (e.target.innerText == "עִברִית") {
        changeLanguageHandler("HE");
      } else {
        changeLanguageHandler("EN");
        // }
      }
    }
  };

  const handlePrescriptionAlert = () => {
    setShowModal(!showModal);
  };

  // this function sets the timer that logs out the admin
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logOut();
      if (role === "admin") {
        window.location.pathname = "/admin";
      } else {
        window.location.pathname = "/";
      }
    }, parseInt(siteLogoutTime));
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  useEffect(() => {
    function checkUserData() {
      if (token) {
        Object.values(events).forEach((item) => {
          window.addEventListener(item, () => {
            resetTimer();
            handleLogoutTimer();
          });
        });
      }
    }
    window.addEventListener("storage", checkUserData);
    checkUserData();
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, [token]);

  return props.show ? (
    <>
      <div className="header-wrapper">
        <div className="new-header">
          <div className="container">
            <div className="new-header-grid">
              <div className="logo-grid">
                <Link to="/" className="logo">
                  {PrimaryLogo}
                </Link>
              </div>
              <div className="new-menu-section">
                <div className="new-header-menu">
                  <Navbar expand="lg" collapseOnSelect>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav>
                        <NavLink className="nav-link mobile-view-link" to="/">
                          {WhiteLogo}
                        </NavLink>
                        {localStorage.getItem("token") ? (
                          <>
                            {localStorage.getItem("role") === "admin" ? (
                              <>
                                <Nav.Link
                                  className="nav-link"
                                  to="/meetingrequest"
                                  as={Link}
                                  eventKey={1}
                                >
                                  {t("Create Meeting")}
                                </Nav.Link>
                                <Nav.Link
                                  className="nav-link"
                                  to="/createuser"
                                  as={Link}
                                  eventKey={2}
                                >
                                  {t("Create User")}
                                </Nav.Link>
                                <Nav.Link
                                  className="nav-link"
                                  to="/AdminDashboard"
                                  as={Link}
                                  eventKey={3}
                                >
                                  {t("View users")}
                                </Nav.Link>
                                <Nav.Link
                                  className="nav-link"
                                  to="/ViewMeeting"
                                  as={Link}
                                  eventKey={4}
                                >
                                  {t("View meetings")}
                                </Nav.Link>
                                <Nav.Link
                                  to="/Profile"
                                  className="nav-link"
                                  as={Link}
                                  eventKey={5}
                                >
                                  {localStorage.getItem("userName")}
                                </Nav.Link>
                                <Nav.Link
                                  className="nav-link"
                                  to="/login"
                                  onClick={logOut}
                                  as={Link}
                                  eventKey={6}
                                >
                                  {t("Log Out")}
                                </Nav.Link>
                              </>
                            ) : (
                              <>
                                {localStorage.getItem("role") === "client" &&
                                mode !== "IDF" ? (
                                  <Nav.Link
                                    as={Link}
                                    eventKey={1}
                                    className="nav-link"
                                    to="/meetingrequest"
                                  >
                                    {t("Create Meeting")}
                                  </Nav.Link>
                                ) : null}
                                {localStorage.getItem("role") === "doctor" ? (
                                  props.subscriptionsList?.includes("Rx") ? (
                                    <Nav.Link
                                      as={Link}
                                      eventKey={6}
                                      className="nav-link"
                                      to="/create-prescription"
                                    >
                                      {t("Create a private prescription")}
                                    </Nav.Link>
                                  ) : (
                                    <span
                                      className="nav-link"
                                      onClick={handlePrescriptionAlert}
                                    >
                                      {t("Create a private prescription")}
                                    </span>
                                  )
                                ) : null}
                                <Nav.Link
                                  as={Link}
                                  eventKey={2}
                                  className="nav-link"
                                  to="/"
                                >
                                  {t("Dashboard")}
                                </Nav.Link>
                                <Nav.Link
                                  className="nav-link"
                                  to="/Profile"
                                  as={Link}
                                  eventKey={3}
                                >
                                  {t("Profile Setting")}
                                </Nav.Link>
                                <Nav.Link
                                  as={Link}
                                  eventKey={4}
                                  className="nav-link"
                                  to=""
                                >
                                  {localStorage.getItem("userName")}
                                </Nav.Link>
                                <Nav.Link
                                  className="nav-link"
                                  to="/login"
                                  onClick={logOut}
                                  as={Link}
                                  eventKey={5}
                                >
                                  {t("Log Out")}
                                </Nav.Link>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {path.includes("/meetingrequest") ? (
                              <Nav.Link
                                eventKey={3}
                                className="nav-link"
                                href="https://hidoc.co.il/"
                              >
                                <span> {t("Back to the main page")}</span>
                              </Nav.Link>
                            ) : null}
                            {mode !== "IDF" ? (
                              <Nav.Link
                                as={Link}
                                eventKey={1}
                                className="nav-link"
                                to="/meetingrequest"
                              >
                                <span> {t("Create Meeting")}</span>
                              </Nav.Link>
                            ) : null}
                            <Nav.Link
                              as={Link}
                              eventKey={2}
                              className="nav-link"
                              to="/"
                            >
                              <span> {t("Login")}</span>
                            </Nav.Link>
                          </>
                        )}
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
              {/* doubleLanguage start*/}
              {localStorage.getItem("monoLanguage") === "true" ? null : (
                <div className="new-auth-box">
                  <div className="linkdropdown">
                    <Nav.Link onClick={(e) => language(e)}>עִברִית</Nav.Link>
                    <Nav.Link onClick={(e) => language(e)}>English</Nav.Link>
                  </div>
                  <div>
                    <DropdownButton
                      key={"down"}
                      id={`dropdown-button-drop-down`}
                      drop={"down"}
                      variant="primary"
                      onSelect={changeLanguageHandler}
                      title={selected}
                    >
                      <Dropdown.Item
                        // className="dropdownItem"
                        eventKey="HE"
                      >
                        עִברִית
                      </Dropdown.Item>
                      <Dropdown.Item
                        // className="dropdownItem"
                        eventKey="EN"
                      >
                        English
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              )}
              {/* doubleLanguage end*/}
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        setShowAlertModal={setShowAlertModal}
        showAlertModal={showAlertModal}
        handleCloseAlertModal={handleCloseAlertModal}
      />
      <PrescriptionAlertModal
        handlePrescriptionAlert={handlePrescriptionAlert}
        showModal={showModal}
      />
    </>
  ) : null;
};
export default NewHeader;
