import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashborad/client.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button } from "react-bootstrap";
import SummaryComponent from "./SummaryComponent";
import { documentView } from "../../config/service";

function useIsButtonDisabled(scheduledDate) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const oneDayLater = new Date(new Date(scheduledDate).getTime() + 24 * 60 * 60 * 1000);

    const nowUTC = new Date().toISOString();

    if (new Date(nowUTC) >= oneDayLater) {
      setIsButtonDisabled(true);
    }
    const interval = setInterval(() => {
      const currentTimeUTC = new Date().toISOString();
      if (new Date(currentTimeUTC) >= oneDayLater) {
        setIsButtonDisabled(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [scheduledDate]);

  return isButtonDisabled;
}

const DoctorRX = (props) => {
  const [meeting, setMeeting] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columnDefs] = useState([
    {
      field: "Patients Details",
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Created Date",
      field: "Created Date",
      sortable: true,
      resizable: true,
      floatingFilter: true,
      sort: "desc",
      valueFormatter: (params) => {
        return params.value
          ? moment(params.value).format("DD-MM-YYYY h:mm:ss a")
          : "";
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: function (filterLocalDate, cellValue) {
          filterLocalDate = new Date(filterLocalDate);
          cellValue = new Date(new Date(cellValue).setHours(0, 0, 0, 0));
          let filterBy = filterLocalDate.getTime();
          let filterMe = cellValue.getTime();
          if (filterBy === filterMe) {
            return 0;
          }

          if (filterMe < filterBy) {
            return -1;
          }

          if (filterMe > filterBy) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Action",
      width: 450,
      cellRendererFramework: (params) => {
        // Calling custom hook for Update button disable
        const isButtonDisabled = useIsButtonDisabled(params?.data?.meetingDetail?.scheduledDate);
        return (
          <div className="d-flex">
            <Button
              onClick={(e) =>
                summaryModal(
                  e,
                  params?.data?.meetingDetail._id,
                  params?.data?.meetingDetail?.prescription,
                  params?.data?.meetingDetail?.summary,
                  params?.data?.meetingDetail
                )
              }
              disabled={isButtonDisabled}
            >
              {t("update")}
            </Button>
            <Button
              onClick={(e) =>
                handleFollowupSummary(e, params?.data?.meetingDetail)
              }
              style={{ margin: "0px 12px" }}
            >
              {t("Follow up summary")}
            </Button>
            <Button
              onClick={() =>
                viewPdf(
                  params?.data?.meetingDetail.documents.length !== 0
                    ? params?.data?.meetingDetail.documents
                    : ""
                )
              }
            >
              {t("download")}
            </Button>
          </div>
        );
      },
    },
  ]);
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);
  const [summaryId, setSummaryId] = useState();
  const [updateSummaryResponce, setUpdateSummaryResponse] = useState({});
  const [prescriptionResponce, setUpdateProscriptionResponse] = useState([]);
  const [userName, setUserName] = useState("");
  const [checkIDF, setCheckIDF] = useState("");

  const getallmeetings = () => {
    const filteredAppointmet = props.allAppointment.filter(
      (el) => el.meetingType === "PrescriptionOnly"
    );

    const meetingData = filteredAppointmet.map((d) => {
      return {
        "Created Date": new Date(d.createdAt),
        meetingDetail: d,
        // "Patients Name": `${
        //   d.patientDetails?.first_name ? d?.patientDetails?.first_name : ""
        // } ${d?.patientDetails?.last_name ? d?.patientDetails?.last_name : ""}`,
        Age: `${ageCalculator(d?.userDetails?.clientDOB)}, `,
        Gender: `${d?.patientDetails?.patientGender}`,
        "Patients Details": `${d.patientDetails?.first_name ? d?.patientDetails?.first_name : ""
          } ${d?.patientDetails?.last_name ? d?.patientDetails?.last_name : ""
          }, ${d?.userDetails?.clientDOB
            ? ageCalculator(d?.userDetails?.clientDOB) : "N/A"}, ${d?.patientDetails?.patientGender
          }`,
        // "Meeting Id": `${d._id}`,
      };
    });
    setMeeting(meetingData);
  };

  useEffect(() => {
    getallmeetings();
  }, [props.allAppointment]);
  const summaryModal = (e, id, prescription, summary, data) => {
    e.preventDefault();
    setUserName(
      `${data?.userDetails?.first_name}  ${data?.userDetails?.last_name}`
    );
    setCheckIDF(data?.consultation[0]?.meetingGroupId);
    setSummaryId(id);
    setUpdateSummaryResponse(summary);
    setUpdateProscriptionResponse(prescription);
    props.setOpen(true);
  };

  const handleFollowupSummary = (e, data) => {
    e.preventDefault();
    navigate("/create-prescription", { state: { meetingDetail: data } });
  };
  const viewPdf = (document) => {

    document.forEach(async (element) => {
      if (element.type == "Summary" && element.docType === 'current') {
        const data = {
          url: element.path,
        };
        const response = await documentView(data);
        setTimeout(() => {
          window.open(response.data, "_blank");
        });
      }
    });

  };

  function ageCalculator(d) {
    var birthDate = new Date(d);
    var otherDate = new Date();

    var years = otherDate.getFullYear() - birthDate.getFullYear();

    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() == birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }
    return years;
  }
  const handleCloseSumm = () => {
    props.handleCloseSummary();
    setUpdateSummaryResponse({});
  };

  return (
    <div>
      <SummaryComponent
        open={props.open}
        updateSummaryResponce={updateSummaryResponce}
        setUpdateSummaryResponse={setUpdateSummaryResponse}
        prescriptionResponce={prescriptionResponce}
        setOpen={props.setOpen}
        meetingId={summaryId}
        handleClose={handleCloseSumm}
        AllApoinmentDoctor={props.AllApoinmentDoctor}
        setMeetingValue={props.setMeetingValue}
        meetingValue={props.meetingValue}
        userName={userName}
        checkIDF={checkIDF}
      />

      <section className="userWrapper doctor-user">
        <div className="adminDataTable">
          <div className="container">
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: 450 }}
            >
          <AgGridReact
            rowData={meeting}
            columnDefs={columnDefs}
            animateRows={true}
            rowSelection="multiple"
            onCellClicked={cellClickedListener}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
          />
        </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default DoctorRX;
