import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AgeForm = ({
  setSelectStep,
  setformData,
  expertise,
  consultField,
  storagePartnerId,
}) => {
  const { t } = useTranslation();
  const [selectType, setSelectType] = useState("adult");
  const handleSelect = (type) => {
    setSelectType(type);
  };
  const handleContinue = () => {
    setformData((prev) => ({
      ...prev,
      ["age"]: selectType === "child" ? 1 : 18,
      ["consult"]: consultField,
    }));
    window.gtag("event", "new-request-age", {});
    setSelectStep(expertise === "null" ? "consult" : "reference");
  };
  useEffect(() => {
    setformData((prev) => ({
      ...prev,
      ["age"]: selectType === "child" ? 1 : 18,
      ["consult"]: consultField,
    }));
  }, [consultField]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="age-form">
      <h6>
        {t("Medical advice")} <span>{t("for")}</span>
      </h6>
      <div className="advise-type">
        <div
          className={`advise-type-box ${
            selectType === "child" ? "selected" : ""
          }`}
          onClick={() => handleSelect("child")}
        >
          <p>{t("Child")}</p>
        </div>
        <div
          className={`advise-type-box ${
            selectType === "adult" ? "selected" : ""
          }`}
          onClick={() => handleSelect("adult")}
        >
          <p>{t("Adult")}</p>
        </div>
      </div>
      <div className="continue-btn">
        <Button onClick={handleContinue}>{t("Continue")}</Button>
        {storagePartnerId === "64d326cd869f4f8664538775" ? (
          <a
            href="https://api.whatsapp.com/send?phone=972526001369"
            target="_blank"
          >
            {t(
              "Didn't find the field you were looking for? It is written to us"
            )}
          </a>
        ) : (
          <a href="https://www.hidoc.co.il/contact">
            {t("We will be happy to help")}
          </a>
        )}
      </div>
    </div>
  );
};
export default AgeForm;
