import React from "react";
import NewFooter from "../footer/newFooter";

const IchilovConsent = () => {
  return (
    <>
      <div className="mainConsent">
        <div className="container">
          <div className="mainConsentItems">
            <h1>טופס הסכמה לקבלת שירות באמצעות "רפואה מרחוק" ("טלמדיסין")</h1>
            <div className="consent_text">
              <ol>
                <li>
                  אני, ___________ מס' ת.ז _____________ מבקש/ת לקבל שירות רפואי
                  מרחוק ע"י רופאי תאגיד הבריאות ליד המרכז הרפואי תל אביב ע.ר.
                  (להלן: " ביה"ח) באמצעות חברת ואלור מוצרים חכמים בע"מ.
                </li>
                <li>
                  שירות רפואי מרחוק פירושו מפגש מקצועי וירטואלי באמצעות שיחה
                  מרחוק עם רופא ביה"ח.
                </li>
                <li>
                  הנני נותן/ת הסכמתי לטיפול, קיום התייעצויות והעברת מידע רפואי
                  למטפלים אחרים, כמקובל בביה"ח ובהתאם לשיקולים הרפואיים
                  והמקצועיים.
                </li>
                <li>
                  ידוע לי כי פנייתי מתועדת באופן מלא על ידי ביה"ח וכי בעצם סימון
                  התיבה "אני מקבל/ת את תנאי השימוש" אני מסכים/ה להם ומצהיר/ה
                  ומתחייב/ת בזאת, כי הפרטים האישיים ונתוני-הזיהוי שאני מוסר/ת
                  אודותיי או אודות מקבל השירות, לצורך שימוש בשיחה מרחוק עם
                  המטפל, כמו גם המידע הרפואי שאמסור והמסמכים הרפואיים שאצרף
                  לצורך קבלת השירות, הם מלאים ומדויקים.
                </li>
                <li>
                  המטפלים בשירות יעשו ככל הניתן לשמור על צנעת הפרט והסודיות
                  הרפואית, בהתאם לצרכים הרפואיים והמקצועיים לאבחון וטיפול.
                </li>
                <li>
                  בהשתמשי בשירותי רפואה מרחוק זה, ידוע לי, כי המידע ו/או ההכוונה
                  שיינתנו על-ידי ביה"ח מהווים שירות ראשוני בלבד, הניתן בהסתמך על
                  המידע הנמסר על ידי במהלך המפגש הווירטואלי, ובהתבסס על מידע זה.
                </li>
                <li>
                  אין בשירות הרפואה מרחוק בכדי להוות תחליף לבדיקה ו/או טיפול
                  ו/או לייעוץ רפואי אישי מלא על ידי רופא/ה, בהתאם לצורך. יתר על
                  כן, הספרות המקצועית הראתה כי למפגשים וירטואליים עם רופא יעילות
                  פחותה באיתור האבחנה והטיפול המתאימים, וכי לא לכל בעיה או שאלה
                  רפואית ניתן לתת מענה במסגרת הזו.
                </li>
                <li>
                  בכל מקרה של בעיה דחופה, מקרה חירום או מצב מצוקה (גופני ו/או
                  נפשי) יש לי אפשרות לפנות לקבלת טיפול רפואי מידי למלר"ד של בית
                  החולים או למוקד רפואי אחר המספק שירותי רפואה דחופה כגון מד"א.
                </li>
                <li>
                  המידע הנמסר על ידי מתועד ברשומה הרפואית של ביה"ח בהתאם לחוק
                  וע"י ואלור מוצרים חכמים בע"מ.
                </li>
                <li>
                  ידוע לי, שקיימות מגבלות בשמירה על פרטיות וחיסיון רפואי, ואף
                  יתכנו אירועי כשל תקשורתי וניתוק פתאומי ביני לבין בשירות. הנהלת
                  השירות עושה ככל יכולתה לאבטח את המידע הנמסר על ידי. באחריותי
                  הבלעדית, לדאוג לשמירת פרטיותי ביחס למידע הרפואי האישי המופיע
                  על הצג הדיגיטלי האישי שממנו מבוצעת שיחת השירות (נקודת הקצה בה
                  אני משתמש, לדוגמה- מסך המחשב, מסוף, טלפון וכד'), ועלול להיות
                  חשוף לאנשים בסביבתי או למשתמשים אחרים באותה נקודת קצה.
                </li>
                <li>
                  פנייה לשירות באמצעות תקשורת שאיננה מאובטחת יכולה להיות חשופה
                  ברשת האינטרנט. ביה"ח יבטיח ככל יכולתו את פרטיות המידע השמור
                  אצלו, אולם ידוע לי כי אין באפשרותו להבטיח את פרטיות המידע
                  במהלך ההתקשרות מנקודת הקצה בה אני משתמש ואת המידע השמור בנקודת
                  הקצה אצלי.
                </li>
                <li>
                  מומלץ בתום כל שימוש, לרבות הפסקה זמנית וקצרת מועד במערכת
                  השירות, להסיר את המידע, ו/או את נתוני הזיהוי, לרבות שם משתמש
                  וסיסמה ו/או כל נתון אחר, מנקודת הקצה בה השתמשתי, כדי לצמצם את
                  האפשרויות לגישה למידע אודותיי ו/או לשירות למי שאינו מורשה לכך
                  על ידי. ידוע לי, כי אבטחת המידע השמור בנקודת הקצה שלי היא
                  באחריותי הבלעדית ואין לביה"ח כל יכולת לאבטח מידע זה.
                </li>
                <li>
                  בהשתמשי בשירותי רפואה מרחוק ידוע לי, כי עקב האופן שבו ניתן
                  השירות ייתכן ולא יועבר רישום של הפנייה לתיק הרפואי האישי שלי
                  או של מקבל השירות בקופת החולים המבטחת, וכי באחריותי לעדכן את
                  הרופא המטפל (בקופת החולים) – למטרת המשך הטיפול ושמירה על הרצף
                  הטיפולי הנכון.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};
export default IchilovConsent;
